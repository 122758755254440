import React from 'react';
import Subject from '../../Components/Subject';
import styled from 'styled-components';

import { patentData } from './companyData';
import CompanyHeader from './CompanyHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 120px;
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div`
  > div {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    @media (max-width: 1080px) {
      margin-top: 10px;
    }
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  padding: 20px 10px;
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-top: 50px;
  @media (max-width: 1080px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const PatentDiv = styled.div`
  width: 17%;
  margin: 3% 1.5%;
  @media (max-width: 1080px) {
    width: 27.3%;
    margin: 3% 3%;
  }
  @media (max-width: 768px) {
    width: 44%;
    margin: 3% 3%;
  }
  > img {
    width: 100%;
  }
  > p {
    margin-top: 5px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #343434;
    @media (max-width: 1080px) {
      font-size: 12px;
      font-weight: 400;
    }
    @media (max-width: 768px) {
      font-size: 10px;
      font-weight: 400;
    }
  }
`;

const Patent = () => {
  const info = { title: '인증서/특허', path: '/company/patent' };
  return (
    <>
      <CompanyHeader />
      <Container>
        <Subject info={info} />
        {patentData.map((data, index) => (
          <Box key={index}>
            <Title data-aos="fade-up" data-aos-duration="2000">
              {data.title}
            </Title>
            <div>
              {data.patents.map((patent, index) => (
                <PatentDiv
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration="2000">
                  <img src={patent.img} alt={patent.name} />
                  <p>{patent.name}</p>
                </PatentDiv>
              ))}
            </div>
          </Box>
        ))}
      </Container>
    </>
  );
};

export default Patent;
