import React from 'react';
import Router from './Router';
import GlobalStyles from './GlobalStyles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './ScrollToTop';

function App() {
  AOS.init();
  return (
    <div className="App">
      <ScrollToTop />
      <Router />
      <GlobalStyles />
    </div>
  );
}

export default App;
