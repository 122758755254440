import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import Axios from 'axios';
import Home from '../Routes/Home/Home';
import Footer from './Footer';
import Header from './Header';
import UserContext from '../Context/UserContext';
import Ceo from '../Routes/Company/Ceo';
import Intro from '../Routes/Company/Intro';
import Business from '../Routes/Company/Business';
import History from '../Routes/Company/History';
import Map from '../Routes/Company/Map';
import Patent from '../Routes/Company/Patent';
import Sliding from '../Routes/Product/Sliding';
import Link from '../Routes/Product/Link';
import Hinge from '../Routes/Product/Hinge';
import Swing from '../Routes/Product/Swing';
import Partition from '../Routes/Product/Partition';
import Catalogue from '../Routes/Promotion/Catalogue';
import News from '../Routes/Promotion/News';
import Knowledge from '../Routes/Promotion/Knowledge';
import Apartment from '../Routes/Portfolio/Apartment';
import Drama from '../Routes/Portfolio/Drama';
import Installation from '../Routes/Portfolio/Installation';
import ModelHouse from '../Routes/Portfolio/ModelHouse';
import Office from '../Routes/Portfolio/Office';
import Counsel from '../Routes/Customer/Counsel';
import Estimate from '../Routes/Customer/Estimate';
import Faq from '../Routes/Customer/Faq';
import Staff from '../Routes/Customer/Staff';
import Login from '../Routes/User/Login';
import Join from '../Routes/User/Join';
import Privacy from '../Routes/User/Privacy';
import EditUser from '../Routes/User/EditUser';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export default () => {
  const [userData, setUserData] = useState({
    token: null,
    userinfo: null,
  });

  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem('auth-token');
      if (token === null) {
        localStorage.setItem('auth-token', '');
        token = '';
      }
      const tokenRes = await Axios.post('/users/tokenIsValid', null, {
        headers: { 'x-auth-token': token },
      });
      if (tokenRes.data) {
        const userRes = await Axios.get('/users', {
          headers: { 'x-auth-token': token },
        });
        setUserData({
          token,
          userinfo: userRes.data.user,
        });
      }
    };
    checkLoggedIn();
  }, []);

  return (
    <Router>
      <UserContext.Provider value={{ userData, setUserData }}>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          {/* company */}
          <Route path="/company/ceo_message" exact component={Ceo} />
          <Route path="/company/intro" exact component={Intro} />
          <Route path="/company/business" exact component={Business} />
          <Route path="/company/history" exact component={History} />
          <Route path="/company/map" exact component={Map} />
          <Route path="/company/patent" exact component={Patent} />
          {/* product */}
          <Route path="/product/sliding" exact component={Sliding} />
          <Route path="/product/link" exact component={Link} />
          <Route path="/product/hinge" exact component={Hinge} />
          <Route path="/product/swing" exact component={Swing} />
          <Route path="/product/partition" exact component={Partition} />
          {/* promotion */}
          <Route path="/promotion/catalogue" exact component={Catalogue} />
          <Route path="/promotion/news" exact component={News} />
          <Route path="/promotion/knowledge" exact component={Knowledge} />
          {/* portfolio */}
          <Route path="/portfolio/apartment" exact component={Apartment} />
          <Route path="/portfolio/drama" exact component={Drama} />
          <Route
            path="/portfolio/installation"
            exact
            component={Installation}
          />
          <Route path="/portfolio/modelhouse" exact component={ModelHouse} />
          <Route path="/portfolio/office" exact component={Office} />
          {/* customer */}
          <Route path="/customer/counsel" exact component={Counsel} />
          <Route path="/customer/estimate" exact component={Estimate} />
          <Route path="/customer/faq" exact component={Faq} />
          <Route path="/customer/staff" exact component={Staff} />
          {/* user */}
          <PublicRoute path="/user/login" exact component={Login} />
          <PublicRoute path="/user/join" exact component={Join} />
          <Route path="/user/privacy" exact component={Privacy} />
          <PrivateRoute path="/user/edit" exact component={EditUser} />
          <Redirect from="*" to="/" />
        </Switch>
        <Footer />
      </UserContext.Provider>
    </Router>
  );
};
