import React from 'react';
import styled from 'styled-components';

import doorInstallIcon from '../../../assets/img/product/authenticate.png';
import misoInstallIcon2 from '../../../assets/img/product/directInstall.png';
import misoASIcon from '../../../assets/img/product/asIcon.png';

const Container = styled.div`
  margin-bottom: 200px;
  text-align: center;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  > h1 {
    font-size: 32px;
    @media (max-width: 768px) {
      font-size: 18px;
      margin: 10px;
    }
    > em {
      font-weight: 600;
    }
  }
  > p {
    font-size: 18px;
    padding: 15px 0;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
      padding: 0 40px;
    }
  }
  > div {
    display: flex;
    justify-content: center;
    margin-bottom: 120px;
    @media (max-width: 768px) {
      margin: 20px 0 80px 0;
    }
    > div {
      margin: 3% 1%;
      width: 28%;
      @media (max-width: 768px) {
        width: 100%;
        margin: 0;
      }
      > img {
        width: 100%;
        padding: 3%;
        @media (max-width: 768px) {
          padding: 0;
          margin-bottom: 10px;
        }
      }
      > h2 {
        font-size: 22px;
        padding: 5px 0;
        font-weight: 500;
        @media (max-width: 768px) {
          padding: 0;
          font-size: 12px;
        }
      }
      > p {
        font-size: 18px;
        line-height: 40px;
        @media (max-width: 768px) {
          font-size: 10px;
          line-height: 20px;
        }
      }
    }
  }
  > div:last-child {
    display: flex;
    justify-content: center;
    margin-bottom: 120px;
    @media (max-width: 768px) {
      margin: 20px 0 80px 0;
    }
    > div {
      margin: 3% 1%;
      width: 20%;
      @media (max-width: 768px) {
        width: 100%;
        margin: 0;
      }
      > img {
        width: 70%;
        padding: 3%;
        @media (max-width: 768px) {
          padding: 0;
          margin-bottom: 10px;
        }
      }
      > h2 {
        font-size: 22px;
        padding: 5px 0;
        font-weight: 500;
        @media (max-width: 768px) {
          padding: 0;
          font-size: 12px;
        }
      }
      > p {
        font-size: 18px;
        line-height: 40px;
        @media (max-width: 768px) {
          font-size: 10px;
          line-height: 20px;
        }
      }
    }
  }
`;

const ProductQuility = ({ imgs }) => {
  const datas = [
    {
      name: 'doorInstallIcon',
      img: doorInstallIcon,
      title: '중문 시공면허제',
      desc: '전문가로 구성된 시공팀',
    },
    {
      name: 'misoInstallIcon2',
      img: misoInstallIcon2,
      title: '직영 시공',
      desc: '철저한 관리 시공',
    },
    {
      name: 'misoASIcon',
      img: misoASIcon,
      title: '신속한 A/S 서비스',
      desc: '최고의 품질과 신속한 A/S',
    },
  ];
  return (
    <Container>
      <h1>
        미소테크가 <em>추천하는 설치장소</em> 입니다.
      </h1>
      <p>
        {imgs[0].title}, {imgs[1].title}, {imgs[2].title} 등 공간을 분리하여
        새로운 인테리어 효과를 낼 수 있습니다.
      </p>
      <div>
        {imgs.map((img, index) => (
          <div key={index}>
            <img src={img.name} alt={img.en_title} />
            <h2>{img.title}</h2>
          </div>
        ))}
      </div>
      <h1>
        미소테크만의 <em>품질 프로그램</em> 입니다.
      </h1>
      <div>
        {datas.map((data, index) => (
          <div key={index}>
            <img src={data.img} alt={data.name} />
            <h2>{data.title}</h2>
            <p>{data.desc}</p>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default ProductQuility;
