import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 100px;
  text-align: center;
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
  > h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 35px;
    color: #1e1e1e;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 40px;
    @media (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
  > p {
    font-size: 24px;
    line-height: 30px;
    padding: 10px 0;
    color: #1e1e1e;
    word-break: keep-all;
    :last-child {
      @media (max-width: 768px) {
        padding: 0 10px;
      }
    }
    @media (max-width: 768px) {
      padding: 0 10px;
      font-size: 14px;
      line-height: 30px;
    }
  }
`;

const DesignDesc = ({ title, data }) => {
  return (
    <Container>
      <h1>{title}</h1>
      {data.map((Desc, index) => (
        <p key={index}>{Desc}</p>
      ))}
    </Container>
  );
};

export default DesignDesc;
