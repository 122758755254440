import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';
import { useState } from 'react';
import PromotionHeader from './PromotionHeader';
import { Link } from 'react-router-dom';
import oneSlidingInstallGuide from '../../assets/img/promotion/knowledge/oneSlidingInstallGuide.png';
import twoSlidingInstallGuide from '../../assets/img/promotion/knowledge/twoSlidingInstallGuide.png';
import linkSlidingInstallGuide from '../../assets/img/promotion/knowledge/linkSlidingInstallGuide.png';
import hingeInstallGuide from '../../assets/img/promotion/knowledge/hingeInstallGuide.png';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const List = styled.ul`
  border-bottom: 1px solid #000;
  font-size: 0;
  margin: 0;
  text-align: left;
  margin-top: 60px;
  @media (max-width: 768px) {
    border-bottom: 0;
    margin-top: 30px;
  }
  > li {
    display: inline-block;
    margin: 0;
    margin-right: 3px;
    @media (max-width: 768px) {
      margin-right: 1px;
    }
    > a {
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      text-decoration: none;
      position: relative;
      padding: 10px 30px;
      color: #999;
      border: 1px solid #ccc;
      border-bottom-color: #000;
      display: inline-block;
      margin-bottom: -1px;
      @media (max-width: 768px) {
        width: 100%;
        display: inline-block;
        font-size: 12px;
        line-height: 22px;
        padding: 3px 10px;
        border-bottom-color: #ccc;
      }
    }
    .active {
      color: #000;
      font-weight: 500;
      border: 1px solid #000;
      border-bottom-color: white;
      @media (max-width: 768px) {
        border-bottom-color: #000;
      }
    }
  }
`;
const TabBox = styled.div`
  padding: 70px 0;
  @media (max-width: 1080px) {
    padding: 50px 0;
  }
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;
const Box = styled.div``;
const Row = styled.div`
  text-align: center;
  img {
    width: 90%;
  }
`;
const Column = styled.div``;

const Knowledge = () => {
  const [active, setActive] = useState(0);
  const info = { title: '시공방법', path: '/promotion/knowledge' };

  return (
    <>
      <PromotionHeader />
      <Container>
        <Subject info={info} />
        <List>
          <li>
            <Link
              href="!#"
              onClick={() => setActive(0)}
              className={active === 0 ? 'active' : ''}>
              슬라이딩 도어
            </Link>
          </li>
          <li>
            <Link
              href="!#"
              onClick={() => setActive(1)}
              className={active === 1 ? 'active' : ''}>
              2슬라이딩 도어
            </Link>
          </li>
          <li>
            <Link
              href="!#"
              onClick={() => setActive(2)}
              className={active === 2 ? 'active' : ''}>
              연동 도어
            </Link>
          </li>
          <li>
            <Link
              href="!#"
              onClick={() => setActive(3)}
              className={active === 3 ? 'active' : ''}>
              여닫이 도어
            </Link>
          </li>
          <li>
            <Link
              href="!#"
              onClick={() => setActive(4)}
              className={active === 4 ? 'active' : ''}>
              스윙 도어
            </Link>
          </li>
          <li>
            <Link
              href="!#"
              onClick={() => setActive(5)}
              className={active === 5 ? 'active' : ''}>
              3연동 하드웨어
            </Link>
          </li>
        </List>
        <TabBox>
          <Box>
            <Row>
              {active === 0 ? (
                <img
                  src={oneSlidingInstallGuide}
                  alt="oneSlidingInstallGuide"
                />
              ) : (
                ''
              )}
              {active === 1 ? (
                <img
                  src={twoSlidingInstallGuide}
                  alt="twoSlidingInstallGuide"
                />
              ) : (
                ''
              )}
              {active === 2 ? (
                <img
                  src={linkSlidingInstallGuide}
                  alt="linkSlidingInstallGuide"
                />
              ) : (
                ''
              )}
              {active === 3 ? (
                <img src={hingeInstallGuide} alt="hingeInstallGuide" />
              ) : (
                ''
              )}
            </Row>
          </Box>
        </TabBox>
      </Container>
    </>
  );
};

export default Knowledge;
