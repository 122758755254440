import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';
import Axios from 'axios';
import UserContext from '../../Context/UserContext';
import ErrorNotice from './ErrorNotice';
import validator from 'validator';
import EtcHeader from './EtcHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  font-family: 'Noto Sans KR', sans-serif;
  margin-bottom: 100px;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div`
  width: 70%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-top: 2px solid #000;
  background: #f5f5f5;
  padding: 40px;
  margin-bottom: 30px;
  text-align: center;
  > form > div {
    text-align: center;
    padding: 20px 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const Title = styled.h1`
  text-align: center;
  padding-bottom: 20px;
  font-size: 27px;
  font-weight: 700;
  color: #333;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Desc = styled.p`
  text-align: center;
  padding-bottom: 30px;
  font-size: 16px;
  line-height: 24px;
  word-break: keep-all;
  font-weight: 500;
  color: #5d5d5d;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const List = styled.ul``;

const Item = styled.li`
  padding: 3px 0;
`;

const SSubject = styled.span`
  width: 19%;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  color: #000;
  @media (max-width: 768px) {
    width: 20%;
    font-size: 14px;
  }
`;

const Input = styled.input`
  width: 40%;
  border: 1px solid #ccc;
  padding: 10px;
  font-weight: 300;
  color: #5d5d5d;
  font-family: 'Noto Sans KR', sans-serif;
`;

const Btn = styled.input`
  background: #333;
  padding: 10px 50px;
  color: #fff;
  font-size: 19px;
  border: 0;
  font-weight: 400;
  cursor: pointer;
`;

const SmallBtn = styled.a`
  color: #666;
  border: 1px solid #bbb;
  text-align: center;
  font-size: 13px;
  padding: 5px 20px;
  font-weight: 600;
  margin: 0 1px;
`;

const Login = () => {
  const info = { title: '로그인', path: '/user/login' };
  const [loginInfo, setLoginInfo] = useState({
    userId: '',
    password: '',
  });
  const [error, setError] = useState();

  const { userData, setUserData } = useContext(UserContext);

  const handleChange = (e) => {
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!loginInfo.userId) return alert('아이디는 반드시 기입하셔야 합니다.');
      if (!validator.isEmail(loginInfo.userId))
        return alert(
          '아이디는 형식이 잘못되었습니다. 아이디는 이메일 형식입니다.'
        );
      if (!loginInfo.password) return alert('비밀번호가 입력되지 않았습니다.');
      const loginUser = {
        email: loginInfo.userId,
        password: loginInfo.password,
      };
      const loginRes = await Axios.post('/users/login', loginUser);
      console.log(loginRes);
      setUserData({
        token: loginRes.data.token,
        user: loginRes.data.user,
      });
      localStorage.setItem('auth-token', loginRes.data.token);
      window.location.href = '/';
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg);
    }
  };

  return (
    <>
      <EtcHeader />
      <Container>
        <Subject info={info} />
        <Box>
          <form>
            <Title>회원 로그인</Title>
            <Desc>
              지금 로그인하시면, 회원님을위한 서비스를 막힘없이 이용하실 수
              있습니다.
            </Desc>
            <List>
              <Item>
                <SSubject>아이디</SSubject>
                <Input
                  type="text"
                  name="userId"
                  required
                  placeholder="이메일을 입력하세요"
                  onChange={handleChange}
                />
              </Item>
              <Item>
                <SSubject>비밀번호</SSubject>
                <Input
                  type="password"
                  name="password"
                  required
                  placeholder="비밀번호를 입력하세요"
                  onChange={handleChange}
                />
              </Item>
            </List>
            <div>
              {error && (
                <ErrorNotice
                  message={error}
                  clearError={() => setError(undefined)}
                />
              )}
              <Btn type="submit" value="LOGIN" onClick={handleSubmit} />
            </div>
            <div>
              <SmallBtn href="/user/join">회원가입</SmallBtn>
              <SmallBtn href="">아이디찾기</SmallBtn>
              <SmallBtn href="">비밀번호찾기</SmallBtn>
            </div>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default Login;
