import React from 'react';
import styled from 'styled-components';
import ProductCard from './ProductCard';

import { productArr } from './datas';

const Container = styled.section`
  width: 100%;
  padding: 80px 20px;
  background: #fff;
  @media screen and (max-width: 1080px) {
    padding: 50px 20px;
  }
`;

const Wrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: #1e1e1e;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  padding-bottom: 15px;
  font-family: 'Montserrat', 'Noto Sans KR', sans-serif;

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 26px;
  }
  @media (max-width: 1080px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

const Desc = styled.p`
  padding-bottom: 40px;
  font-size: 14px;
  line-height: 20px;
  word-break: keep-all;
  color: #666666;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  text-align: center;
  @media (max-width: 1080px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Products = () => {
  return (
    <Container>
      <Wrap>
        <Title data-aos="fade-up" data-aos-duration="2000">
          Product
        </Title>
        <Desc data-aos="fade-up" data-aos-duration="2000">
          다양한 제품군을 통하여 효과적이고 편리한 인테리어 시스템 도어를
          설치하실 수 있습니다.
        </Desc>
        <Box>
          {productArr.map((info, index) => (
            <ProductCard key={index} info={info} />
          ))}
        </Box>
      </Wrap>
    </Container>
  );
};

export default Products;
