import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';
import apartment1 from '../../assets/img/portfolio/apartment/apartment1.png';
import apartment2 from '../../assets/img/portfolio/apartment/apartment2.png';
import apartment3 from '../../assets/img/portfolio/apartment/apartment3.png';
import apartment4 from '../../assets/img/portfolio/apartment/apartment4.png';
import apartment5 from '../../assets/img/portfolio/apartment/apartment5.png';
import apartment6 from '../../assets/img/portfolio/apartment/apartment6.png';
import apartment7 from '../../assets/img/portfolio/apartment/apartment7.png';
import apartment8 from '../../assets/img/portfolio/apartment/apartment8.png';
import apartment9 from '../../assets/img/portfolio/apartment/apartment9.png';
import apartment10 from '../../assets/img/portfolio/apartment/apartment10.png';
import apartment11 from '../../assets/img/portfolio/apartment/apartment11.png';
import apartment12 from '../../assets/img/portfolio/apartment/apartment12.png';
import apartment13 from '../../assets/img/portfolio/apartment/apartment13.png';
import dressMain from '../../assets/img/portfolio/apartment/dressRoom/dressMain.png';
import dressRoom1 from '../../assets/img/portfolio/apartment/dressRoom/dressRoom1.png';
import dressRoom2 from '../../assets/img/portfolio/apartment/dressRoom/dressRoom2.png';
import dressRoom3 from '../../assets/img/portfolio/apartment/dressRoom/dressRoom3.png';
import dressRoom4 from '../../assets/img/portfolio/apartment/dressRoom/dressRoom4.png';
import kitchen1 from '../../assets/img/portfolio/apartment/kitchen/kitchen1.png';
import kitchen2 from '../../assets/img/portfolio/apartment/kitchen/kitchen2.png';
import kitchen3 from '../../assets/img/portfolio/apartment/kitchen/kitchen3.png';
import kitchen4 from '../../assets/img/portfolio/apartment/kitchen/kitchen4.png';
import kitchen5 from '../../assets/img/portfolio/apartment/kitchen/kitchen5.png';
import kitchen6 from '../../assets/img/portfolio/apartment/kitchen/kitchen6.png';
import alpha1 from '../../assets/img/portfolio/apartment/alpha/alpha1.png';
import alpha2 from '../../assets/img/portfolio/apartment/alpha/alpha2.png';
import alpha3 from '../../assets/img/portfolio/apartment/alpha/alpha3.png';
import PortfolioHeader from './PortfolioHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div`
  width: 100%;
  margin: 0;
  padding: 60px 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  @media (max-width: 1080px) {
    padding: 50px 0px;
  }
  @media (max-width: 768px) {
    padding: 40px 0px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  padding-bottom: 15px;
  @media (max-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const Name = styled.h2`
   {
    color: #00529b;
    padding: 15px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 0;
    margin-bottom: 10px;
  }
  @media (max-width: 1080px) {
    font-size: 17px;
    line-height: 24px;
  }
`;

const Desc = styled.p`
  font-size: 17px;
  line-height: 24px;
  word-break: keep-all;
  margin-bottom: 50px;
  @media (max-width: 1080px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }
`;

const ImgBox = styled.div`
  width: 1200px;
  margin-bottom: 30px;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  > img {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  @media (max-width: 1280px) {
    margin-bottom: 40px;
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Column = styled.div`
  width: 31%;
  margin: 1%;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 30px;
    margin-bottom: 20px;
  }
  > img {
    width: 100%;
  }
`;

const ColumnHalf = styled(Column)`
  width: 48%;
`;
const Apartment = () => {
  const info = { title: '아파트', path: '/portfolio/apartment' };
  return (
    <>
      <PortfolioHeader />
      <Container>
        <Subject info={info} />
        <Box>
          <Title data-aos="fade-up" data-aos-duration="2000">
            현관 중문
          </Title>
          <ImgBox data-aos="fade-up" data-aos-duration="2000">
            <img src={apartment1} alt="apartment1" />
          </ImgBox>
          <Name data-aos="fade-up" data-aos-duration="2000">
            현관중문
          </Name>
          <Desc data-aos="fade-up" data-aos-duration="2000">
            슬림한 프레임으로 공간 분할 디자인이 돋보이는 공간을 연출해줍니다.
            또한 냉/난방 효율 증가 및 먼지 등 냄새의 실내 유입을 막아줍니다.
            최근에는 반려동물을 위하여 방음 효과의 목적으로도 쓰입니다.
          </Desc>
          <Row>
            <Column>
              <img
                src={apartment2}
                alt="apartment2"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={apartment3}
                alt="apartment3"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={apartment4}
                alt="apartment4"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={apartment5}
                alt="apartment5"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={apartment6}
                alt="apartment6"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={apartment7}
                alt="apartment7"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={apartment8}
                alt="apartment8"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={apartment9}
                alt="apartment9"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={apartment10}
                alt="apartment10"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={apartment11}
                alt="apartment11"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={apartment12}
                alt="apartment12"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={apartment13}
                alt="apartment13"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
        </Box>
        <Box>
          <Title data-aos="fade-up" data-aos-duration="2000">
            주방 및 드레스룸
          </Title>
          <ImgBox>
            <img
              src={dressMain}
              alt="dressMain"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          </ImgBox>
          <Name data-aos="fade-up" data-aos-duration="2000">
            드레스룸 & 붙박이장
          </Name>
          <Desc data-aos="fade-up" data-aos-duration="2000">
            심플하게 그러나 고급스럽게 주거공간의 품격을 높여 드립니다. 값비싼
            가구보다 더욱 실용성 있고 고급스러운 인테리어 시스템 도어를
            추천드립니다.
          </Desc>
          <Row>
            <ColumnHalf>
              <img
                src={dressRoom1}
                alt="dressRoom1"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </ColumnHalf>
            <ColumnHalf>
              <img
                src={dressRoom2}
                alt="dressRoom2"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </ColumnHalf>
          </Row>
          <Row>
            <ColumnHalf>
              <img
                src={dressRoom3}
                alt="dressRoom3"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </ColumnHalf>
            <ColumnHalf>
              <img
                src={dressRoom4}
                alt="dressRoom4"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </ColumnHalf>
          </Row>
          <Name data-aos="fade-up" data-aos-duration="2000">
            주방 & 다이닝룸
          </Name>
          <Desc data-aos="fade-up" data-aos-duration="2000">
            정형화된 주방의 디자인을 탈피 할 수 있는 기회 맞춤형 주방을 구성할
            수 있습니다. 주방을 더욱 아늑하게, 냄새와 소음 모두 중문으로 해결할
            수 있습니다.
          </Desc>
          <Row>
            <ColumnHalf>
              <img
                src={kitchen1}
                alt="kitchen1"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </ColumnHalf>
            <ColumnHalf>
              <img
                src={kitchen2}
                alt="kitchen2"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </ColumnHalf>
          </Row>
          <Row>
            <ColumnHalf>
              <img
                src={kitchen3}
                alt="kitchen3"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </ColumnHalf>
            <ColumnHalf>
              <img
                src={kitchen4}
                alt="kitchen4"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </ColumnHalf>
          </Row>
          <Row>
            <ColumnHalf>
              <img
                src={kitchen5}
                alt="kitchen5"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </ColumnHalf>
            <ColumnHalf>
              <img
                src={kitchen6}
                alt="kitchen6"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </ColumnHalf>
          </Row>
          <Name data-aos="fade-up" data-aos-duration="2000">
            알파룸 & 기타공간
          </Name>
          <Desc data-aos="fade-up" data-aos-duration="2000">
            불필요한 공간을 인테리어 도어의 설치만으로 유용하게 사용하실 수
            있습니다.
          </Desc>
          <Row>
            <Column>
              <img
                src={alpha1}
                alt="alpha1"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={alpha2}
                alt="alpha2"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={alpha3}
                alt="alpha3"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
        </Box>
      </Container>
    </>
  );
};

export default Apartment;
