import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import main_Img01 from '../../assets/img/home/mainImg1.jpeg';
import main_Img02 from '../../assets/img/home/mainImg2.jpeg';
import main_Img03 from '../../assets/img/home/mainImg3.jpeg';

const Container = styled.div`
  width: 100%;
  height: 733px;
  @media (max-width: 1920px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 1080px) {
    height: 450px;
    background-size: auto 100% !important;
  }
  @media (max-width: 768px) {
    height: 360px;
  }
`;

const Background = styled.div`
  @keyframes moveUp {
    0% {
      transform: translateY(30px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  background: url(${(props) => props.back}) center;
  background-size: cover !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 733px;
  @media (max-width: 1920px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
    background-position-x: ${(props) => props.position};
  }
  @media (max-width: 1080px) {
    height: 450px;
    background-size: auto 100% !important;
  }
  @media (max-width: 768px) {
    height: 360px;
  }
`;

const NavBox = styled.div`
  padding: 0 10px;
  z-index: 100;
`;

const Arrow = styled.span`
  cursor: pointer;
  > i {
    font-size: 30px;
    color: #1f1f1f;
  }
`;

// const CenterBox = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   height: 100%;
// `;

// const Wrap = styled.div`
//   position: absolute;
//   top: 25%;
//   text-align: center;
//   letter-spacing: -0.4px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   text-align: center;
//   @media (max-width: 768px) {
//     top: 25%;
//   }
//   @media (max-width: 480px) {
//     top: 25%;
//   }
//   display: ${(props) => props.display};
// `;

// const Title = styled.h1`
//   @keyframes moveUp {
//     0% {
//       transform: translateY(30px);
//     }
//     100% {
//       transform: translateY(0px);
//     }
//   }
//   -webkit-animation: moveUp 1.2s ease-in-out both;
//   -moz-animation: moveUp 1.2s ease-in-out both;
//   -o-animation: moveUp 1.2s ease-in-out both;
//   -ms-animation: moveUp 1.2s ease-in-out both;
//   animation: moveUp 1.2s ease-in-out both;
//   font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
//   color: ${(props) => props.color};
//   display: block;
//   font-size: 42px;
//   line-height: 50px;
//   font-weight: 800;
//   padding-bottom: 20px;
//   @media (max-width: 1080px) {
//     font-size: 32px;
//     line-height: 36px;
//     padding-bottom: 10px;
//   }
//   @media (max-width: 768px) {
//     font-size: 24px;
//     line-height: 30px;
//     color: ${(props) => props.color2};
//   }
//   @media (max-width: 480px) {
//     font-size: 24px;
//     line-height: 24px;
//   }
// `;

// const Desc = styled.p`
//   @keyframes moveUp2 {
//     0% {
//       transform: translateY(20px);
//     }
//     100% {
//       transform: translateY(0px);
//     }
//   }
//   -webkit-animation: moveUp2 1.2s ease-in-out both;
//   -moz-animation: moveUp2 1.2s ease-in-out both;
//   -o-animation: moveUp2 1.2s ease-in-out both;
//   -ms-animation: moveUp2 1.2s ease-in-out both;
//   animation: moveUp2 1.2s ease-in-out both;
//   font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
//   word-break: keep-all;
//   color: ${(props) => props.color};
//   font-size: 16px;
//   line-height: 24px;
//   font-weight: 400;
//   @media (max-width: 1080px) {
//     font-size: 14px;
//     line-height: 20px;
//   }
//   @media (max-width: 768px) {
//     font-size: 16px;
//     line-height: 18px;
//     padding-bottom: 5px;
//     color: ${(props) => props.color2};
//   }
// `;

const PreLoading = styled.div`
  display: none;
`;

const SlideImg = () => {
  const [index, setIndex] = useState(0);
  const [display, setDisplay] = useState(['block', 'none', 'none']);
  const [positions, setPositions] = useState('-150px');

  const SlideInfos = [
    {
      img: main_Img01,
      link: '/company/intro',
      color: '#1f1f1f',
      color2: '#1f1f1f',
      position: positions,
      display: display[0],
    },
    {
      img: main_Img02,
      link: '/company/ceo',
      color: '#1f1f1f',
      color2: '#1f1f1f',
      position: positions,
      display: display[1],
    },
    {
      img: main_Img03,
      link: '/company/map',
      color: '#fff',
      color2: '#fff',
      position: positions,
      display: display[2],
    },
  ];

  const CaptionInfos = [
    ['none', 'none', 'block'],
    ['none', 'block', 'none'],
    ['block', 'none', 'none'],
  ];

  const BackImgs = [main_Img01, main_Img02, main_Img03];

  const Positions = ['-150px', '-260px', '-550px'];

  const [backImg, setBackImg] = useState(main_Img01);

  const handleTotal = (i) => {
    setIndex(i);
    setBackImg(BackImgs[i]);
    setPositions(Positions[i]);
    setDisplay(CaptionInfos[i]);
  };

  const handlePrev = () => {
    if (index === 0) {
      handleTotal(2);
    } else {
      handleTotal(index - 1);
    }
  };

  const handleNext = () => {
    if (index === 2) {
      handleTotal(0);
    } else {
      handleTotal(index + 1);
    }
  };

  useEffect(() => {
    const next = (index + 1) % SlideInfos.length;
    const id = setTimeout(() => {
      handleTotal(next);
    }, 5000);
    return () => clearTimeout(id);
  }, [index]);

  return (
    <Container>
      <PreLoading>
        <img src={main_Img01} alt="mainImg1" />
        <img src={main_Img02} alt="mainImg2" />
        <img src={main_Img03} alt="mainImg3" />
      </PreLoading>
      <Background back={backImg} position={positions}>
        <NavBox onClick={handlePrev}>
          <Arrow>
            <i className="fas fa-chevron-left"></i>
          </Arrow>
        </NavBox>
        {/* <CenterBox>
          {SlideInfos.map((info, index) => (
            <Wrap key={index} display={info.display}>
              <Title color={info.color} color2={info.color2}>
                {info.title}
              </Title>
              <Desc
                dangerouslySetInnerHTML={{
                  __html: info.desc,
                }}
                color={info.color}
                color2={info.color2}></Desc>
            </Wrap>
          ))}
        </CenterBox> */}
        <NavBox onClick={handleNext}>
          <Arrow>
            <i className="fas fa-chevron-right"></i>
          </Arrow>
        </NavBox>
      </Background>
    </Container>
  );
};

export default SlideImg;
