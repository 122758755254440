import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';
import { mapData } from './companyData';
import CompanyHeader from './CompanyHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 120px;
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Address = styled.ul`
  border-top: 1px solid #ddd;
  padding-bottom: 0 !important;
  > li {
    width: 100%;
    display: table;
    position: relative;
    border-bottom: 1px solid #ddd;
    @media (max-width: 480px) {
      padding: 15px;
    }
    > div {
      font-size: 17px;
      &:first-child {
        color: #000;
        background: #f5f5f5;
        width: 20%;
        display: table-cell;
        vertical-align: middle;
        padding: 20px 30px;
        @media (max-width: 768px) {
          width: 25%;
          padding: 15px;
        }
        @media (max-width: 480px) {
          width: 100%;
          display: inline-block;
          background: #fff;
          padding: 0;
          padding-bottom: 5px;
        }
        > i {
          color: #001f60;
          font-size: 17px;
          margin-right: 15px;
          vertical-align: middle;
          @media (max-width: 480px) {
            font-size: 14px;
            margin-right: 7px;
          }
        }
      }
      &:last-child {
        color: #5d5d5d;
        letter-spacing: -0.4px;
        word-break: keep-all;
        vertical-align: middle;
        padding: 20px 30px;
        font-size: 17px;
        @media (max-width: 480px) {
          font-size: 14px;
          width: 100%;
          display: inline-block;
          background: #fff;
          padding: 0;
          padding-bottom: 5px;
          line-height: 25px;
        }
      }
    }
  }
`;

const MapBox = styled.div`
  height: 450px;
  margin-bottom: 30px;
`;

const Map = () => {
  const info = { title: '오시는 길', path: '/company/map' };
  return (
    <>
      <CompanyHeader />
      <Container>
        <Subject info={info} />
        <MapBox data-aos="fade-up" data-aos-duration="2000">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3159.617417413872!2d127.28945281567344!3d37.63468617978511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ccaae74c315d9%3A0xde134ebf1fe005d4!2z6rK96riw64-EIOuCqOyWkeyjvOyLnCDtmZTrj4TsnY0g7IiY66CI66GcOTYx67KI6ri4IDI1!5e0!3m2!1sko!2skr!4v1594796370846!5m2!1sko!2skr"
            frameBorder="0"
            width="100%"
            height="100%"
            allowFullScreen
            title="companyMap"></iframe>
        </MapBox>
        <Address data-aos="fade-up" data-aos-duration="2000">
          {mapData.map((data, index) => (
            <li key={index}>
              <div>
                <i className={data.icon}></i>
                {data.title}
              </div>
              <div>{data.content}</div>
            </li>
          ))}
        </Address>
      </Container>
    </>
  );
};

export default Map;
