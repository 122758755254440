import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';

import oneLineImg from '../../assets/img/customer/estimate/oneLineImg.png';
import threeLineImg from '../../assets/img/customer/estimate/threeLineImg.png';
import hingeLineImg from '../../assets/img/customer/estimate/hingeLineImg.png';
import swingLineImg from '../../assets/img/customer/estimate/swingLineImg.png';
import fixLineImg from '../../assets/img/customer/estimate/fixLineImg.png';
import EstimateTable from './EstimateTable';
import EstimateTableFix from './EstimateTableFix';
import EstimateTableLink from './EstimateTableLink';
import EstimateTableHinge from './EstimateTableHinge';
import EstimateTableSwing from './EstimateTableSwing';
import CustomerHeader from './CustomerHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div``;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;
  align-items: center;
`;

const Column = styled.div`
  width: 60%;
  margin: 1%;
  padding: 10px 0;
  &:last-child {
    width: 35%;
    display: flex;
  }
  > img {
    width: 100%;
  }
`;

const Form = styled.form`
  input,
  select {
    width: 80%;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 15px;
    color: #333333;
    font-weight: 300;
    font-family: 'Noto Sans KR', sans-serif;
  }
  label {
    font-size: 15px;
    font-weight: 600;
    padding: 5px 10px;
  }
  > ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 100%;
      padding: 5px 0;
    }
  }
`;

const Estimate = () => {
  const info = { title: '온라인견적', path: '/customer/estimate' };

  return (
    <>
      <CustomerHeader />

      <Container>
        <Subject info={info} />
        <Box>
          <Row>
            <Column>
              <img src={oneLineImg} alt="oneLineImg" />
            </Column>
            <Column>
              <EstimateTable />
            </Column>
          </Row>
          <Row>
            <Column>
              <img src={threeLineImg} alt="threeLineImg" />
            </Column>
            <Column>
              <EstimateTableLink />
            </Column>
          </Row>
          <Row>
            <Column>
              <img src={hingeLineImg} alt="hingeLineImg" />
            </Column>
            <Column>
              <EstimateTableHinge />
            </Column>
          </Row>
          <Row>
            <Column>
              <img src={swingLineImg} alt="swingLineImg" />
            </Column>
            <Column>
              <EstimateTableSwing />
            </Column>
          </Row>
          <Row>
            <Column>
              <img src={fixLineImg} alt="swingLineImg" />
            </Column>
            <Column>
              <Form>
                <EstimateTableFix />
              </Form>
            </Column>
          </Row>
        </Box>
      </Container>
    </>
  );
};

export default Estimate;
