const handleOneCost = ({ oneSpec, setOneCost }) => {
  if (oneSpec.oneInstallWidth >= 1450) {
    if (oneSpec.oneInstallHeight > 2400) {
      let defaultCost = 160000;
      handleLocation(oneSpec, defaultCost, setOneCost);
    } else if (oneSpec.oneInstallHeight > 0) {
      let defaultCost = 150000;
      handleLocation(oneSpec, defaultCost, setOneCost);
    } else if (
      (oneSpec.oneInstallHeight = 0 || oneSpec.oneInstallHeight === '')
    ) {
      setOneCost(0);
    }
  } else if (oneSpec.oneInstallWidth > 1299) {
    if (oneSpec.oneInstallHeight > 2400) {
      let defaultCost = 150000;
      handleLocation(oneSpec, defaultCost, setOneCost);
    } else if (oneSpec.oneInstallHeight > 0) {
      let defaultCost = 140000;
      handleLocation(oneSpec, defaultCost, setOneCost);
    } else if (
      (oneSpec.oneInstallHeight = 0 || oneSpec.oneInstallHeight === '')
    ) {
      setOneCost('₩ 0');
    }
  } else if (oneSpec.oneInstallWidth > 0) {
    if (oneSpec.oneInstallHeight > 2400) {
      let defaultCost = 140000;
      handleLocation(oneSpec, defaultCost, setOneCost);
    } else if (oneSpec.oneInstallHeight > 0) {
      let defaultCost = 130000;
      handleLocation(oneSpec, defaultCost, setOneCost);
    } else if (
      (oneSpec.oneInstallHeight = 0 || oneSpec.oneInstallHeight === '')
    ) {
      setOneCost('₩ 0');
    }
  } else if (oneSpec.oneInstallWidth === 0 || oneSpec.oneInstallWidth === '') {
    if ((oneSpec.oneInstallHeight = 0 || oneSpec.oneInstallHeight === '')) {
      setOneCost('₩ 0');
    }
  }
};

export default handleOneCost;

// const handleDelivery = (oneSpec, defaultCost, setOneCost) => {
//   switch (oneSpec.oneDelivery) {
//     case 'knockDown':
//       break;
//     case 'assembly':
//       break;
//     case 'install':
//       break;
//     default:
//       break;
//   }
// };

const handleLocation = (oneSpec, defaultCost, setOneCost) => {
  switch (oneSpec.oneLocation) {
    case 'inSeoul':
      break;
    case 'inGunggi':
      break;
    case 'fromGunggi2hour':
      break;
    case 'fromGunggi4hour':
      break;
    default:
      break;
  }
};
