import React from 'react';
import styled from 'styled-components';

import Whatwedo from './Whatwedo';
import FixBackground from './FixBackground';
import Products from './Products';
import Customer from './Customer';
import SlideImg from './SlideImg';

const Container = styled.div`
  max-width: 100%;
  width: 100%;
`;

const Home = () => {
  return (
    <Container>
      <SlideImg />
      <Whatwedo />
      <FixBackground />
      <Products />
      <Customer />
    </Container>
  );
};

export default Home;
