import React from 'react';
import styled from 'styled-components';
import homeFixbg from '../../assets/img/home/homeFixbg.png';
import { Link } from 'react-router-dom';

const Container = styled.section`
  width: 100%;
  height: 304px;
  background: url(${(props) => props.back}) center top no-repeat;
  background-size: cover;
  padding: 80px 20px;
  background-attachment: fixed;
  @media screen and (max-width: 1080px) {
    background-size: cover;
    height: auto;
    padding: 50px 20px;
    background-attachment: scroll;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: #fff;
  font-size: 34px;
  line-height: 42px;
  font-weight: 500;
  padding-bottom: 20px;
  font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
  @media screen and (max-width: 1080px) {
    font-size: 28px;
    line-height: 34px;
    padding-bottom: 10px;
  }
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 15px;
  }
`;

const Desc = styled.p`
  color: #fff;
  padding-bottom: 25px;
  font-size: 14px;
  line-height: 20px;
  word-break: keep-all;
  text-align: center;
  @media screen and (max-width: 1080px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

const Btn = styled(Link)`
  display: inline-block;
  margin: 0 auto;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.75);
  padding: 8px 30px;
  font-size: 13px;
  cursor: pointer;
  :hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const FixBackground = () => {
  return (
    <Container back={homeFixbg}>
      <Box>
        <Title data-aos="fade-up" data-aos-duration="2000">
          전문적이고 완벽한 최적의 솔루션
        </Title>
        <Desc data-aos="fade-up" data-aos-duration="2000">
          미소테크는 어떠한 환경에서도 고객이 요구하는 니즈를 충족시켜 드리기
          위하여 노력합니다.
        </Desc>
        <Btn to="/customer/staff" data-aos="fade-up" data-aos-duration="2000">
          자세히보기>>
        </Btn>
      </Box>
    </Container>
  );
};

export default FixBackground;
