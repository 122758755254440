import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.footer`
  background: #333;
  padding: 30px 20px;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
`;

const Wrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > ul {
    display: flex;
  }
  &:first-child {
    > ul {
      > li {
        padding-right: 6px;
        > a {
          display: block;
          color: #fff;
          width: 42px;
          height: 42px;
          font-size: 17px;
          padding-top: 10px;
          border-radius: 30px;
          background-color: rgba(255, 255, 255, 0.1);
          :hover {
            background-color: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }
  &:nth-child(2) {
    width: 100%;
    padding: 20px 0;
    flex-direction: column;
    > ul {
      justify-content: center;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      > li {
        color: #ddd;
        display: inline-block;
        padding-right: 20px;
        @media (max-width: 768px) {
          width: 100%;
          padding: 0;
        }
      }
    }
    > span {
      width: 100%;
      color: #ddd;
    }
  }
  &:last-child {
    > ul {
      > li {
        display: inline-block;
        padding-right: 2px;
        > a {
          display: block;
          color: #bbb;
          font-size: 11px;
          border: 1px solid #a0a0a0;
          padding: 3px 10px;
          :hover {
            background: #999;
            border: 1px solid #999;
            color: #fff;
          }
        }
      }
    }
  }
`;

const Footer = () => {
  return (
    <Container>
      <Wrap>
        <Box>
          <ul>
            <li>
              <a
                href="https://www.instagram.com/misotech_slimdoor/?hl=ko"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <Link to="#">
                <i className="fab fa-facebook-square"></i>
              </Link>
            </li>
            <li>
              <Link to="#">
                <i className="fab fa-blogger"></i>
              </Link>
            </li>
          </ul>
        </Box>
        <Box>
          <ul>
            <li>상호 : MISO TECH</li>
            <li>주소 : 경기도 남양주시 화도읍 수레로 961번길 25-30</li>
            <li>TEL : 031-594-1042</li>
            <li>사업자 등록번호 : 724-81-01569</li>
          </ul>
          <span>Copyright(c) MISO TECH. All Rights Reserved.</span>
        </Box>
        <Box>
          <ul>
            <li>
              <Link to="/company/intro">회사소개</Link>
            </li>
            <li>
              <Link to="/user/privacy">개인정보처리방침</Link>
            </li>
            <li>
              <Link to="#">관리자 페이지</Link>
            </li>
          </ul>
        </Box>
      </Wrap>
    </Container>
  );
};

export default Footer;
