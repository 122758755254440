import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';
import officeImg1 from '../../assets/img/portfolio/office/officeImg1.png';
import officeImg2 from '../../assets/img/portfolio/office/officeImg2.png';
import officeImg3 from '../../assets/img/portfolio/office/officeImg3.png';
import officeImg4 from '../../assets/img/portfolio/office/officeImg4.png';
import officeImg5 from '../../assets/img/portfolio/office/officeImg5.png';
import officeImg6 from '../../assets/img/portfolio/office/officeImg6.jpg';
import officeImg7 from '../../assets/img/portfolio/office/officeImg7.jpg';
import PortfolioHeader from './PortfolioHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div`
  width: 100%;
  margin: 0;
  padding: 60px 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  @media (max-width: 1080px) {
    padding: 50px 0px;
  }
  @media (max-width: 768px) {
    padding: 40px 0px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  padding-bottom: 15px;
  @media (max-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const Name = styled.h2`
   {
    color: #00529b;
    padding: 15px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 0;
    margin-bottom: 10px;
  }
  @media (max-width: 1080px) {
    font-size: 17px;
    line-height: 24px;
  }
`;

const Desc = styled.p`
  font-size: 17px;
  line-height: 24px;
  word-break: keep-all;
  margin-bottom: 50px;
  @media (max-width: 1080px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }
`;

const ImgBox = styled.div`
  width: 1200px;
  margin-bottom: 30px;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  > img {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const Column = styled.div`
  width: 48%;
  margin: 1%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 20px;
  }
  > img {
    width: 100%;
  }
`;

const Office = () => {
  const info = { title: '사무실', path: '/portfolio/office' };

  return (
    <>
      <PortfolioHeader />
      <Container>
        <Subject info={info} />
        <Box>
          <Title data-aos="fade-up" data-aos-duration="2000">
            사무실
          </Title>
          <ImgBox data-aos="fade-up" data-aos-duration="2000">
            <img src={officeImg1} alt="officeImg1" />
          </ImgBox>
          <Name data-aos="fade-up" data-aos-duration="2000">
            사무실 및 레스토랑
          </Name>
          <Desc data-aos="fade-up" data-aos-duration="2000">
            주거 공간 뿐만 아니라 사무실 및 레스토랑 등 상업공간에서 파티션과
            더불어 공간을 효과적으로 분할 할 수 있습니다.
          </Desc>
          <Row>
            <Column>
              <img
                src={officeImg6}
                alt="officeImg6"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={officeImg7}
                alt="officeImg7"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={officeImg2}
                alt="officeImg2"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={officeImg3}
                alt="officeImg3"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={officeImg4}
                alt="officeImg4"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={officeImg5}
                alt="officeImg5"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
        </Box>
      </Container>
    </>
  );
};

export default Office;
