import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';
import CustomerHeader from './CustomerHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-top: 1px #333 solid;
  border-bottom: 1px #4fb3d2 solid;
  border-left: 1px #e5e5e5 solid;
  > tbody {
    > tr {
      height: 30px;
      > td {
        font-size: 15px;
        line-height: 22px;
        border-bottom: 1px #e5e5e5 solid;
        border-right: 1px #e5e5e5 solid;
        padding: 5px;
        width: 80%;
        &:first-child {
          width: 20%;
          text-align: center;
          background-color: #f7f7f7;
        }
        &:last-child {
          > input {
            width: 90%;
            border: 1px solid #ccc;
            padding: 7px;
            font-size: 12px;
            color: #333333;
            font-weight: 300;
            font-family: 'Noto Sans KR', sans-serif;
          }
        }
      }
      &:first-child {
        > td {
          &:last-child {
            > select {
              font-weight: 300;
              color: #5d5d5d;
              font-family: 'Noto Sans KR', sans-serif;
            }
          }
        }
      }
      &:nth-child(2) {
        > td {
          &:last-child {
            > input {
              width: 90%;
            }
          }
        }
      }
      &:nth-child(3) {
        > td {
          &:last-child {
            > input {
              max-width: 70px;
              width: 90%;
            }
          }
        }
      }
      &:nth-child(7) {
        > td {
          vertical-align: middle;
          > textarea {
            width: 100%;
            height: 300px;
            font-weight: 300;
            color: #5d5d5d;
            font-family: 'Noto Sans KR', sans-serif;
          }
        }
      }
      &:nth-child(8) {
        > td {
          > input {
            border: 0;
            background: none;
            padding: 0;
          }
        }
      }
      &:nth-child(9) {
        > td {
          &:last-child {
            > input {
              max-width: 70px;
              width: 90%;
            }
          }
        }
      }
      &:last-child {
        > td {
          padding: 10px;
          width: 100%;
          background-color: #fff;
          > table {
            width: 100%;
            > tbody {
              > tr {
                &:first-child {
                  > td {
                    width: 50%;
                    text-align: left;
                    padding: 5px;
                    @media (max-width: 480px) {
                      display: flex;
                      width: 100%;
                    }
                    &:last-child {
                      text-align: right;
                    }
                  }
                }
                &:last-child {
                  > td {
                    padding: 5px;
                    > textarea {
                      width: 100%;
                      font-weight: 300;
                      color: #5d5d5d;
                      font-family: 'Noto Sans KR', sans-serif;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const BtnBox = styled.div`
  width: 100%;
  text-align: center;
  padding: 40px 0;
`;

const Btn = styled.a`
  margin: 0 5px;
  font-size: 15px;
  font-weight: 500;
  color: white;
  background: #999999;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background: #fff;
    color: #999999;
    &:first-child {
      background: #fff;
      color: #666666;
    }
  }
  &:first-child {
    background: #666666;
    border: 1.5px solid #666666;
  }
  &:last-child {
    border: 1.5px solid #999999;
  }
`;

const Counsel = () => {
  const info = { title: '고객상담', path: '/customer/counsel' };

  return (
    <>
      <CustomerHeader />
      <Container>
        <Subject info={info} />
        <Table>
          <tbody>
            <tr>
              <td>구 분</td>
              <td>
                <select>
                  <option>견적문의</option>
                  <option>대리점계약</option>
                  <option>A/S문의</option>
                  <option>기타</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>이 름</td>
              <td>
                <input type="text" />
              </td>
            </tr>
            <tr>
              <td>전화번호</td>
              <td>
                <input type="text" />-<input type="text" />-
                <input type="text" />
              </td>
            </tr>
            <tr>
              <td>이메일</td>
              <td>
                <input type="text" />
              </td>
            </tr>
            <tr>
              <td>주 소</td>
              <td>
                <input type="text" />
              </td>
            </tr>
            <tr>
              <td>제 목</td>
              <td>
                <input type="text" />
              </td>
            </tr>
            <tr>
              <td>내 용</td>
              <td>
                <textarea name="" id="" cols="30" rows="10"></textarea>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <table>
                  <tbody>
                    <tr>
                      <td>· 개인정보의 수집 및 이용목적</td>
                      <td>
                        <input type="checkbox" name="" id="" />
                        개인정보의 수집 및 이용목적에 동의합니다.
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          placeholder={`▶ 개인정보의 수집 및 이용목적
- 서비스 이용에 따른 본인식별, 실명확인, 가입의사 확인, 연령제한 서비스 이용
- 고지사항 전달, 불만처리 의사소통 경로 확보, 물품배송 시 정확한 배송지 정보 확보
- 신규 서비스 등 최신정보 안내 및 개인맞춤서비스 제공을 위한 자료
- 기타 원활한 양질의 서비스 제공 등

▶ 수집하는 개인정보의 항목
- 이름, 이메일, 주민등록번호, 주소, 연락처, 핸드폰번호, 그 외 선택항목

▶ 개인정보의 보유 및 이용기간
- 원칙적으로 개인정보의 수집 또는 제공받은 목적 달성 시 지체 없이 파기합니다.
- 다만, 원활한 서비스의 상담을 위해 상담 완료 후 내용을 3개월간 보유할 수 있으며
  전자상거래에서의 소비자보호에 관한 법률 등 타법률에 의해 보존할 필요가 있는 경우에는 일정기간 보존합니다.

  `}></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </Table>
        <BtnBox>
          <Btn>확인</Btn>
          <Btn>취소</Btn>
        </BtnBox>
      </Container>
    </>
  );
};

export default Counsel;
