import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  opacity: 0;
  width: 30%;
  margin: 0.65%;
  border: 1px solid #d5d5d5;
  background: #fff;
  padding: 0 0 20px 0;
  @media (max-width: 768px) {
    width: 45% !important;
    display: block;
    margin: 2%;
  }
  @media (max-width: 480px) {
    width: 90% !important;
    margin: 2%;
    display: block;
  }
`;

const Imgwrap = styled.div`
  width: 100%;
  :hover {
    opacity: 0.6;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }
`;

const Img = styled.img`
  width: 100%;
`;

const Descwrap = styled.div`
  background: #fff;
`;

const Title = styled.span`
  padding: 24px;
  text-align: center;
  display: inline-block;
  width: 100%;
  > a {
    font-size: 17px;
    font-weight: 500;
    color: #000;
  }
`;

const Desc = styled.p`
  padding: 0 27px;
  font-size: 14px;
  line-height: 22px;
  word-break: keep-all;
  font-weight: 500;
  text-align: center;
  color: #666;
`;

const ProductCard = ({ info }) => {
  return (
    <Container data-aos="fade-up" data-aos-duration="2000">
      <Imgwrap>
        <Link to={info.link}>
          <Img src={info.img} alt={info.name} />
        </Link>
      </Imgwrap>
      <Descwrap>
        <Title>
          <Link to={info.link}>{info.title}</Link>
        </Title>
        <Desc>{info.desc}</Desc>
      </Descwrap>
    </Container>
  );
};

export default ProductCard;
