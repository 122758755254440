import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 100px 0;
  @media (max-width: 768px) {
    margin: 40px 0;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    width: ${(props) => props.width};
    margin: 30px 4% 30px 4%;
    text-align: center;
    @media (max-width: 768px) {
      width: 90%;
      margin: 30px 2% 30px 2%;
    }
    > img {
      width: 100%;
    }
    > h1 {
      font-size: 24px;
      margin: 20px;
      @media (max-width: 768px) {
        margin: 15px;

        font-size: 16px;
      }
    }
    > p {
      font-size: 18px;
      line-height: 30px;
      color: #1e1e1e;
      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }
`;

const BottomRail = ({ info }) => {
  return (
    <Container width={info.bottomRail.length > 1 ? '40%' : '90%'}>
      {info.bottomRail.map((bottom, index) => (
        <div key={index}>
          <img src={bottom.img} alt={bottom.name} />
          <h1>{bottom.title}</h1>
          {bottom.desc.map((d, index) => (
            <p key={index}>{d}</p>
          ))}
        </div>
      ))}
    </Container>
  );
};

export default BottomRail;
