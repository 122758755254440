import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';

import companyCeoImg_1 from '../../assets/img/company/companyCeoImg_1.png';
import companyCeoImg_2 from '../../assets/img/company/companyCeoImg_2.png';
import ceoOhSignature from '../../assets/img/company/ceoOhSignature.png';
import ceoLeeSignature from '../../assets/img/company/ceoLeeSignature.png';
import CompanyHeader from './CompanyHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
  align-items: center;
  @media (max-width: 917px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    :last-child {
      flex-direction: column-reverse;
    }
    > div {
      padding: 0;
      width: 100%;
    }
  }
`;

const ImgBox = styled.div`
  width: 55%;
  vertical-align: top;
  padding-right: 50px;
  > img {
    width: 100%;
  }
`;

const ImgBox2 = styled(ImgBox)`
  padding-right: 0;
  padding-left: 50px;
`;

const TextBox = styled.div`
  width: 45%;
  @media (max-width: 480px) {
    margin-top: 20px;
  }
  > p:first-child {
    font-size: 16px;
    line-height: 28px;
    color: #00a1e4;
    letter-spacing: 5px;
    font-weight: 600;
    padding-bottom: 10px;
    @media (max-width: 1080px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  > p:nth-child(2) {
    font-size: 30px;
    line-height: 40px;
    color: #1e1e1e;
    padding-bottom: 20px;
    word-break: keep-all;
    @media (max-width: 1080px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  > p:nth-child(3) {
    font-size: 17px;
    line-height: 32px;
    word-break: keep-all;
    color: #5d5d5d;
    @media (max-width: 1080px) {
      font-size: 16px;
      line-height: 28px;
    }
    @media (max-width: 768px) {
      font-size: 15px;
      line-height: 24px;
    }
  }
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 92%;
    color: #888;
    > span {
      padding-left: 20px;
      font-size: 30px;
      font-style: italic;
      color: black;
      &:first-child {
        padding: 0;
        font-size: 17px;
        color: #888;
        font-style: unset;
        @media (max-width: 1080px) {
          font-size: 16px;
          line-height: 28px;
        }
        @media (max-width: 768px) {
          font-size: 15px;
          line-height: 24px;
        }
      }
      @media (max-width: 1080px) {
        font-size: 16px;
        line-height: 28px;
      }
      @media (max-width: 768px) {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
`;

const Ceo = () => {
  const info = { title: 'CEO 인사말', path: '/company/ceo_message' };
  return (
    <>
      <CompanyHeader />
      <Container>
        <Subject info={info} />
        <Box>
          <ImgBox
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="top-center">
            <img src={companyCeoImg_1} alt="companyCeoImg_2" />
          </ImgBox>
          <TextBox
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="top-center">
            <p>CEO MESSAGE</p>
            <p>신뢰를 바탕으로 함께 성장해 나가겠습니다.</p>
            <p>
              고객의 미소가 저희의 행복입니다. 주식회사 미소테크는 전문가
              그룹으로 구성된 조직으로 고객이 원하는 모든 요구를 충족시켜
              드립니다. 그저 물건을 판매하는데 그치지 않고 고객사와 상생하는
              회사입니다.
              <br />
              특히 전문적인 마케팅 전략을 바탕으로, 고객이 원하는 자료와
              서비스를 빠르게 대응해 드립니다. 미소테크의 핵심 가치인 신의를
              바탕으로 고객에게 신뢰받는 기업, 나아가 고객에게 감동을 주는
              기업이 되기 위하여, 전 임직원이 한마음이 되어 최고의 서비스로
              정성을 다하는 기업이 될 것을 약속드립니다.
            </p>
            <div>
              <span>주식회사 미소테크 대표</span>&nbsp;&nbsp;
              <span>Lee.sungtae</span>
              <img src={ceoLeeSignature} alt="ceoLeeSignature" />
            </div>
          </TextBox>
        </Box>
        <Box>
          <TextBox
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="top-center">
            <p>CEO MESSAGE</p>
            <p>기술적 진보와 혁신을 바탕으로 함께 성장해 나가겠습니다.</p>
            <p>
              고객의 미소가 저희의 행복입니다. 주식회사 미소테크는 설립이후
              중문업계에서 끊임없는 성장과 기술적 진보를 일궈내고 있습니다.
              고객의 다양한 요구사항과 신뢰성 높은 기술을 접목하여 최상의 제품을
              공급하는 귀사의 든든한 파트너가 되도록 하겠습니다.
              <br />
              저희 임직원은 고객만족이라는 하나의 목표아래 최고의 품질을 통한
              고객만족을 실현하고 구성원의 역량과 잠재력을 최대한 발휘할 수
              있도록 지원함으로써 더 높아진 위상과 경쟁력을 갖춘 기업으로 발돋움
              할 것이며 공정하고 투명한 경영으로 고객 여러분과 동반 성장하는
              기업이 될 것을 약속드립니다.
            </p>
            <div>
              <span>주식회사 미소테크 대표</span>&nbsp;&nbsp;
              <span>Oh.daewook</span>
              <img src={ceoOhSignature} alt="ceoOhSignature" />
            </div>
          </TextBox>
          <ImgBox2
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="top-center">
            <img src={companyCeoImg_2} alt="companyCeoImg_2" />
          </ImgBox2>
        </Box>
      </Container>
    </>
  );
};

export default Ceo;
