import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 100px 0;
  text-align: center;
  @media (max-width: 768px) {
    margin: 0;
    padding: 40px 0;
  }
  > div {
    display: flex;
    > div {
      width: ${(props) => props.width};
      margin: 0 auto;
      > img {
        width: 95%;
      }
      > h2 {
        padding: 20px 0;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        color: #1e1e1e;
        font-size: 22px;
        font-weight: 600;
        @media (max-width: 768px) {
          padding: 10px 0;
          font-size: 12px;
        }
      }
    }
  }
  > img {
    width: 90%;
    margin: 70px 0;
    @media (max-width: 768px) {
      margin: 10px 0;
    }
  }
  > p {
    padding: 10px 0;
    font-size: 24px;
    color: #1e1e1e;
    word-break: keep-all;
    line-height: 30px;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
      padding: 5px 0px;
    }
  }
`;

const DividingPattern = ({ info }) => {
  return (
    <Container width={info.dividingImgs.lenth > 3 ? '23%' : '31.3%'}>
      <div>
        {info.dividingImgs.map((dividingImg, index) => (
          <div key={index}>
            <img src={dividingImg.img} alt={dividingImg.name} />
            <h2>
              <em>{dividingImg.title[0]}</em>
              {dividingImg.title[1]}
            </h2>
          </div>
        ))}
      </div>
      <img src={info.dividingPatternImg} alt="info.en_title" />
      {info.dividingPatternDesc.map((desc, index) => (
        <p key={index}>{desc}</p>
      ))}
    </Container>
  );
};

export default DividingPattern;
