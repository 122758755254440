import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useState } from 'react';

const Container = styled.div`
  .show {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const Btn = styled.a`
  display: inline-block;
  background-color: #ff9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  ::after {
    font-family: 'Font Awesome 5 Free';
    content: '\f102';
    display: inline-block;
    font-size: 2em;
    vertical-align: middle;
    line-height: 50px;
    color: #fff;
    font-weight: 900;
  }
  :hover {
    cursor: pointer;
    background-color: #333;
  }
  :active {
    background-color: #555;
  }
`;

const ScrollToTop = () => {
  const [clsName, setClsName] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.pageYOffset > 500) {
        setClsName('show');
      } else {
        setClsName('');
      }
    });
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Container>
      <Btn className={clsName} onClick={handleClick}></Btn>
    </Container>
  );
};

export default ScrollToTop;
