import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';
import installImg1 from '../../assets/img/portfolio/install/installMainImg.jpeg';
import installImg2 from '../../assets/img/portfolio/install/install01.png';
import installImg3 from '../../assets/img/portfolio/install/install02.png';
import installImg4 from '../../assets/img/portfolio/install/install03.png';
import installImg5 from '../../assets/img/portfolio/install/install04.png';
import installImg6 from '../../assets/img/portfolio/install/install05.png';
import installImg7 from '../../assets/img/portfolio/install/install06.png';
import PortfolioHeader from './PortfolioHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div`
  width: 100%;
  margin: 0;
  padding: 60px 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  @media (max-width: 1080px) {
    padding: 50px 0px;
  }
  @media (max-width: 768px) {
    padding: 40px 0px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  padding-bottom: 15px;
  @media (max-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const Name = styled.h2`
   {
    color: #00529b;
    padding: 15px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 0;
    margin-bottom: 10px;
  }
  @media (max-width: 1080px) {
    font-size: 17px;
    line-height: 24px;
  }
`;

const Desc = styled.p`
  font-size: 17px;
  line-height: 24px;
  word-break: keep-all;
  margin-bottom: 50px;
  @media (max-width: 1080px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }
`;

const ImgBox = styled.div`
  width: 1200px;
  margin-bottom: 30px;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  > img {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const Column = styled.div`
  width: 48%;
  margin: 1%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 20px;
  }
  > img {
    width: 100%;
  }
`;

const Installation = () => {
  const info = { title: '시공현장', path: '/portfolio/install' };

  return (
    <>
      <PortfolioHeader />

      <Container>
        <Subject info={info} />
        <Box>
          <Title data-aos="fade-up" data-aos-duration="2000">
            시공현장
          </Title>
          <ImgBox data-aos="fade-up" data-aos-duration="2000">
            <img src={installImg1} alt="installImg1" />
          </ImgBox>
          <Name data-aos="fade-up" data-aos-duration="2000">
            시공 및 A/S
          </Name>
          <Desc data-aos="fade-up" data-aos-duration="2000">
            시공 경력이 풍부하고 중문과 인테리어 분야에서 전문 지식이 다양한
            인력으로 구성된 전문 시공팀이 함께합니다.
          </Desc>
          <Row>
            <Column>
              <img
                src={installImg2}
                alt="installImg2"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={installImg3}
                alt="installImg3"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={installImg4}
                alt="installImg4"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={installImg5}
                alt="installImg5"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={installImg6}
                alt="installImg6"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={installImg7}
                alt="installImg7"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
        </Box>
      </Container>
    </>
  );
};

export default Installation;
