import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 100px 0;
  @media (max-width: 768px) {
    margin: 40px 0 0 0;
  }
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0;
  @media (max-width: 768px) {
    padding: 15px 0;
  }
  > div {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px) {
      width: 90%;
      > img {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    &:last-child {
      width: 40%;
      @media (max-width: 768px) {
        width: 90%;
        > img {
          width: 100%;
        }
      }
    }
    > p {
      text-align: center;
      padding: 5px 10px;
      font-size: 18px;
      line-height: 30px;
      color: #1e1e1e;
      word-break: keep-all;
      @media (max-width: 768px) {
        padding: 0 20px;
        font-size: 14px;
      }
    }
  }
`;

const DividingImg = ({ info }) => {
  return (
    <Container>
      <div>
        <img src={info[0].img} alt={info[0].name} />
      </div>
      <div>
        <img src={info[1].img} alt={info[0].name} />
        <p>
          다양한 분할패턴을 적용할 수 있는 체결방식을 채택함으로서 더욱 견고하고
          안전한 도어를 제작할 수 있습니다.
        </p>
      </div>
    </Container>
  );
};

export default DividingImg;
