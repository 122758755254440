import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyles = createGlobalStyle`
${reset};
    a{
        text-decoration:none;
        color:inherit;
    }
    *{
        box-sizing:border-box;
    }
    body{
        font-family: 'NanumSquare', sans-serif !important;
        font-size:12px;
        @media (max-width: 480px) {
            width:100%;
        }
    }
`;

export default GlobalStyles;
