import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';

import SpecSection from './productComponents/SpecSection';
import HeaderContents from './productComponents/HeaderContents';
import DividingPattern from './productComponents/DividingPattern';
import DividingDesc from './productComponents/DividingDesc';
import DividingImg from './productComponents/DividingImg';
import BottomRail from './productComponents/BottomRail';
import ProductQuility from './productComponents/ProductQuility';
import DesignDesc from './productComponents/DesignDesc';
import ProcessSection from './productComponents/ProcessSection';
import Neccessary from './productComponents/Neccessary';
import oneProductMainImg from '../../assets/img/product/sliding/oneProductMainImg.png';

import { slideData, slideInfo } from './productData';
import ProductHeader from './ProductHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div`
  width: 100%;
`;

const Row = styled.div`
  > img {
    width: 100%;
  }
`;

const Sliding = () => {
  return (
    <>
      <ProductHeader />
      <Container>
        <Subject info={slideInfo} />
        <Box>
          <Row>
            <img src={oneProductMainImg} alt="oneProductMainImg" />
          </Row>
          <HeaderContents info={slideInfo} />
          <DividingPattern info={slideInfo} />
          <DividingDesc
            title={slideInfo.dividingTitle}
            data={slideInfo.dividingDesc}
          />
          <DividingImg info={slideInfo.dividingBarImgs} />
          <BottomRail info={slideInfo} />
          <DesignDesc
            title={slideInfo.designTitle}
            data={slideInfo.designDesc}
          />
          {slideData.map((data, index) => (
            <SpecSection data={data} key={index} />
          ))}
          <ProcessSection />
          <Neccessary />
          <ProductQuility imgs={slideInfo.bottomImgs} />
        </Box>
      </Container>
    </>
  );
};

export default Sliding;
