import React, { useState, useContext, useEffect } from 'react';
import EtcHeader from './EtcHeader';
import styled from 'styled-components';
import Subject from '../../Components/Subject';
import Axios from 'axios';
import validator from 'validator';
import ErrorNotice from './ErrorNotice';
import UserContext from '../../Context/UserContext';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;
const Box = styled.div`
  text-align: left;
  margin-bottom: 50px;
  > div {
    padding: 5px 0;
    display: flex;
    align-items: center;
  }
`;

const Title = styled.h1`
    font-size: 18px;
    color: #000;
    font-weight: 500;
    padding:10px 0;
}
`;

const Desc = styled.textarea`
  width: 100%;
  height: 129px;
  font-size: 12px;
  color: #333333;
  border: 1px solid #d4d4d4;
  text-align: left;
`;

const Check = styled.input``;

const Btn = styled.input`
  background: #333;
  padding: 10px 50px;
  color: #fff;
  font-size: 15px;
  border: 0;
  font-weight: 400;
  font-family: 'Noto Sans KR', sans-serif;
  cursor: pointer;
  margin-bottom: 50px;
  text-align: center;
`;

const CBtn = styled(Btn)`
  background: #999;
  margin-left: 10px;
`;

const List = styled.ul``;

const Item = styled.li`
  padding: 5px 0;
`;

const SSubject = styled.span`
  width: 15%;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  color: #000;
  @media (max-width: 768px) {
    width: 25%;
    font-size: 12px;
  }
`;

const Input = styled.input`
  width: 40%;
  border: 1px solid #ccc;
  padding: 10px;
  font-weight: 300;
  color: #5d5d5d;
  font-family: 'Noto Sans KR', sans-serif;
  @media (max-width: 768px) {
    width: 75%;
    font-size: 12px;
  }
`;

const Hr = styled.hr`
  margin: 20px 0 20px 0;
  @media (max-width: 768px) {
    margin: 10px 0 10px 0;
  }
`;

const EditUser = () => {
  const info = { title: '회원정보 수정', path: '/user/edit' };
  const { userData, setUserData } = useContext(UserContext);
  const [error, setError] = useState();
  const { userinfo } = userData;
  const handleChange = (e) => {
    console.log('hi');
  };

  const handleSubmit = async (e) => {
    console.log('hi2');
    // e.preventDefault();
    // try {
    //   if (!user.email) return alert('이메일은 반드시 기입하셔야 합니다.');
    //   if (!validator.isEmail(user.email))
    //     return alert('이메일 형식이 잘못되었습니다.');
    //   if (!user.password) return alert('비밀번호가 입력되지 않았습니다.');
    //   if (!user.password.length > 8)
    //     return alert('비밀번호의 길이는 최소 8글자 이상이어야 합니다.');
    //   if (!user.passwordCheck)
    //     return alert('비밀번호 체크 항목에 동일한 비밀번호를 적어주십시오.');
    //   if (user.password !== user.passwordCheck)
    //     return alert('입력하신 비밀번호가 동일하지 않습니다.');
    //   const newUser = {
    //     email: user.email,
    //     password: user.password,
    //     passwordCheck: user.passwordCheck,
    //     companyName: user.companyName,
    //     companyCeo: user.companyCeo,
    //     companyNumber: user.companyNumber,
    //     companyAddress1: user.companyAddress1,
    //     companyAddress2: user.companyAddress2,
    //     companyDeliveryPoint: user.companyDeliveryPoint,
    //     companyContact: user.companyContact,
    //     companyFax: user.companyFax,
    //   };
    //   await Axios.post('/users/register', newUser);
    //   const loginRes = await Axios.post('/users/login', {
    //     email: user.email,
    //     password: user.password,
    //   });
    //   setUserData({
    //     token: loginRes.data.token,
    //     user: loginRes.data.user,
    //   });
    //   localStorage.setItem('auth-token', loginRes.data.token);
    //   window.location.href = '/';
    // } catch (err) {
    //   err.response.data.msg && setError(err.response.data.msg);
    // }
  };

  return (
    <>
      <EtcHeader />
      <Container>
        <Subject info={info} />
        <form>
          <Box>
            <List>
              <Item>
                <SSubject>아이디(이메일)*</SSubject>
                <Input
                  type="email"
                  name="email"
                  disabled
                  value={userinfo ? userinfo.email : 'Loading...'}
                />
              </Item>
              <Item>
                <SSubject>비밀번호*</SSubject>
                <Input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  required
                  placeholder="비밀번호를 입력하세요"
                />
              </Item>
              <Hr />
              <Item>
                <SSubject>업체명*</SSubject>
                <Input
                  type="text"
                  name="companyName"
                  disabled
                  value={userinfo ? userinfo.companyName : 'Loading...'}
                />
              </Item>
              <Item>
                <SSubject>대표자명*</SSubject>
                <Input
                  type="text"
                  name="companyCeo"
                  disabled
                  value={userinfo ? userinfo.companyCeo : 'Loading...'}
                />
              </Item>
              <Item>
                <SSubject>사업자번호*</SSubject>
                <Input
                  type="text"
                  name="companyNumber"
                  disabled
                  value={userinfo ? userinfo.companyNumber : 'Loading...'}
                />
              </Item>
              <Item>
                <SSubject>주소*</SSubject>
                <Input
                  type="text"
                  name="companyAddress1"
                  onChange={handleChange}
                  value={userinfo ? userinfo.companyAddress1 : 'Loading...'}
                />
              </Item>
              <Item>
                <SSubject></SSubject>
                <Input
                  type="text"
                  name="companyAddress2"
                  onChange={handleChange}
                  value={userinfo ? userinfo.companyAddress2 : 'Loading...'}
                />
              </Item>
              <Item>
                <SSubject>화물지점(대신)</SSubject>
                <Input
                  type="text"
                  name="companyDeliveryPoint"
                  onChange={handleChange}
                  value={
                    userinfo ? userinfo.companyDeliveryPoint : 'Loading...'
                  }
                />
              </Item>
              <Item>
                <SSubject>연락처*</SSubject>
                <Input
                  type="text"
                  name="companyContact"
                  onChange={handleChange}
                  value={userinfo ? userinfo.companyContact : 'Loading...'}
                />
              </Item>
              <Item>
                <SSubject>팩스</SSubject>
                <Input
                  type="text"
                  name="companyFax"
                  onChange={handleChange}
                  value={userinfo ? userinfo.companyFax : 'Loading...'}
                />
              </Item>
            </List>
          </Box>
          {error && (
            <ErrorNotice
              message={error}
              clearError={() => setError(undefined)}
            />
          )}
          <Btn type="submit" value="확인" onClick={handleSubmit} />
          <CBtn type="button" value="취소" />
        </form>
      </Container>
    </>
  );
};

export default EditUser;
