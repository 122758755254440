import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';
import companyIntroImg_1 from '../../assets/img/company/companyIntroImg_1.png';
import companyIntroImg_2 from '../../assets/img/company/companyIntroImg_2.png';
import organization from '../../assets/img/company/organization.png';
import CompanyHeader from './CompanyHeader';
import { introData } from './companyData';

const Container = styled.div`
  margin: 0 auto;
  margin-bottom: 120px;
  text-align: center;
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
  > h2 {
    color: #000;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    padding-bottom: 15px;
    @media (max-width: 1080px) {
      font-size: 20px;
      line-height: 30px;
    }
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  > img {
    width: 60%;
    margin: 0 0 100px 0;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0 0 60px 0;
    }
  }
`;

const Box = styled.div`
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
  @media (max-width: 1080px) {
    width: 100%;
  }
  > div {
    padding-bottom: 40px;
    line-height: 0;
    > img {
      width: 100%;
    }
  }
  > div:last-child {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    > p {
      font-size: 30px;
      line-height: 40px;
      color: #1e1e1e;
      font-weight: 500;
      @media (max-width: 1080px) {
        font-size: 24px;
        line-height: 32px;
      }
      @media (max-width: 480px) {
        font-size: 18px;
        line-height: 26px;
      }
    }
    > p:last-child {
      font-size: 17px;
      color: #666;
      font-weight: 400;
      line-height: 30px;
      margin-top: 30px;
      word-break: keep-all;

      @media (max-width: 1080px) {
        font-size: 15px;
        line-height: 28px;
      }
      @media (max-width: 768px) {
        font-size: 13px;
        line-height: 26px;
      }
      @media (max-width: 480px) {
        font-size: 12px;
        line-height: 24px;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const CenterBox = styled.div`
    width:100%;
    background-image: url(${(props) => props.back});
    background-position:center top;
    background-repeat:no-repeat
    text-align: center;
    padding: 80px 40px;
    background-size: cover;
    margin-bottom:120px;
    @media (max-width: 768px){
      margin-bottom: 80px;
      padding: 60px 40px;
    }
    >ul{
      width:1200px;
      margin: 0 auto;
      display:flex;
      flex-wrap:wrap;
      justify-content:space-evenly;
      @media (max-width: 1280px){
      width: 100%;
    }
      >li{
        color: #333;
        width: 19%;
        margin-right: 0.5%;
        padding: 40px 0;
        background: rgba(255,255,255,0.85);
        @media (max-width: 768px){
          color: #333;
          width: 48%;
          margin-bottom: 0.5%;
          padding: 30px 0;
        }
        @media (max-width: 480px){
          width: 100%;
          margin-bottom: 5px;
        }
        >div:first-child{
          text-align:center;
          >i{
            font-size:48px;
            padding-bottom: 20px;
          }
        }
        >div:last-child{
          text-align:center;
          >h5{
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
            font-family: 'Montserrat','Noto Sans KR', sans-serif;
            color: #000;
            padding-bottom: 10px;
            @media (max-width: 1080px){
              font-size: 17px;
            line-height: 24px;
            }
            @media (max-width: 768px){
              font-size: 16px;
            line-height: 24px;
            }
          }
          >p{
            font-size: 16px;
    line-height: 24px;
    word-break: keep-all;
    color: #666;
    @media (max-width: 1080px){
      font-size: 14px;
    line-height: 22px;
    }
}
          }
        }
      }
    `;

const Title = styled.h1`
  color: #fff;
  padding-bottom: 10px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const Desc = styled.p`
  word-break: keep-all;
  color: #fff;
  padding-bottom: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 1080px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const Wrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
  }
  > h2 {
    color: #000;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    padding-bottom: 15px;
    @media (max-width: 1080px) {
      font-size: 20px;
      line-height: 30px;
    }
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
`;

const Table = styled.table`
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  border-top: 2px solid #1e459c !important;
  margin-bottom: 50px;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;
  line-height: 30px;
  @media (max-width: 1280px) {
    width: 100%;
    line-height: 28px;
  }
  @media (max-width: 1080px) {
    font-size: 14px;
    line-height: 26px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 24px;
  }
  tr {
    color: #5d5d5d;
    background: #fff;
    @media (max-width: 480px) {
      font-size: 10px;
    }
    &:first-child {
      color: #000;
      background: #f5f5f5;
    }
    > td {
      padding: 10px 2px;
      border: 1px solid #ddd;
    }
  }
`;

const Table2 = styled(Table)`
  > tbody {
    > tr {
      @media (max-width: 480px) {
        font-size: 13px;
      }
      &:first-child {
        > td:last-child {
          color: #5d5d5d;
          background: #fff;
        }
      }
      > td:first-child {
        color: #000;
        background: #f5f5f5;
        &:last-child {
          color: #5d5d5d;
          background: #fff;
        }
      }
    }
  }
`;

const Intro = () => {
  const info = { title: '회사소개', path: '/company/intro' };
  return (
    <>
      <CompanyHeader />
      <Container>
        <Subject info={info} />
        <Box>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="top-center">
            <img src={companyIntroImg_1} alt="companyIntroImg_1" />
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="top-center">
            <p>기술력과 전문성을 바탕으로 고객과 상생하는 기업이 되겠습니다.</p>
            <p>
              미소테크는 인테리어 디자인 도어의 미래를 개척하는 기업입니다.
              <br /> 기술 분야와 마케팅 분야의 전문가인 두 대표의 만남으로
              시작된 미소테크는 업계의 시너지 효과를 내고 있습니다.
              <br /> 또한 빠르게 변화하는 인테리어 시장보다 한 발 앞서 움직이기
              위하여
              <br /> 전문 기술인들이 끊임없이 연구하고 개발하고 있습니다.
              <br /> 또한 단순히 자재만을 개발하는 것이 아니라, 더 나은 생활
              환경과 완벽한 공간 구성을 제시하여
              <br /> 고객이 인테리어를 즐길 수 있는 공간을 마련하고 있습니다.
            </p>
          </div>
        </Box>
        <CenterBox back={companyIntroImg_2}>
          <Title
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="top-center">
            주요현황
          </Title>
          <Desc
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="top-center">
            미소테크의 주요현황을 알려드립니다.
          </Desc>
          <ul>
            {introData.map((data, index) => (
              <li
                key={index}
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center">
                <div>
                  <i className={data.icon}></i>
                </div>
                <div>
                  <h5>{data.title}</h5>
                  <p>{data.content}</p>
                </div>
              </li>
            ))}
          </ul>
        </CenterBox>
        <h2
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-anchor-placement="top-center">
          조직도
        </h2>
        <img
          src={organization}
          alt="organization"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-anchor-placement="top-center"
        />
        <Wrap>
          <h2
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="top-center">
            주요현황 테이블
          </h2>
          <Table
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="top-center">
            <tbody>
              <tr>
                {introData.map((data, index) => (
                  <td key={index}>{data.title}</td>
                ))}
              </tr>
              <tr>
                {introData.map((data, index) => (
                  <td key={index}>{data.content}</td>
                ))}
              </tr>
            </tbody>
          </Table>
          <Table2
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="top-center">
            <tbody>
              {introData.map((data, index) => (
                <tr key={index}>
                  <td>{data.title}</td>
                  <td>{data.content}</td>
                </tr>
              ))}
            </tbody>
          </Table2>
        </Wrap>
      </Container>
    </>
  );
};

export default Intro;
