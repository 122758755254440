import handleMainImg from '../../assets/img/product/sliding/handleMainImg.png';
import assemblyHandle from '../../assets/img/product/sliding/assemblyHandle.png';
import attachHandle from '../../assets/img/product/sliding/attachHandle.png';
import screwHandle from '../../assets/img/product/sliding/screwHandle.png';
import frameMainImg from '../../assets/img/product/sliding/frameMainImg.png';
import blackFrame from '../../assets/img/product/sliding/blackFrame.png';
import whiteFrame from '../../assets/img/product/sliding/whiteFrame.png';
import boldGoldFrame from '../../assets/img/product/sliding/boldGoldFrame.png';
import CGoldFrame from '../../assets/img/product/sliding/CGoldFrame.png';
import handleBarMainImg from '../../assets/img/product/sliding/handleBarMainImg.png';
import handle25 from '../../assets/img/product/sliding/25handle.png';
import handle36 from '../../assets/img/product/sliding/36handle.png';
import handle36_2 from '../../assets/img/product/sliding/36handle-2.png';
import handle44 from '../../assets/img/product/sliding/44handle.png';
import glassMainImg from '../../assets/img/product/sliding/glassMainImg.png';
import glassWire from '../../assets/img/product/sliding/glassWire.png';
import glassBronze from '../../assets/img/product/sliding/glassBronze.png';
import glassAqua from '../../assets/img/product/sliding/glassAqua.png';
import endMainImg from '../../assets/img/product/sliding/endMainImg.png';
import doorEndCap from '../../assets/img/product/sliding/doorEndCap.png';
import glassEndCap from '../../assets/img/product/sliding/glassEndCap.png';
import railEndCap from '../../assets/img/product/sliding/railEndCap.png';
import endFrame from '../../assets/img/product/sliding/endFrame.png';
import hardwareMainImg from '../../assets/img/product/sliding/hardwareMainImg.png';
import damperImg from '../../assets/img/product/sliding/damperImg.png';
import stopperImg from '../../assets/img/product/sliding/stopperImg.png';
import rollerImg from '../../assets/img/product/sliding/rollerImg.png';
import slidingSub1 from '../../assets/img/product/sliding/slidingSub1.png';
import slidingSub2 from '../../assets/img/product/sliding/slidingSub2.png';
import slidingSub3 from '../../assets/img/product/sliding/slidingSub3.png';
import onePattern from '../../assets/img/product/sliding/onePattern.png';
import productDividing from '../../assets/img/product/sliding/productDividing.png';
import productDividing1 from '../../assets/img/product/sliding/productDividing1.png';
import exposeBottomRail from '../../assets/img/product/sliding/exposeBottomRail.png';
import burriedBottomRail from '../../assets/img/product/sliding/burriedBottomRail.png';
import slidingBottomImg1 from '../../assets/img/product/sliding/slidingBottomImg1.png';
import slidingBottomImg2 from '../../assets/img/product/sliding/slidingBottomImg2.png';
import slidingBottomImg3 from '../../assets/img/product/sliding/slidingBottomImg3.png';
//link
import linkEndMainImg from '../../assets/img/product/link/linkEndMainImg.png';
import doorGasket from '../../assets/img/product/link/doorGasket.png';
import verticalEndCap from '../../assets/img/product/link/verticalEndCap.png';
import hardware from '../../assets/img/product/link/hardware3.png';
import pivot from '../../assets/img/product/link/pivot.png';
import bottomRoller from '../../assets/img/product/link/bottomRoller.png';
import linkDividing1 from '../../assets/img/product/link/linkDividing1.png';
import linkDividing2 from '../../assets/img/product/link/linkDividing2.png';
import linkDividing3 from '../../assets/img/product/link/linkDividing3.png';
import linkDividing4 from '../../assets/img/product/link/linkDividing4.png';
import linkPaternImg from '../../assets/img/product/link/linkPaternImg.png';
import linkDividingSubImg from '../../assets/img/product/link/linkDividingSubImg.png';
import linkBottomRailImg from '../../assets/img/product/link/linkBottomRailImg.png';
import linkBottomImg1 from '../../assets/img/product/link/linkBottomImg1.png';
import linkBottomImg2 from '../../assets/img/product/link/linkBottomImg2.png';
import linkBottomImg3 from '../../assets/img/product/link/linkBottomImg3.png';
import linkhardwareMainImg from '../../assets/img/product/link/linkhardwareMainImg.png';
import linkEndFrame from '../../assets/img/product/link/linkEndFrame.png';
//hinge
import handle1 from '../../assets/img/product/hinge/handle1.png';
import handle2 from '../../assets/img/product/hinge/handle2.png';
import handle3 from '../../assets/img/product/hinge/handle3.png';
import hardware1 from '../../assets/img/product/hinge/hardware1.png';
import hardware2 from '../../assets/img/product/hinge/hardware2.png';
import hardware3 from '../../assets/img/product/hinge/hardware3.png';
import hardware4 from '../../assets/img/product/hinge/hardware4.png';
import hingeHandleMainImg from '../../assets/img/product/hinge/hingeHandleMainImg.png';
import hingeFrameGasket from '../../assets/img/product/hinge/hingeFrameGasket.png';
import hingeFrameCap from '../../assets/img/product/hinge/hingeFrameCap.png';
import hingeHardwareMainImg from '../../assets/img/product/hinge/hingeHardwareMainImg.png';
import subImg1 from '../../assets/img/product/hinge/subImg1.png';
import subImg2 from '../../assets/img/product/hinge/subImg2.png';
import subImg3 from '../../assets/img/product/hinge/subImg3.png';
import pattern from '../../assets/img/product/hinge/pattern.png';
import hingeDividing from '../../assets/img/product/hinge/hingeDividing.png';
import hingeFrameAssembly from '../../assets/img/product/hinge/hingeFrameAssembly.png';
import hingeBottomImg1 from '../../assets/img/product/hinge/hingeBottomImg1.png';
import hingeBottomImg2 from '../../assets/img/product/hinge/hingeBottomImg2.png';
import hingeBottomImg3 from '../../assets/img/product/hinge/hingeBottomImg3.png';
//swing
import swingHandle_black from '../../assets/img/product/swing/swingHandle_black.png';
import swingHandle_white from '../../assets/img/product/swing/swingHandle_white.png';
import swingHandle_gold from '../../assets/img/product/swing/swingHandle_gold.png';
import swingHardwareMain from '../../assets/img/product/swing/swingHardwareMain.png';
import swingHardware1 from '../../assets/img/product/swing/swingHardware1.png';
import swingHardware2 from '../../assets/img/product/swing/swingHardware2.png';
import swingHardware3 from '../../assets/img/product/swing/swingHardware3.png';
import swingBottomImg1 from '../../assets/img/product/swing/swingBottomImg1.png';
import swingBottomImg2 from '../../assets/img/product/swing/swingBottomImg2.png';
import swingBottomImg3 from '../../assets/img/product/swing/swingBottomImg3.png';
import swingSideFrame from '../../assets/img/product/swing/swingSideFrame.png';
import swingSideFrame2 from '../../assets/img/product/swing/swingSideFrame2.png';
import swingFrame_gasket from '../../assets/img/product/swing/swingFrame_gasket.png';
import swingBottomFrame from '../../assets/img/product/swing/swingBottomFrame.png';
import swingDividing_pattern from '../../assets/img/product/swing/swingDividing_pattern.png';
import swingHandle_main from '../../assets/img/product/swing/swingHandle_main.png';
import swingSubImg1 from '../../assets/img/product/swing/swingSubImg1.png';
import swingSubImg2 from '../../assets/img/product/swing/swingSubImg2.png';
import swingSubImg3 from '../../assets/img/product/swing/swingSubImg3.png';
import swingDividingPatterns from '../../assets/img/product/swing/swingDividingPatterns.png';
//partition
import closeMainImg from '../../assets/img/product/partition/closeMainImg.png';
import closeHbar from '../../assets/img/product/partition/closeHbar.png';
import closeSideFrameBar from '../../assets/img/product/partition/closeSideFrameBar.png';
import partitionnBottomImg1 from '../../assets/img/product/partition/partitionBottomImg1.png';
import partitionnBottomImg2 from '../../assets/img/product/partition/partitionBottomImg2.png';
import partitionnBottomImg3 from '../../assets/img/product/partition/partitionBottomImg3.png';
import fixRollerHardware from '../../assets/img/product/partition/fixRollerHardware.png';
import newFixRollerHardware from '../../assets/img/product/partition/newFixRollerHardware.png';
import fixBottomBarHardware from '../../assets/img/product/partition/fixBottomBarHardware.png';
import partitionHardwareMainImg from '../../assets/img/product/partition/partitionHardwareMainImg.png';
import partitionDividing from '../../assets/img/product/partition/partitionDividing.png';
import fixBottomRail from '../../assets/img/product/partition/fixBottomRail.png';
import fixRoller from '../../assets/img/product/partition/fixRoller.png';
import partitionSubImg1 from '../../assets/img/product/partition/partitionSubImg1.png';
import partitionSubImg2 from '../../assets/img/product/partition/partitionSubImg2.png';
import partitionSubImg3 from '../../assets/img/product/partition/partitionSubImg3.png';
import partitionPatterns from '../../assets/img/product/partition/partitionPatterns.png';

export const slideData = [
  {
    id: 1,
    title: 'handle',
    ko_title: '최고급 슬림 손잡이',
    desc:
      '미소테크의 슬림 손잡이는 디자인적으로 우수하며 취향에 따라 다양한 디자인을 선택하실 수 있습니다. 모던한 손잡이에서 고급스러움을 느낄 수 있습니다.',
    mainImg: handleMainImg,
    imgs: [
      { name: '매립식 손잡이', img: assemblyHandle },
      { name: '접착식 손잡이', img: attachHandle },
      { name: '체결식 손잡이', img: screwHandle },
    ],
  },
  {
    id: 2,
    title: 'frameMainImg',
    ko_title: '세련된 4종의 프레임 색상',
    desc:
      '다양한 색상을 보유함으로 트렌디한 인테리어 디자인에 최적으로 매칭시킬 수 있습니다. 특히 아노다이징 도장은 고급스럽고도 은은한 광택으로 인기가 많습니다. 불소 도장 또한 친환경 원료를 사용하고 3코팅 기법으로 특유의 고급스러움을 자랑합니다.',
    mainImg: frameMainImg,
    imgs: [
      { name: '다크실버', img: blackFrame },
      { name: '화이트', img: whiteFrame },
      { name: '진골드', img: boldGoldFrame },
      { name: '샴페인골드', img: CGoldFrame },
    ],
  },
  {
    id: 3,
    title: 'handleBar',
    ko_title: '손잡이바',
    desc:
      '손잡이와 일체된 핸들바를 사용함으로써 모던한 인테리어를 완성할 수 있습니다. 4종의 디자인을 지닌 핸들바는 인체공학적으로 설계되어 도어를 개폐할 때, 최적화된 힘을 구현하도록 도움을 줍니다.',
    mainImg: handleBarMainImg,
    imgs: [
      { name: '25 핸들바', img: handle25 },
      { name: '36 핸들바', img: handle36 },
      { name: '36 핸들바-2', img: handle36_2 },
      { name: '44 핸들바', img: handle44 },
    ],
  },
  {
    id: 4,
    title: 'glass',
    ko_title: '안전한 유리',
    desc:
      '슬림도어에 최적화 되어있는 안전하고 견고한 강화유리를 사용합니다. 또한 다양한 유리를 분할 사용하고 고유한 질감을 디자인에 적용하여 제작할 수 있습니다.',
    mainImg: glassMainImg,
    imgs: [
      { name: '망입 유리', img: glassWire },
      { name: '브론즈 유리', img: glassBronze },
      { name: '아쿠아 유리', img: glassAqua },
    ],
  },
  {
    id: 5,
    title: 'endMaterial',
    ko_title: '마감자재',
    desc:
      '미소테크의 슬림도어는 각종 마감재와 색상별로 구비되어 있는 가스켓들로 어떠한 설치 환경에서도 완벽하게 인테리어와 조화를 이룰수 있습니다.',
    mainImg: endMainImg,
    imgs: [
      { name: '유리 가스켓', img: glassEndCap },
      { name: '도어 가스켓', img: doorEndCap },
      { name: '레일 마감캡', img: railEndCap },
      { name: '각종 마감재', img: endFrame },
    ],
  },
  {
    id: 6,
    title: 'hardware',
    ko_title: '하드웨어',
    desc:
      '하드웨어는 연구개발부서 운영을 통한 개발품이며, 슬라이딩 도어에 최적화 되어있습니다. 고객의 요구에 맞게 언제든 신제품 개발과 더불어 수정 보완을 해 나가고 있습니다.',
    mainImg: hardwareMainImg,
    imgs: [
      { name: '댐퍼', img: damperImg },
      { name: '댐퍼 스톱퍼', img: stopperImg },
      { name: '하부롤러', img: rollerImg },
    ],
  },
];

export const slideInfo = {
  title: '슬라이딩 도어',
  path: '/product/sliding',
  en_title: 'sliding door',
  desc: [
    '새로운 공간을 창조하는 인테리어로 인기를 끌고 있는 미소테크 슬림도어',
    '미소테크 슬림도어는 새로운 공간을 디자인합니다.',
  ],
  dividingImgs: [
    { title: ['분할패턴 -', 'MS1'], img: slidingSub3, name: 'slidingSub3' },
    { title: ['분할패턴 -', 'MS2'], img: slidingSub2, name: 'slidingSub2' },
    { title: ['분할패턴 -', 'MS3'], img: slidingSub1, name: 'slidingSub1' },
  ],
  dividingPatternImg: onePattern,
  dividingPatternDesc: [
    '원슬라이딩도어는 부드러운 구동성과 댐퍼 적용으로 보다',
    '안전한 기술력을 자랑하는 미소테크의 주력상품 입니다.',
  ],
  dividingTitle: 'Dividing & Frame',
  dividingDesc: [
    '미소테크는 인테리어 도어의 완성입니다.',
    '미소테크 중문은 인테리어 도어의 시작입니다.',
    '슬림한 프레임에 다양한 분할 패턴을 적용하여 세련된 디자인을 연출할 수 있습니다.',
  ],
  dividingBarImgs: [
    { name: 'productDividing', img: productDividing },
    { name: 'productDividing1', img: productDividing1 },
  ],
  designTitle: 'Design & Hardware',
  designDesc: [
    '실용적인 디자인과 자체 개발한 하드웨어는 품격있는 인테리어를 완성합니다.',
    '미소테크의 기술력은 소비자의 요구와 함께합니다.',
  ],
  bottomRail: [
    {
      img: burriedBottomRail,
      name: 'burriedBottomRail',
      title: '마루를 가공하여 삽입하는 형식',
      desc: [
        '바닥과 일체되어 걸림없이 이용할 수 있는',
        '장점이 있으나, A/S가 어렵고, 시공 시간이 오래걸림.',
      ],
    },
    {
      img: exposeBottomRail,
      name: 'exposeBottomRail',
      title: '바닥에 부착하는 형식',
      desc: [
        '라운드 모양의 디자인으로 동선에 방해되지 않고,',
        '디자인이 우수하며 시공시간이 짧고 용이함.',
      ],
    },
  ],
  bottomImgs: [
    {
      title: '드레스룸',
      name: slidingBottomImg1,
      en_title: 'slidingBottomImg1',
    },
    {
      title: '알파룸',
      name: slidingBottomImg2,
      en_title: 'slidingBottomImg2',
    },
    { title: '현관', name: slidingBottomImg3, en_title: 'slidingBottomImg3' },
  ],
};

export const linkData = [
  {
    id: 1,
    title: 'handle',
    ko_title: '최고급 슬림 손잡이',
    desc:
      '미소테크의 슬림 손잡이는 디자인적으로 우수하며 취향에 따라 다양한 디자인을 선택하실 수 있습니다. 모던한 손잡이에서 고급스러움을 느낄 수 있습니다.',
    mainImg: handleMainImg,
    imgs: [
      { name: '매립식 손잡이', img: assemblyHandle },
      { name: '접착식 손잡이', img: attachHandle },
      { name: '체결식 손잡이', img: screwHandle },
    ],
  },
  {
    id: 2,
    title: 'frameMainImg',
    ko_title: '세련된 4종의 프레임 색상',
    desc:
      '다양한 색상을 보유함으로 트렌디한 인테리어 디자인에 최적으로 매칭시킬 수 있습니다. 특히 아노다이징 도장은 고급스럽고도 은은한 광택으로 인기가 많습니다. 불소 도장 또한 친환경 원료를 사용하고 3코팅 기법으로 특유의 고급스러움을 자랑합니다.',
    mainImg: frameMainImg,
    imgs: [
      { name: '다크실버', img: blackFrame },
      { name: '화이트', img: whiteFrame },
      { name: '진골드', img: boldGoldFrame },
      { name: '샴페인골드', img: CGoldFrame },
    ],
  },
  {
    id: 3,
    title: 'handleBar',
    ko_title: '손잡이바',
    desc:
      '손잡이와 일체된 핸들바를 사용함으로써 모던한 인테리어를 완성할 수 있습니다. 4종의 디자인을 지닌 핸들바는 인체공학적으로 설계되어 도어를 개폐할 때, 최적화된 힘을 구현하도록 도움을 줍니다.',
    mainImg: handleBarMainImg,
    imgs: [
      { name: '25 핸들바', img: handle25 },
      { name: '36 핸들바', img: handle36 },
      { name: '36 핸들바-2', img: handle36_2 },
      { name: '44 핸들바', img: handle44 },
    ],
  },
  {
    id: 4,
    title: 'glass',
    ko_title: '안전한 유리',
    desc:
      '슬림도어에 최적화 되어있는 안전하고 견고한 강화유리를 사용합니다. 또한 다양한 유리를 분할 사용하고 고유한 질감을 디자인에 적용하여 제작할 수 있습니다.',
    mainImg: glassMainImg,
    imgs: [
      { name: '망입 유리', img: glassWire },
      { name: '브론즈 유리', img: glassBronze },
      { name: '아쿠아 유리', img: glassAqua },
    ],
  },
  {
    id: 5,
    title: 'endMaterial',
    ko_title: '마감자재',
    desc:
      '미소테크의 슬림도어는 각종 마감재와 색상별로 구비되어 있는 가스켓들로 어떠한 설치 환경에서도 완벽하게 인테리어와 조화를 이룰수 있습니다.',
    mainImg: linkEndMainImg,
    imgs: [
      { name: '유리 가스켓', img: glassEndCap },
      { name: '도어 가스켓', img: doorGasket },
      { name: '세로바 마감캡', img: verticalEndCap },
      { name: '각종 마감재', img: linkEndFrame },
    ],
  },
  {
    id: 6,
    title: 'hardware',
    ko_title: '하드웨어',
    desc:
      '하드웨어는 연구개발부서 운영을 통한 개발품이며, 슬라이딩 도어에 최적화 되어있습니다. 고객의 요구에 맞게 언제든 신제품 개발과 더불어 수정 보완을 해 나가고 있습니다.',
    mainImg: linkhardwareMainImg,
    imgs: [
      { name: '3연동 하드웨어', img: hardware },
      { name: '피봇', img: pivot },
      { name: '하부롤러', img: bottomRoller },
    ],
  },
];

export const linkInfo = {
  title: '연동 도어',
  path: '/product/link',
  en_title: 'link door',
  desc: [
    '새로운 공간을 창조하는 인테리어로 인기를 끌고 있는 미소테크 슬림도어',
    '미소테크 슬림도어는 새로운 공간을 디자인합니다.',
  ],
  dividingImgs: [
    {
      title: ['분할패턴 -', 'MT1'],
      img: linkDividing1,
      name: 'linkDividing1',
    },
    {
      title: ['분할패턴 -', 'MT2'],
      img: linkDividing2,
      name: 'linkDividing2',
    },
    {
      title: ['분할패턴 -', 'MT3'],
      img: linkDividing3,
      name: 'linkDividing3',
    },
    {
      title: ['분할패턴 -', 'MT9'],
      img: linkDividing4,
      name: 'linkDividing4',
    },
  ],
  dividingPatternImg: linkPaternImg,
  dividingPatternDesc: [
    '연동 도어는 자체 개발한 하드웨어를 적용하여',
    '부드러운 구동성과 소음이 발생되지 않는 장점을 지닙니다.',
  ],
  dividingTitle: 'Dividing & Frame',
  dividingDesc: [
    '미소테크는 인테리어 도어의 완성입니다.',
    '미소테크 중문은 인테리어 도어의 시작입니다.',
    '슬림한 프레임에 다양한 분할 패턴을 적용하여 세련된 디자인을 연출할 수 있습니다.',
  ],
  dividingBarImgs: [
    { name: 'linkDividingSubImg', img: linkDividingSubImg },
    { name: 'productDividing1', img: productDividing1 },
  ],
  designTitle: 'Design & Hardware',
  designDesc: [
    '실용적인 디자인과 자체 개발한 하드웨어는 품격있는 인테리어를 완성합니다.',
    '미소테크의 기술력은 소비자의 요구와 함께합니다.',
  ],
  bottomRail: [
    {
      img: linkBottomRailImg,
      name: 'linkBottomRailImg',
      title: '다양한 종류의 하부레일',
      desc: [
        '여러 종류의 하부레일을 보유하고 있어 상황과',
        '인테리어 환경에 어울리는 도어를 디자인을 할 수 있습니다.',
      ],
    },
  ],
  bottomImgs: [
    { title: '드레스룸', name: linkBottomImg1, en_title: 'linkBottomImg1' },
    { title: '침실', name: linkBottomImg2, en_title: 'linkBottomImg2' },
    { title: '현관', name: linkBottomImg3, en_title: 'linkBottomImg3' },
  ],
};

export const hingeData = [
  {
    id: 1,
    title: 'handle',
    ko_title: '독일제 호페 손잡이',
    desc:
      '미소테크의 슬림 손잡이는 디자인적으로 우수하며 호페 핸들을 사용함으로써 더욱 고급스럽고 모던한 느낌을 손잡이에서 찾을 수 있습니다.',
    mainImg: hingeHandleMainImg,
    imgs: [
      { name: '호페 손잡이(화이트)', img: handle1 },
      { name: '호페 손잡이(블랙)', img: handle2 },
      { name: '일반 손잡이', img: handle3 },
    ],
  },
  {
    id: 2,
    title: 'frameMainImg',
    ko_title: '세련된 2종의 프레임 색상',
    desc:
      '다양한 색상을 보유함으로 트렌디한 인테리어 디자인에 최적으로 매칭시킬 수 있습니다. 특히 아노다이징 도장은 고급스럽고도 은은한 광택으로 인기가 많습니다. 불소 도장 또한 친환경 원료를 사용하고 3코팅 기법으로 특유의 고급스러움을 자랑합니다.',
    mainImg: frameMainImg,
    imgs: [
      { name: '다크실버', img: blackFrame },
      { name: '화이트', img: whiteFrame },
    ],
  },
  {
    id: 3,
    title: 'glass',
    ko_title: '안전한 유리',
    desc:
      '슬림도어에 최적화 되어있는 안전하고 견고한 강화유리를 사용합니다. 또한 다양한 유리를 분할 사용하고 고유한 질감을 디자인에 적용하여 제작할 수 있습니다.',

    mainImg: glassMainImg,
    imgs: [
      { name: '망입 유리', img: glassWire },
      { name: '브론즈 유리', img: glassBronze },
      { name: '아쿠아 유리', img: glassAqua },
    ],
  },
  {
    id: 4,
    title: 'endMaterial',
    ko_title: '마감자재',
    desc:
      '미소테크의 슬림도어는 각종 마감재와 색상별로 구비되어 있는 가스켓들로 어떠한 설치 환경에서도 완벽하게 인테리어와 조화를 이룰수 있습니다.',
    mainImg: linkEndMainImg,
    imgs: [
      { name: '유리 가스켓', img: glassEndCap },
      { name: '도어 가스켓', img: doorEndCap },
      { name: '프레임 가스켓', img: hingeFrameGasket },
      { name: '프레임 마감재', img: hingeFrameCap },
    ],
  },
  {
    id: 5,
    title: 'hardware',
    ko_title: '하드웨어',
    desc:
      '하드웨어는 연구개발부서 운영을 통한 개발품이며, 여닫이 도어에 최적화 되어있습니다. 고객의 요구에 맞게 언제든 신제품 개발과 더불어 수정 보완을 해 나가고 있습니다.',
    mainImg: hingeHardwareMainImg,
    imgs: [
      { name: '여닫이 힌지', img: hardware1 },
      { name: '오도시', img: hardware2 },
      { name: '오도시 브라켓', img: hardware3 },
      { name: '도어 캐치', img: hardware4 },
    ],
  },
];

export const hingeInfo = {
  title: '여닫이 도어',
  path: '/product/hinge',
  en_title: 'hinge door',
  desc: [
    '새로운 공간을 창조하는 인테리어로 인기를 끌고 있는 미소테크 슬림도어',
    '미소테크 슬림도어는 새로운 공간을 디자인합니다.',
  ],
  dividingImgs: [
    {
      title: ['분할패턴 -', 'MDH3'],
      img: subImg1,
      name: 'subImg1',
    },
    {
      title: ['분할패턴 -', 'MH3'],
      img: subImg2,
      name: 'subImg2',
    },
    {
      title: ['분할패턴 -', 'MDH5'],
      img: subImg3,
      name: 'subImg3',
    },
  ],
  dividingPatternImg: pattern,
  dividingPatternDesc: [
    '여닫이 도어는 자체 개발한 하드웨어를 적용하여',
    '시간이 지나도 처지지 않고 부드러운 작동을 지속합니다.',
  ],
  dividingTitle: 'Dividing & Frame',
  dividingDesc: [
    '미소테크는 인테리어 도어의 완성입니다.',
    '미소테크 중문은 인테리어 도어의 시작입니다.',
    '슬림한 프레임에 다양한 분할 패턴을 적용하여 세련된 디자인을 연출할 수 있습니다.',
  ],
  dividingBarImgs: [
    { name: 'hingeDividing', img: hingeDividing },
    { name: 'productDividing1', img: productDividing1 },
  ],
  designTitle: 'Design & Hardware',
  designDesc: [
    '실용적인 디자인과 자체 개발한 하드웨어는 품격있는 인테리어를 완성합니다.',
    '미소테크의 기술력은 소비자의 요구와 함께합니다.',
  ],
  bottomRail: [
    {
      img: hingeFrameAssembly,
      name: 'hingeFrameAssembly',
      title: '쉽고 기밀한 문틀 체결 구조',
      desc: [
        '설치가 용이하고 깔끔한 마감으로 시간이 단축되고,',
        '처짐이 없는 구조로 A/S 없이 사용하실 수 있습니다.',
      ],
    },
  ],
  bottomImgs: [
    {
      title: '방문',
      name: hingeBottomImg1,
      en_title: 'hingeBottomImg1',
    },
    {
      title: '사무공간',
      name: hingeBottomImg2,
      en_title: 'hingeBottomImg2',
    },
    { title: '현관', name: hingeBottomImg3, en_title: 'hingeBottomImg3' },
  ],
};

export const swingData = [
  {
    id: 1,
    title: 'handle',
    ko_title: '고급 알루미늄 손잡이',
    desc:
      '미소테크의 슬림 손잡이는 디자인적으로 우수하며 취향에 따라 다양한 디자인을 선택하실 수 있습니다. 모던한 손잡이에서 고급스러움을 느낄 수 있습니다.',

    mainImg: swingHandle_main,
    imgs: [
      { name: '손잡이(블랙)', img: swingHandle_black },
      { name: '손잡이(화이트)', img: swingHandle_white },
      { name: '손잡이(골드)', img: swingHandle_gold },
    ],
  },
  {
    id: 2,
    title: 'frameMainImg',
    ko_title: '세련된 3종의 프레임 색상',
    desc:
      '다양한 색상을 보유함으로 트렌디한 인테리어 디자인에 최적으로 매칭시킬 수 있습니다. 특히 아노다이징 도장은 고급스럽고도 은은한 광택으로 인기가 많습니다. 불소 도장 또한 친환경 원료를 사용하고 3코팅 기법으로 특유의 고급스러움을 자랑합니다.',

    mainImg: frameMainImg,
    imgs: [
      { name: '블랙', img: blackFrame },
      { name: '화이트', img: whiteFrame },
      { name: '골드', img: boldGoldFrame },
    ],
  },
  {
    id: 3,
    title: 'glass',
    ko_title: '안전한 유리',
    desc:
      '슬림도어에 최적화 되어있는 안전하고 견고한 강화유리를 사용합니다. 또한 다양한 유리를 분할 사용하고 고유한 질감을 디자인에 적용하여 제작할 수 있습니다.',

    mainImg: glassMainImg,
    imgs: [
      { name: '망입 유리', img: glassWire },
      { name: '브론즈 유리', img: glassBronze },
      { name: '아쿠아 유리', img: glassAqua },
    ],
  },
  {
    id: 4,
    title: 'endMaterial',
    ko_title: '마감자재',
    desc:
      '미소테크의 슬림도어는 각종 마감재와 색상별로 구비되어 있는 가스켓들로 어떠한 설치 환경에서도 완벽하게 인테리어와 조화를 이룰수 있습니다.',

    mainImg: linkEndMainImg,
    imgs: [
      { name: '유리 가스켓', img: glassEndCap },
      { name: '도어 가스켓', img: doorEndCap },
      { name: '세로바 마감캡', img: verticalEndCap },
      { name: '프레임 이틈새', img: swingFrame_gasket },
    ],
  },
  {
    id: 5,
    title: 'hardware',
    ko_title: '하드웨어',
    desc:
      '하드웨어는 연구개발부서 운영을 통한 개발품이며, 슬라이딩 도어에 최적화 되어있습니다. 고객의 요구에 맞게 언제든 신제품 개발과 더불어 수정 보완을 해 나가고 있습니다.',

    mainImg: swingHardwareMain,
    imgs: [
      { name: ' 하드웨어', img: swingHardware1 },
      { name: '하부 브라켓', img: swingHardware2 },
      { name: '상부 피봇', img: swingHardware3 },
      { name: '하부식기', img: swingBottomFrame },
    ],
  },
];

export const swingInfo = {
  title: '스윙 도어',
  path: '/product/swing',
  en_title: 'swing door',
  desc: [
    '새로운 공간을 창조하는 인테리어로 인기를 끌고 있는 미소테크 슬림도어',
    '미소테크 슬림도어는 새로운 공간을 디자인합니다.',
  ],
  dividingImgs: [
    {
      title: ['분할패턴-', 'MDH3'],
      img: swingSubImg1,
      name: 'subImg1',
    },
    {
      title: ['분할패턴-', 'MH3'],
      img: swingSubImg2,
      name: 'subImg2',
    },
    {
      title: ['분할패턴-', 'MDH5'],
      img: swingSubImg3,
      name: 'subImg3',
    },
  ],
  dividingPatternImg: swingDividingPatterns,
  dividingPatternDesc: [
    '스윙도어는 실내 뿐만아니라 실외 출입문 용도로도 ',
    '사용하며 자체 개발한 하드웨어는 강하면서 부드러우며, ',
    '기밀성이 강합니다.',
  ],
  dividingTitle: 'Dividing & Frame',
  dividingDesc: [
    '미소테크는 인테리어 도어의 완성입니다.',
    '미소테크 중문은 인테리어 도어의 시작입니다.',
    '슬림한 프레임에 다양한 분할 패턴을 적용하여 세련된 디자인을 연출할 수 있습니다.',
  ],
  dividingBarImgs: [
    { name: 'swingDividing_pattern', img: swingDividing_pattern },
    { name: 'productDividing1', img: productDividing1 },
  ],
  designTitle: 'Design & Hardware',
  designDesc: [
    '실용적인 디자인과 자체 개발한 하드웨어는 품격있는 인테리어를 완성합니다.',
    '미소테크의 기술력은 소비자의 요구와 함께합니다.',
  ],
  bottomRail: [
    {
      img: swingSideFrame,
      name: 'swingSideFrame',
      title: '선택 사용 가능한 문틀 측면바',
      desc: [
        '인테리어 취향에 맞도록 한쪽에는 모헤어를',
        '반대에는 이틈새를 사용하여 기밀성을 높여줍니다.',
      ],
    },
    {
      img: swingSideFrame2,
      name: 'swingSideFrame2',
      title: '쉽고 기밀한 문틀 체결 구조',
      desc: [
        '문틀 구조가 단순하여 쉽게 시공이 가능하고,',
        '선택적 사용이 가능하여, 인테리어 분위기에 맞게',
        '스윙도어를 디자인 할 수 있습니다.',
      ],
    },
  ],
  bottomImgs: [
    { title: '건물입구', name: swingBottomImg1, en_title: 'swingBottomImg1' },
    { title: '키즈룸', name: swingBottomImg2, en_title: 'swingBottomImg2' },
    { title: '현관', name: swingBottomImg3, en_title: 'swingBottomImg3' },
  ],
};

export const partitionData = [
  {
    id: 1,
    title: 'frameMainImg',
    ko_title: '세련된 4종의 프레임 색상',
    desc:
      '다양한 색상을 보유함으로 트렌디한 인테리어 디자인에 최적으로 매칭시킬 수 있습니다. 특히 아노다이징 도장은 고급스럽고도 은은한 광택으로 인기가 많습니다. 불소 도장 또한 친환경 원료를 사용하고 3코팅 기법으로 특유의 고급스러움을 자랑합니다.',

    mainImg: frameMainImg,
    imgs: [
      { name: '다크실버', img: blackFrame },
      { name: '화이트', img: whiteFrame },
      { name: '진골드', img: boldGoldFrame },
      { name: '샴페인골드', img: CGoldFrame },
    ],
  },
  {
    id: 2,
    title: 'glass',
    ko_title: '안전한 유리',
    desc:
      '슬림도어에 최적화 되어있는 안전하고 견고한 강화유리를 사용합니다. 또한 다양한 유리를 분할 사용하고 고유한 질감을 디자인에 적용하여 제작할 수 있습니다.',

    mainImg: glassMainImg,
    imgs: [
      { name: '망입 유리', img: glassWire },
      { name: '브론즈 유리', img: glassBronze },
      { name: '아쿠아 유리', img: glassAqua },
    ],
  },
  {
    id: 3,
    title: 'endMaterial',
    ko_title: '마감자재',
    desc:
      '미소테크의 슬림도어는 각종 마감재와 색상별로 구비되어 있는 가스켓들로 어떠한 설치 환경에서도 완벽하게 인테리어와 조화를 이룰수 있습니다.',

    mainImg: closeMainImg,
    imgs: [
      { name: '유리 가스켓', img: glassEndCap },
      { name: '도어 가스켓', img: doorEndCap },
      { name: 'H 고정바', img: closeHbar },
      { name: '측면문틀', img: closeSideFrameBar },
    ],
  },
  {
    id: 4,
    title: 'hardware',
    ko_title: '하드웨어',
    desc:
      '하드웨어는 연구개발부서 운영을 통한 개발품이며, 슬라이딩 도어에 최적화 되어있습니다. 고객의 요구에 맞게 언제든 신제품 개발과 더불어 수정 보완을 해 나가고 있습니다.',

    mainImg: partitionHardwareMainImg,
    imgs: [
      { name: '고정 롤러', img: fixRollerHardware },
      { name: '신규 고정 롤러', img: newFixRollerHardware },
      { name: '하부 고정 레일', img: fixBottomBarHardware },
    ],
  },
];

export const partitionInfo = {
  title: '파티션',
  path: '/product/partition',
  en_title: 'partition',
  desc: [
    '새로운 공간을 창조하는 인테리어로 인기를 끌고 있는 미소테크 슬림도어',
    '미소테크 슬림도어는 새로운 공간을 디자인합니다.',
  ],
  dividingImgs: [
    {
      title: ['분할패턴 -', 'MP1'],
      img: partitionSubImg1,
      name: 'subImg1',
    },
    {
      title: ['분할패턴 -', 'MP2'],
      img: partitionSubImg2,
      name: 'subImg2',
    },
    {
      title: ['분할패턴 -', 'MP3'],
      img: partitionSubImg3,
      name: 'subImg3',
    },
  ],
  dividingPatternImg: partitionPatterns,
  dividingPatternDesc: [
    '슬림 파티션은 시공이 쉬우면서, 다양한 마감자재를 통해서',
    '어떠한 시공 상황에서도 조화롭게 설치되어 디자인적으로 우수합니다.',
  ],
  dividingTitle: 'Dividing & Frame',
  dividingDesc: [
    '미소테크는 인테리어 도어의 완성입니다.',
    '미소테크 중문은 인테리어 도어의 시작입니다.',
    '슬림한 프레임에 다양한 분할 패턴을 적용하여 세련된 디자인을 연출할 수 있습니다.',
  ],
  dividingBarImgs: [
    { name: 'partitionDividing', img: partitionDividing },
    { name: 'productDividing1', img: productDividing1 },
  ],
  designTitle: 'Design & Hardware',
  designDesc: [
    '실용적인 디자인과 자체 개발한 하드웨어는 품격있는 인테리어를 완성합니다.',
    '미소테크의 기술력은 소비자의 요구와 함께합니다.',
  ],
  bottomRail: [
    {
      img: fixRoller,
      name: 'fixRoller',
      title: '파티션 고정 상부 롤러',
      desc: [
        '상부레일 안에서 유격이 없이 파티션도어를 고정하도록',
        '유지시켜 주는 하드웨어, A/S가 용이한 구조입니다.',
      ],
    },
    {
      img: fixBottomRail,
      name: 'fixBottomRail',
      title: '파티션 고정 하부바',
      desc: [
        '파티션의 하부 흔들림이 없도록 바닥이 부착하여 고정하도록',
        '유지시켜 주는 하드웨어, A/S가 용이한 구조입니다.',
      ],
    },
  ],
  bottomImgs: [
    {
      title: '화장실',
      name: partitionnBottomImg1,
      en_title: 'partitionnBottomImg1',
    },
    {
      title: '현관',
      name: partitionnBottomImg2,
      en_title: 'partitionnBottomImg2',
    },
    {
      title: '사무실',
      name: partitionnBottomImg3,
      en_title: 'partitionnBottomImg3',
    },
  ],
};
