import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';

import mainImg from '../../assets/img/company/whatwedo/mainImg.png';

import { bussinessDatas } from './companyData';
import CompanyHeader from './CompanyHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 120px;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
  @media (max-width: 480px) {
    margin-bottom: 50px;
  }
`;

const Row = styled.div`
  width: 100%;
  margin-top: 100px;
  > img {
    width: 100%;
  }
  > h3 {
    font-size: 30px;
    padding: 20px 0;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: 480px) {
    margin-top: 50px;
    text-align: center;
    > div {
      flex-direction: column;
      align-items: center;
    }
  }
`;

const Column = styled.div`
  width: 49%;
  > img {
    width: 100%;
  }
  > p {
    word-wrap: break-word;
    word-break: keep-all;
  }
  @media (max-width: 480px) {
    width: 90%;
    margin-bottom: 30px;
  }
`;

const Title = styled.h1`
  padding: 15px 0;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 500;
  > em {
    color: #666;
    font-weight: 400;
  }
`;

const SubTitle = styled.h2`
  padding: 10px 0;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  color: #00a1e4;
  line-height: 30px;
`;

const Desc = styled.p`
  padding: 10px 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  color: #666;
  line-height: 25px;
  word-break: keep-all;
  @media (max-width: 480px) {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
`;

const Business = () => {
  const info = { title: '기업가치', path: '/company/business' };
  return (
    <>
      <CompanyHeader />
      <Container>
        <Subject info={info} />
        <Row>
          <h3 data-aos="fade-up" data-aos-duration="2000">
            기업가치
          </h3>
          <img
            src={mainImg}
            alt="businessMainImg"
            data-aos="fade-up"
            data-aos-duration="2000"
          />
        </Row>
        {bussinessDatas.map((data, index) => (
          <Row key={index} data-aos="fade-up" data-aos-duration="2000">
            <Title>
              {data.title}&nbsp;
              <em>{data.ko_title}</em>
            </Title>
            <div>
              {data.columns.map((column, index) => (
                <Column key={index}>
                  <img
                    src={column.img}
                    alt="subImg"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  />
                  <SubTitle data-aos="fade-up" data-aos-duration="2000">
                    {column.subTitle}
                  </SubTitle>
                  <hr />
                  <Desc data-aos="fade-up" data-aos-duration="2000">
                    {column.desc}
                  </Desc>
                </Column>
              ))}
            </div>
          </Row>
        ))}
      </Container>
    </>
  );
};

export default Business;
