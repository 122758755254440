import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';

import historyLine from '../../assets/img/company/historyLine.gif';
import CompanyHeader from './CompanyHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 120px;
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const YearBox = styled.div`
  text-align: center;
  @media (max-width: 768px) {
    text-align: left;
  }
  > div {
    display: inline-block;
    background: #396eb3;
    margin: 0 auto;
    padding: 10px 30px;
    font-size: 19px;
    color: #fff;
    border-radius: 3px;
    @media (max-width: 768px) {
      padding: 8px 24px;
      font-size: 16px;
    }
  }
`;

const HistoryBox = styled.div`
  padding: 30px 0;
  display: table;
  width: 100%;
  position: relative;
  background: url(${historyLine}) center repeat-y;
  @media (max-width: 768px) {
    background: unset;
  }
`;

const RightBox = styled.div`
  width: 100%;
  display: block;
  > div {
    width: 100%;
    float: right;
    padding: 30px 0;
    padding-left: 50%;
    display: flex;
    @media (max-width: 768px) {
      padding: 0;
      padding-left: 0;
    }
    > div {
      > dl {
        padding-left: 30px;
        > dt {
          color: #000;
          font-size: 20px;
          padding-bottom: 5px;
          font-weight: 600;
        }
        > dd {
          font-size: 16px;
          width: 100%;
          padding-bottom: 14px;
          color: #5d5d5d;
          line-height: 30px;
          letter-spacing: -0.4px;
          word-break: keep-all;
        }
      }
    }
  }
`;

const LeftBox = styled(RightBox)`
  float: left;
  > div {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    padding-left: 0;
    padding-right: 50%;
    @media (max-width: 768px) {
      justify-content: flex-start;
      text-align: left;
      padding-right: 0;
    }
    > div {
      > dl {
        padding-right: 30px;
      }
    }
  }
  @media (max-width: 1080px) {
    padding-left: unset;
  }
`;

const History = () => {
  const info = { title: '회사연혁', path: '/company/history' };

  return (
    <>
      <CompanyHeader />

      <Container>
        <Subject info={info} />
        <YearBox>
          <div>2020 년</div>
        </YearBox>
        <HistoryBox>
          <RightBox>
            <div data-aos="fade-up" data-aos-duration="2000">
              <div>
                <dl>
                  <dt>10월</dt>
                  <dd>전산 발주 시스템 구축</dd>
                  <dt>09월</dt>
                  <dd>온라인 주문 어플 개발</dd>
                  <dt>08월</dt>
                  <dd>3연동 하드웨어 철물 양산/판매</dd>
                  <dt>07월</dt>
                  <dd>홈페이지 구축 완료</dd>
                </dl>
              </div>
            </div>
          </RightBox>
          <LeftBox>
            <div data-aos="fade-up" data-aos-duration="2000">
              <div>
                <dl>
                  <dt>06월</dt>
                  <dd>누드 슬라이딩 도어 개발</dd>
                  <dt>05월</dt>
                  <dd>랩핑 시스템 도어 개발</dd>
                  <dt>04월</dt>
                  <dd>카달로그 제작</dd>
                  <dt>02월</dt>
                  <dd>홈페이지 구축 완료</dd>
                </dl>
              </div>
            </div>
          </LeftBox>
        </HistoryBox>
        <YearBox>
          <div>2019 년</div>
        </YearBox>
        <HistoryBox>
          <RightBox>
            <div data-aos="fade-up" data-aos-duration="2000">
              <div>
                <dl>
                  <dt>10월</dt>
                  <dd>연구개발전담부서 설립</dd>
                  <dt>09월</dt>
                  <dd>공장 확장 이전</dd>
                  <dt>08월</dt>
                  <dd>3연동 하드웨어 철물 개발</dd>
                  <dt>09월</dt>
                  <dd>반자동 스윙도어 양산/판매</dd>
                </dl>
              </div>
            </div>
          </RightBox>
          <LeftBox>
            <div data-aos="fade-up" data-aos-duration="2000">
              <div>
                <dl>
                  <dt>04월</dt>
                  <dd>주식회사 미소테크 법인 전환</dd>
                  <dt>04월</dt>
                  <dd>반자동 스윙도어 개발 완료</dd>
                  <dt>02월</dt>
                  <dd>2SD/코너바 개발</dd>
                  <dt>01월</dt>
                  <dd>베트남 업체 기술 제휴 완료/양산</dd>
                </dl>
              </div>
            </div>
          </LeftBox>
        </HistoryBox>
        <YearBox>
          <div>2018 년</div>
        </YearBox>
        <HistoryBox>
          <RightBox>
            <div data-aos="fade-up" data-aos-duration="2000">
              <div>
                <dl>
                  <dt>12월</dt>
                  <dd>건설사 넉다운 500세대 납품</dd>
                  <dt>11월</dt>
                  <dd>3연동 대규모 특판 사업 진행</dd>
                  <dt>10월</dt>
                  <dd>전용 하드웨어 적용/판매</dd>
                  <dt>09월</dt>
                  <dd>베트남 업체 기술 제휴</dd>
                </dl>
              </div>
            </div>
          </RightBox>
          <LeftBox>
            <div data-aos="fade-up" data-aos-duration="2000">
              <div>
                <dl>
                  <dt>08월</dt>
                  <dd>전용 하드웨어 개발</dd>
                  <dt>07월</dt>
                  <dd>3연동 양산/판매</dd>
                  <dt>06월</dt>
                  <dd>3연동 전용 라인 신설</dd>
                  <dt>05월</dt>
                  <dd>거래처 20개소 확보</dd>
                </dl>
              </div>
            </div>
          </LeftBox>
          <RightBox>
            <div data-aos="fade-up" data-aos-duration="2000">
              <div>
                <dl>
                  <dt>04월</dt>
                  <dd>카달로그 제작</dd>
                  <dt>03월</dt>
                  <dd>3연동 도어 개발</dd>
                  <dt>02월</dt>
                  <dd>양개 여닫이 도어 양산/판매</dd>
                  <dt>01월</dt>
                  <dd>여닫이 도어 개발</dd>
                </dl>
              </div>
            </div>
          </RightBox>
        </HistoryBox>
        <YearBox>
          <div>2017 년</div>
        </YearBox>
        <HistoryBox>
          <RightBox>
            <div data-aos="fade-up" data-aos-duration="2000">
              <div>
                <dl>
                  <dt>12월</dt>
                  <dd>슬라이딩 도어 마감자재 개발판매(디자인특허)</dd>
                  <dt>12월</dt>
                  <dd>픽스도어 개발/양산/판매</dd>
                  <dt>10월</dt>
                  <dd>원슬라이딩 양산/판매</dd>
                  <dt>09월</dt>
                  <dd>2017.09.16 미소테크 설립</dd>
                </dl>
              </div>
            </div>
          </RightBox>
        </HistoryBox>
      </Container>
    </>
  );
};

export default History;
