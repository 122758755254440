import React from 'react';
import styled from 'styled-components';

import cuttingCheck from '../../../assets/img/product/cuttingCheck.png';
import processCheck from '../../../assets/img/product/processCheck.png';
import packingCheck from '../../../assets/img/product/packingCheck.png';
import outCheck from '../../../assets/img/product/outCheck.png';

const Container = styled.div`
  margin: 200px 0 100px 0;
  text-align: center;
  @media (max-width: 768px) {
    margin: 100px 0 30px 0;
  }
  > h1 {
    font-size: 32px;
    margin: 30px;
    color: #1e1e1e;
    @media (max-width: 768px) {
      font-size: 18px;
      margin: 20px;
    }
    > em {
      font-weight: 600;
    }
  }
  > h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    padding: 10px 0;
    color: #2d3436;
    > em {
      color: #1e1e1e;
      font-weight: 900;
    }
  }
  > p {
    font-size: 18px;
    line-height: 34px;
    color: #636e72;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 24px;
    }
    &:last-child {
      color: #d63031;
      font-weight: 500;
    }
  }
  > div {
    padding: 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 768px) {
      padding: 20px 0;
    }
    > div {
      width: 20%;
      margin: 1%;
      @media (max-width: 768px) {
        width: 40%;
        margin: 3%;
      }
      > img {
        width: 100%;
      }
      > p {
        font-size: 18px;
        line-height: 40px;
        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
`;

const ProcessSection = () => {
  return (
    <Container>
      <h1>
        미소테크 <em>생산작업</em>
      </h1>
      <h2>
        <em>ONE STOP</em> 시공
      </h2>
      <p>주문을 받고 즉시 전산시스템을 통한 작업지시서를 통해</p>
      <p>정확하고 빠른 작업을 진행하여 시공해 드립니다.</p>
      <div>
        <div>
          <img src={cuttingCheck} alt="cuttingCheck" />
          <p>1. 절단 공정</p>
        </div>
        <div>
          <img src={processCheck} alt="processCheck" />
          <p>2. 가공 공정</p>
        </div>
        <div>
          <img src={packingCheck} alt="packingCheck" />
          <p>3. 포장 공정</p>
        </div>
        <div>
          <img src={outCheck} alt="outCheck" />
          <p>4. 출고</p>
        </div>
      </div>
      <p>모든 공정은 중복 검수를 통하여 완벽한 제품을 출고합니다.</p>
      <p>미소테크의 모든 제품은 본사에서 직접 생산됩니다.</p>
    </Container>
  );
};

export default ProcessSection;
