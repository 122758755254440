import React from 'react';
import styled from 'styled-components';

const Container = styled.ul`
  width: 100%;
  position: absolute;
  top: 78px;
  > li {
    :first-child {
      border-top: 1px solid #ddd;
    }
    border-bottom: 1px solid #ddd;
  }
`;

const Item = styled.li`
  background: #fff;
  :hover {
    > ul {
      display: block;
      position: relative;
      top: 0;
      -webkit-transition: height 0.5s ease;
      height: ${(props) => props.height * 37}px;
      overflow: hidden;
      width: 100%;
    }
  }
`;

const Menu = styled.p`
  font-size: 14px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  > i {
    font-size: 16px;
  }
  .fa-angle-up {
    display: none;
  }
  :hover {
    .fa-angle-up {
      display: block;
    }
    .fa-angle-down {
      display: none;
    }
  }
`;

const SubList = styled.ul`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    position: relative;
    top: 0;
    -webkit-transition: height 1s ease;
    height: 0;
    overflow: hidden;
    width: 100%;
  }
`;

const SubItem = styled.li`
  font-size: 12px;
  background: #555;
  padding: 0 20px;
  border-bottom: 1px solid #666;
`;

const SubMenu = styled.a`
  display: block;
  height: 36px;
  width: 100%;
  color: white;
  padding: 13px 0 11px 0;
`;

const Dropdown = ({ datas }) => {
  return (
    <Container className="dropdown_list">
      {datas.map((data, index) => (
        <Item key={index} height={data.subMenus.length}>
          <Menu>
            {data.name}
            <i className="fas fa-angle-up"></i>
            <i className="fas fa-angle-down"></i>
          </Menu>
          <SubList>
            {data.subMenus.map((subMenu, index) => (
              <SubItem key={index}>
                <SubMenu href={subMenu.subLink}>{subMenu.subName}</SubMenu>
              </SubItem>
            ))}
          </SubList>
        </Item>
      ))}
    </Container>
  );
};

export default Dropdown;
