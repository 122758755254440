import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 100px 0;
  @media (max-width: 768px) {
    margin: 40px 0 0 0;
  }
  > h1 {
    font-size: 48px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    color: #1e1e1e;
    font-weight: 800;
    letter-spacing: 10px;
    @media (max-width: 768px) {
      letter-spacing: 4px;
      font-size: 26px;
    }
  }
  > p {
    text-align: center;
    font-size: 20px;
    word-break: keep-all;
    line-height: 40px;
    color: #666;
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 20px;
    }
    :nth-child(2) {
      margin-bottom: 40px;
      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
    }
    :nth-child(3),
    :last-child {
      padding: 10px 220px;
      font-size: 24px;
      line-height: 30px;
      color: #1e1e1e;
      word-break: keep-all;
      @media (max-width: 768px) {
        font-size: 14px;
        padding: 2px 40px;
        line-height: 24px;
      }
    }
  }
`;

const HeaderContents = ({ info }) => {
  return (
    <Container>
      <h1>{info.en_title}</h1>
      <p>모던하고 세련된 공간 혁신, 미소테크 슬림도어</p>
      <p>{info.desc[0]}</p>
      <p>{info.desc[1]}</p>
    </Container>
  );
};

export default HeaderContents;
