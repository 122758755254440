import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import handleOneCost from './EstimateFunc';

const Table = styled.table`
  width: 100%;
  text-align: center;
  border-top: 2px solid #1e459c !important;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;
  line-height: 30px;
  @media (max-width: 1280px) {
    width: 100%;
    line-height: 28px;
  }
  @media (max-width: 1080px) {
    font-size: 14px;
    line-height: 26px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  tr {
    color: #5d5d5d;
    background: #fff;
    @media (max-width: 480px) {
      font-size: 12px;
    }
    &:first-child {
      color: #000;
      background: #f5f5f5;
    }
    > td {
      padding: 10px 5px;
      border: 1px solid #ddd;
      &:first-child {
        width: 30%;
      }
      > input,
      select {
        width: 80%;
        border: 1px solid #ccc;
        padding: 5px;
        font-size: 15px;
        color: #333333;
        font-weight: 300;
        font-family: 'Noto Sans KR', sans-serif;
      }
    }
  }
`;

const EstimateTableLink = () => {
  const [oneSpec, setOneSpec] = useState({
    oneInstallWidth: 0,
    oneInstallHeight: 0,
    oneBottomRail: 'exposed',
    oneColor: 'darkSilver',
    oneGlass: 'defaultGlass',
    oneDelivery: 'knockDown',
    oneLocation: 'inSeoul',
  });
  const [oneCost, setOneCost] = useState(0);

  const handleChange = (e) => {
    setOneSpec({ ...oneSpec, [e.target.name]: e.target.value });
    console.log(oneSpec);
  };

  useEffect(() => {
    handleOneCost({ oneSpec, setOneCost });
  }, [oneSpec]);
  return (
    <Table>
      <tbody>
        <tr>
          <td colSpan="2">연동 도어 견적 산출</td>
        </tr>
        <tr>
          <td>설치 폭</td>
          <td>
            <input
              type="number"
              id="oneInstallWidth"
              name="oneInstallWidth"
              onChange={(e) =>
                setOneSpec({
                  ...oneSpec,
                  [e.target.name]: e.target.value,
                })
              }
              value={oneSpec.oneInstallWidth}
            />
          </td>
        </tr>
        <tr>
          <td>설치 높이</td>
          <td>
            <input
              type="number"
              id="oneInstallHeight"
              name="oneInstallHeight"
              onChange={(e) =>
                setOneSpec({
                  ...oneSpec,
                  [e.target.name]: e.target.value,
                })
              }
              value={oneSpec.oneInstallHeight}
            />
          </td>
        </tr>
        <tr>
          <td>하부레일 방식</td>
          <td>
            <select
              name="oneBottomRail"
              id="oneBottomRail"
              onChange={handleChange}>
              <option value="exposed">노출</option>
              <option value="burried">매립</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>색 상</td>
          <td>
            <select name="oneColor" id="oneColor" onChange={handleChange}>
              <option value="darkSilver">다크실버</option>
              <option value="white">화이트</option>
              <option value="boldGold">진골드</option>
              <option value="CGold">샴페인골드</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>유 리</td>
          <td>
            <select name="oneGlass" id="oneGlass" onChange={handleChange}>
              <option value="defaultGlass">투명, 브론즈</option>
              <option value="satinGlass">사틴 유리</option>
              <option value="doubleGlass">접합 유리</option>
              <option value="specialGlass">특수 유리</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>납품 방식</td>
          <td>
            <select name="oneDelivery" id="oneDelivery" onChange={handleChange}>
              <option value="knockDown">넉다운</option>
              <option value="assembly">조립 납품</option>
              <option value="install">조립, 시공</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>설치 지역</td>
          <td>
            <select name="oneLocation" id="oneLocation">
              <option value="inSeoul">서울 이내</option>
              <option value="inGunggi">수도권 이내</option>
              <option value="fromGunggi2hour">수도권 2시간 이내</option>
              <option value="fromGunggi4hour">수도권 4시간 이내</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>예상 가격</td>
          <td>
            <input type="number" id="oneExpectCost" value={oneCost} />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default EstimateTableLink;
