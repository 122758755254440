import React from 'react';
import styled from 'styled-components';

import Subject from '../../Components/Subject';

import mhIparkMain from '../../assets/img/portfolio/modelHouse/mh_ipark_main.png';
import mhIpark01 from '../../assets/img/portfolio/modelHouse/mh_ipark_01.png';
import mhIpark02 from '../../assets/img/portfolio/modelHouse/mh_ipark_02.jpg';
import mhIpark03 from '../../assets/img/portfolio/modelHouse/mh_ipark_03.png';
import mhIpark04 from '../../assets/img/portfolio/modelHouse/mh_ipark_04.png';
import mhIpark05 from '../../assets/img/portfolio/modelHouse/mh_ipark_05.png';
import mhIpark06 from '../../assets/img/portfolio/modelHouse/mh_ipark_06.png';
import mhEntriumMain from '../../assets/img/portfolio/modelHouse/mh_entrium_main.png';
import mhEntrium01 from '../../assets/img/portfolio/modelHouse/mh_entrium_01.png';
import mhEntrium02 from '../../assets/img/portfolio/modelHouse/mh_entrium_02.png';
import mhEntrium03 from '../../assets/img/portfolio/modelHouse/mh_entrium_03.png';
import mhEntrium04 from '../../assets/img/portfolio/modelHouse/mh_entrium_04.png';
import mhEntrium05 from '../../assets/img/portfolio/modelHouse/mh_entrium_05.png';
import mhEntrium06 from '../../assets/img/portfolio/modelHouse/mh_entrium_06.png';
import mhEntrium07 from '../../assets/img/portfolio/modelHouse/mh_entrium_07.png';
import mhEntrium08 from '../../assets/img/portfolio/modelHouse/mh_entrium_08.png';
import PortfolioHeader from './PortfolioHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div`
  width: 100%;
  margin: 0;
  padding: 60px 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  @media (max-width: 1080px) {
    padding: 50px 0px;
  }
  @media (max-width: 768px) {
    padding: 40px 0px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  padding-bottom: 15px;
  @media (max-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const Name = styled.h2`
   {
    color: #00529b;
    padding: 15px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 0;
    margin-bottom: 10px;
  }
  @media (max-width: 1080px) {
    font-size: 17px;
    line-height: 24px;
  }
`;

const Desc = styled.p`
  font-size: 17px;
  line-height: 24px;
  word-break: keep-all;
  margin-bottom: 50px;
  @media (max-width: 1080px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }
`;

const ImgBox = styled.div`
  width: 1200px;
  margin-bottom: 30px;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  > img {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const Column = styled.div`
  width: 48%;
  margin: 1%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 20px;
  }
  > img {
    width: 100%;
  }
`;

const Hr = styled.hr`
  margin: 100px 0;
  @media (max-width: 1280px) {
    margin: 80px 0;
  }
  @media (max-width: 1080px) {
    margin: 60px 0;
  }
  @media (max-width: 768px) {
    margin: 50px 0;
  }
  @media (max-width: 480px) {
    margin: 40px 0;
  }
`;

const ModelHouse = () => {
  const info = { title: '모델하우스', path: '/portfolio/modelhouse' };

  return (
    <>
      <PortfolioHeader />

      <Container>
        <Subject info={info} />
        <Box>
          <Title data-aos="fade-up" data-aos-duration="2000">
            아이파크 모델하우스
          </Title>
          <ImgBox data-aos="fade-up" data-aos-duration="2000">
            <img src={mhIparkMain} alt="portfolioImg0" />
          </ImgBox>
          <Name data-aos="fade-up" data-aos-duration="2000">
            대치동 M/H 다이닝룸 3연동 도어
          </Name>
          <Desc data-aos="fade-up" data-aos-duration="2000">
            모델하우스 실측부터 규격을 모두 고려하여 스펙에 걸맞는 제품을
            설치합니다. 주방과 조리대를 분리하여 완벽하면서 아늑한 다이닝 룸을
            조성합니다.
          </Desc>
          <Row>
            <Column>
              <img
                src={mhIpark01}
                alt="portfolioImg2"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={mhIpark02}
                alt="portfolioImg3"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={mhIpark03}
                alt="portfolioImg3"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={mhIpark04}
                alt="portfolioImg4"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={mhIpark05}
                alt="portfolioImg5"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={mhIpark06}
                alt="portfolioImg6"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Hr />
          <Title data-aos="fade-up" data-aos-duration="2000">
            오서아이티엠 모델하우스
          </Title>
          <ImgBox data-aos="fade-up" data-aos-duration="2000">
            <img src={mhEntriumMain} alt="portfolioImg5" />
          </ImgBox>
          <Name data-aos="fade-up" data-aos-duration="2000">
            영등포 M/H 현관 드레스룸 슬라이딩 도어
          </Name>
          <Desc data-aos="fade-up" data-aos-duration="2000">
            모델하우스 실측부터 규격을 모두 고려하여 스펙에 걸맞는 제품을
            설치합니다. 현관과 드레스룸에 슬라이딩 도어를 설치하여 모던한 라이프
            스타일을 느낄 수 있습니다.
          </Desc>
          <Row>
            <Column>
              <img
                src={mhEntrium01}
                alt="portfolioImg1"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={mhEntrium02}
                alt="portfolioImg2"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={mhEntrium03}
                alt="portfolioImg3"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={mhEntrium04}
                alt="portfolioImg4"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={mhEntrium05}
                alt="portfolioImg5"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={mhEntrium06}
                alt="portfolioImg6"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={mhEntrium07}
                alt="portfolioImg7"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={mhEntrium08}
                alt="portfolioImg8"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
        </Box>
      </Container>
    </>
  );
};

export default ModelHouse;
