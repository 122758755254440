import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';
import CustomerHeader from './CustomerHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 100px;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
  @media (max-width: 480px) {
    width: 100%;
    margin-bottom: 0;
  }
`;

const List = styled.ul`
  width: 80%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
    padding: ;
  }
`;

const Item = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  cursor: pointer;
  line-height: 36px;
  border-left: 1px solid #666;
  border-right: 1px solid #666;
  border-bottom: 1px solid #666;
  &:first-child {
    border-top: 1px solid #666;
  }
  > div:first-child {
    > i {
      padding: 10px 10px 10px 20px;
      @media (max-width: 768px) {
        padding: 5px;
      }
    }
  }
  > div:last-child {
    font-size: 18px;
    padding: 20px 40px;
    line-height: 32px;
    background: #f5f5f5;
    word-break: keep-all;
    border-top: 1px solid #666;
    @media (max-width: 768px) {
      font-size: 14px;
      padding: 10px;
    }
  }
  :hover {
  }
`;

const datas = [
  {
    question: '미소테크 인테리어 도어는 주로 어디에 사용되나요?',
    answer:
      '요즘 많이 사용되는 공간은 현관 중문, 주방 중문 등으로 많이 사용이 됩니다. 공간을 분리하고자 하는 곳은 어디에나 적용하실 수 있습니다.',
  },
  {
    question: '인테리어 도어에는 어떤 유리가 적용되나요?',
    answer:
      '소비자가 원하시는 다양한 유리를 적용할 수 있습니다. 꼭 유리가 아니더라도 5mm 두께의 다양한 소재 적용이 가능합니다.',
  },
  {
    question: '공사가 끝나거나, 살고있는 집에 인테리어도어 적용이 가능한가요?',
    answer:
      '제품 스타일에 따라 다르지만, 대부분의 제품들이 인테리어가 끝난 후에도 시공이 가능합니다.',
  },
  {
    question: '제품 주문은 어떻게 하나요?',
    answer:
      '전국에 있는 미소테크 대리점을 통하여 주문이 가능하고, 또한 직접 미소테크로 주문을 하실 수 있습니다.',
  },
  {
    question: '취급점 및 대리점 업체 등록은 어떻게 하나요?',
    answer:
      '적절한 계약서와 함께 카달로그를 확인하신 후 친절한 상담의 과정을 거쳐 대리점 계약을 하실 수 있습니다.',
  },
  {
    question: '가격은 어떻게 측정되나요?',
    answer:
      '제품에 따라 측정금액은 다릅니다. 또한 설치 장소의 사이즈 및 제품 출고방식(반조립/완조립)에 따라 다릅니다. 그리고 시공을 포함하는 경우에는, 시공비가 별도로 추가됩니다.',
  },
  {
    question: '샘플이나 미니어쳐를 구매하고 싶은데 가능한가요?',
    answer:
      '미소테크의 모든 제품은 미니어쳐 판매가 가능합니다. 또한 전시장 설치 및 샘플 미니어쳐 가능경우에는 할인되어 적극적으로 지원해 드리고 있습니다.',
  },
  {
    question: '주문 후 출고기간은 어떻게되나요?',
    answer:
      '일부 제품에 대해서는 2시 이전 주문시 당일 출고가 가능합니다. 물론 출고량에 따라 익일 출고될 수도 있으며, 최대 3일 전에는 출고됩니다.',
  },
  {
    question: '시공하는 방법과 시공시간은 어떻게 되나요?',
    answer:
      '도어 종류와 시공자의 숙련도에 따라서 시공시간이 상이하지만 최소 40분에서 최대 2시간 정도의 시간이 소요됩니다. 미소테크는 자체 시공팀을 별도로 운영하여, 도어 시공에 대한 교육을 별도로 진행 하고 있습니다.',
  },
  {
    question: '지방 설치도 가능한가요?',
    answer:
      '전국에 위치하고 있는 대리점을 통하여, 가까운 대리점을 통하여 설치가 가능합니다. 가까운 지역 대리점이 없는 경우 대표번호 혹은 홈페이지로 문의주시면 답변드리겠습니다.',
  },
];

const Faq = () => {
  const info = { title: 'FAQ', path: '/customer/faq' };

  return (
    <>
      <CustomerHeader />

      <Container>
        <Subject info={info} />
        <List>
          {datas.map((data, index) => (
            <React.Fragment key={index}>
              <Item key={index}>
                <div>
                  <i className="fas fa-plus"></i>
                  {/* <i className="fas fa-minus"></i> */}
                  {data.question}
                </div>
                <div>{data.answer}</div>
              </Item>
            </React.Fragment>
          ))}
        </List>
      </Container>
    </>
  );
};

export default Faq;
