import React from 'react';
import styled from 'styled-components';
import companyHeaderImg from '../../assets/img/company/companyHeaderImg.png';
import { withRouter, NavLink } from 'react-router-dom';

const Header = styled.div`
  background: url(${(props) => props.back}) center no-repeat;
  text-align: center;
  background-size: cover;
`;

const Box = styled.div`
  margin: 0;
  padding: 100px 0;
  border: 0;
  font-size: 100%;
  @media (max-width: 1080px) {
    padding: 50px 20px;
  }
  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const Title = styled.h1`
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
      transform: translateX(-20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
  }

  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  font-size: 36px;
  font-weight: 800;
  color: #ffffff;
  font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
  padding-bottom: 10px;
  @media (max-width: 1080px) {
    font-size: 28px;
    line-height: 34px;
    padding-bottom: 10px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 10px;
  }
`;

const Desc = styled.p`
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
  }
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  word-break: keep-all;
  color: #eee;
  font-size: 15px;
  line-height: 22px;
  @media (max-width: 1080px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const SubMenu = styled.div`
  background: #001236;
  text-align: center;
  > div {
    width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 1280px) {
      width: 100%;
    }
    > ul {
      display: inline-block;
      vertical-align: top;
      list-style: none;
      > li {
        float: left;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        height: 100%;
        :last-child {
          border-right: 1px solid rgba(255, 255, 255, 0.2);
        }
        > a {
          display: block;
          padding: 18px 40px;
          font-size: 17px;
          font-weight: 500;
          @media (max-width: 1080px) {
            padding: 15px 30px;
            font-size: 14px;
          }
          @media (max-width: 768px) {
            padding: 12px 4px;
            font-size: 12px;
          }
        }
        :hover {
          background: #fff;
          > a {
            color: #001236;
          }
        }
      }
    }
  }
`;

const Item = styled.li`
  background-color: ${(props) => (props.current ? '#fff' : '#001236')};
`;

const Slink = styled(NavLink)`
  color: ${(props) => (props.current ? '#001236' : '#fff')};
`;

const subMenuArr = [
  { title: '회사소개', link: '/company/intro' },
  { title: '기업가치', link: '/company/business' },
  { title: 'CEO 인사말', link: '/company/ceo_message' },
  { title: '회사연혁', link: '/company/history' },
  { title: '인증서/특허', link: '/company/patent' },
  { title: '오시는 길', link: '/company/map' },
];
const CompanyHeader = ({ location: { pathname } }) => {
  return (
    <Header back={companyHeaderImg}>
      <Box>
        <Title>Company</Title>
        <Desc>
          고객의 미소가 저희의 행복입니다. 함께 상생하는 회사가 되겠습니다.
        </Desc>
      </Box>
      <SubMenu>
        <div>
          <ul>
            {subMenuArr.map((info, index) => (
              <Item key={index} current={pathname === info.link}>
                <Slink to={info.link} current={pathname === info.link}>
                  {info.title}
                </Slink>
              </Item>
            ))}
          </ul>
        </div>
      </SubMenu>
    </Header>
  );
};

export default withRouter(CompanyHeader);
