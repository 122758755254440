import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import headerLogo2 from '../assets/img/header/headerLogo2.png';
import Dropdown from './Dropdown';
import UserContext from '../Context/UserContext';
import { Link, NavLink } from 'react-router-dom';

const Container = styled.header`
  width: 100%;
  padding-bottom: ${(props) => props.pdbottom};
  @media (max-width: 1080px) {
    padding-bottom: ${(props) => props.pdbottom2};
  }
  @media (max-width: 768px) {
    padding-bottom: ${(props) => props.pdbottom3};
  }
`;

const Box = styled.div`
  width: 100%;
  background: #f1f2f6;
  z-index: 9999;
  -webkit-transition: 0.5s all;
  -webkit-animation: 0.5s all;
  -moz-animation: 0.5s all;
  -ms-animation: 0.5s all;
  -o-animation: 0.5s all;
  transition: 0.5s all;
  &:first-child {
    visibility: ${(props) => props.visible};
  }
  &:last-child {
    position: fixed;
    top: ${(props) => props.top};
    background: #fff;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
  }
`;

const FixHeader = styled.div`
  width: 1200px;
  margin: 0 auto;
  height: 30px;
  display: flex;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  justify-content: flex-end;
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
  > ul {
    height: 100%;
    display: flex;
    > li {
      width: 100px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #2f3542;

      > a {
        font-weight: 600;
        color: #2f3542;
        cursor: pointer;
        > i {
          margin-right: 5px;
        }
        &:hover {
          font-weight: bold;
          color: #57606f;
          font-weight: bolder;
        }
      }
      &:last-child {
        border-right: 1px solid #2f3542;
        @media screen and (max-width: 1280px) {
          border-right: unset;
        }
      }
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const MainHeader = styled.div`
  width: 1200px;
  height: 88px;
  padding: 0 0 0 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s all;
  label,
  input {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    input {
      display: none;
    }
    label {
      display: block;
      font-size: 25px;
      padding-right: 15px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  @media only screen and (max-width: 1080px) {
    width: 100%;
    height: 78px;
    padding: 0 0 0 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
  > ul {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
      position: absolute;
      top: 78px;
      -webkit-transition: height 1s ease;
      height: ${(props) => props.listHeight};
      overflow: hidden;
      width: 100%;
    }
  }
  > div {
    display: flex;
    align-items: center;
    > a {
      > img {
        width: 100%;
        @media only screen and (max-width: 1080px) {
          width: 90%;
        }
        @media only screen and (max-width: 768px) {
          width: 80%;
          padding-left: 15px;
        }
      }
    }
    :nth-child(4) {
      @media only screen and (max-width: 768px) {
        display: none;
      }
      height: 100%;
      > ul {
        display: flex;
        align-items: center;
        height: 70%;
        > li {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 140px;
          height: 100%;
          position: relative;
          justify-content: center;
          > span {
            font-size: 18px;
            font-weight: 500;
            color: black;
            cursor: pointer;
            font-family: 'Noto Sans KR', sans-serif;
            > i {
              display: none;
            }
          }
          @media only screen and (max-width: 1080px) {
            width: 130px;
          }
          :hover {
            > ul {
              display: block;
              animation: growDown 500ms ease-in-out forwards;
              transform-origin: top;
            }
          }
          > ul {
            @keyframes growDown {
              0% {
                opacity: 0;
                transform: translateY(-10px);
              }

              100% {
                opacity: 1;
                transform: translateY(0);
              }
            }
            width: 100%;
            position: absolute;
            top: 50px;
            left: 0px;
            box-shadow: none;
            z-index: 999;
            display: none;
            list-style: none;
            padding: 0;
            margin: 0;
            > li {
              background: #fff;
              border-bottom: 1px solid #f0f0f0;
              text-align: center;
              > a {
                color: #2f3542;
                padding: 10px;
                font-size: 14px;
                font-weight: 400;
                font-family: 'Noto Sans KR', sans-serif;
                display: block;
                @media only screen and (max-width: 1080px) {
                  font-size: 12px;
                }
                :hover {
                  background: #a4b0be;
                  color: #fff;
                }
              }
            }
          }
          @media only screen and (max-width: 1080px) {
            width: 100px;
          }
          > span {
            cursor: pointer;
            font-size: 18px;
            font-weight: 500;
            color: black;
            &:first-child:hover {
              color: #8d99ae;
              font-weight: 500;
            }
            @media only screen and (max-width: 1080px) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
`;

const Header = () => {
  const [loca, setLoca] = useState();
  const [dropdown, setDropdwon] = useState('hidden');
  const [listHeight, setlistHeight] = useState(0);

  const handleToggleShow = () => {
    if (dropdown === 'hidden') {
      setDropdwon('visible');
      setlistHeight('500px');
    } else {
      setDropdwon('hidden');
      setlistHeight(0);
    }
  };

  const currentToken = localStorage.getItem('auth-token');
  const { userData, setUserData } = useContext(UserContext);

  const logout = () => {
    setUserData({
      token: undefined,
      userinfo: undefined,
    });
    localStorage.setItem('auth-token', '');
    window.location.href = '/';
  };

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      setLoca(window.pageYOffset);
    });
  }, []);

  const datas = [
    {
      name: '회사소개',
      subMenus: [
        { subName: '회사소개', subLink: '/company/intro' },
        { subName: '기업가치', subLink: '/company/business' },
        { subName: 'CEO 인사말', subLink: '/company/ceo_message' },
        { subName: '회사연혁', subLink: '/company/history' },
        { subName: '인증서/특허', subLink: '/company/patent' },
        { subName: '오시는길', subLink: '/company/map' },
      ],
    },
    {
      name: '제품소개',
      subMenus: [
        { subName: '슬라이딩 도어', subLink: '/product/sliding' },
        { subName: '연동 도어', subLink: '/product/link' },
        { subName: '여닫이 도어', subLink: '/product/hinge' },
        { subName: '스윙 도어', subLink: '/product/swing' },
        { subName: '파티션', subLink: '/product/partition' },
      ],
    },
    {
      name: '프로모션',
      subMenus: [
        { subName: '카탈로그', subLink: '/promotion/catalogue' },
        { subName: '미소소식', subLink: '/promotion/news' },
        { subName: '시공방법', subLink: '/promotion/knowledge' },
      ],
    },
    {
      name: '포트폴리오',
      subMenus: [
        { subName: '모델하우스', subLink: '/portfolio/modelhouse' },
        { subName: '아파트', subLink: '/portfolio/apartment' },
        { subName: '사무실', subLink: '/portfolio/office' },
        { subName: '드라마협찬', subLink: '/portfolio/drama' },
        { subName: '시공사진', subLink: '/portfolio/installation' },
      ],
    },
    {
      name: '고객지원',
      subMenus: [
        { subName: '고객상담', subLink: '/customer/counsel' },
        { subName: 'FAQ', subLink: '/customer/faq' },
        { subName: '담당자안내', subLink: '/customer/staff' },
        { subName: '온라인견적', subLink: '/customer/estimate' },
        currentToken !== ''
          ? { subName: '온라인발주', subLink: '/customer/order' }
          : { subName: '회원가입', subLink: '/user/join' },
      ],
    },
  ];

  return (
    <Container
      pdbottom={loca > 0 ? '68px' : '88px'}
      pdbottom2={loca > 0 ? '58px' : '78px'}
      pdbottom3={loca > 0 ? '8px' : '78px'}>
      <Box visible={loca > 0 ? 'hidden' : 'visible'}>
        <FixHeader>
          <ul>
            {currentToken !== '' ? (
              <>
                <li>
                  <NavLink to="/">
                    <i className="fas fa-home" />
                    HOME
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/user/edit">
                    <i className="fas fa-user-edit" />
                    회원정보
                  </NavLink>
                </li>
                <li>
                  <NavLink to="!#" onClick={logout}>
                    <i className="fas fa-sign-out-alt" />
                    로그아웃
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                <li>
                  <NavLink to="/">
                    <i className="fas fa-home" />
                    HOME
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/user/login">
                    <i className="fas fa-sign-in-alt" />
                    로그인
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/user/join">
                    <i className="fas fa-user-plus" />
                    회원가입
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </FixHeader>
      </Box>
      <Box top={loca > 0 ? '0px' : '30px'}>
        <MainHeader dropdown={dropdown} listHeight={listHeight}>
          <div>
            <Link to="/">
              <img src={headerLogo2} alt="headerLogo2" />
            </Link>
          </div>
          <label htmlFor="check">
            {dropdown === 'visible' ? (
              <i className="fas fa-times" onClick={handleToggleShow}></i>
            ) : (
              <i className="fas fa-bars" onClick={handleToggleShow}></i>
            )}
          </label>
          <input className="dropdown_check" id="check" type="checkbox" />
          <div>
            <ul>
              {datas.map((data, index) => (
                <li key={index}>
                  <span href="!#">
                    {data.name}
                    <i className="fas fa-angle-down"></i>
                  </span>
                  <ul>
                    {data.subMenus.map((subMenu, index) => (
                      <li key={index}>
                        <Link to={subMenu.subLink}>{subMenu.subName}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          <Dropdown datas={datas} className="dropdown_list" />
        </MainHeader>
      </Box>
    </Container>
  );
};

export default Header;
