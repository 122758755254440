import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';
import mainImg from '../../assets/img/product/partition/partitionMainImg.png';
import SpecSection from './productComponents/SpecSection';
import HeaderContents from './productComponents/HeaderContents';
import DividingPattern from './productComponents/DividingPattern';
import DividingDesc from './productComponents/DividingDesc';
import DividingImg from './productComponents/DividingImg';
import BottomRail from './productComponents/BottomRail';
import DesignDesc from './productComponents/DesignDesc';
import ProcessSection from './productComponents/ProcessSection';
import ProductQuility from './productComponents/ProductQuility';
import Neccessary from './productComponents/Neccessary';
import ProductHeader from './ProductHeader';
import { partitionData, partitionInfo } from './productData';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div`
  width: 100%;
`;

const Row = styled.div`
  > img {
    width: 100%;
  }
`;

const Partition = () => {
  return (
    <>
      <ProductHeader />
      <Container>
        <Subject info={partitionInfo} />
        <Box>
          <Row>
            <img src={mainImg} alt="mainImg" />
          </Row>
          <HeaderContents info={partitionInfo} />
          <DividingPattern info={partitionInfo} />
          <DividingDesc
            title={partitionInfo.dividingTitle}
            data={partitionInfo.dividingDesc}
          />
          <DividingImg info={partitionInfo.dividingBarImgs} />
          <BottomRail info={partitionInfo} />
          <DesignDesc
            title={partitionInfo.designTitle}
            data={partitionInfo.designDesc}
          />

          {partitionData.map((data, index) => (
            <SpecSection data={data} key={index} />
          ))}
          <ProcessSection />
          <Neccessary />
          <ProductQuility imgs={partitionInfo.bottomImgs} />
        </Box>
      </Container>
    </>
  );
};

export default Partition;
