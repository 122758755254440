import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  margin: 100px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > div {
    &:first-child {
      text-align: right;
      > img {
        width: 80%;
      }
      @media (max-width: 768px) {
        width: 100%;
        text-align: center;
        > img {
          width: 50%;
        }
      }
      /* main left coulmun */
    }
    &:last-child {
      /* right coulmun */
      width: 70%;
      padding-left: 10px;
      @media (max-width: 768px) {
        width: 100%;
        padding: 0;
      }
      > div {
        margin: 0;
        &:first-child {
          > h3 {
            padding-left: 5px;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: 600;
            color: #3b6eb3;
            > em {
              padding-left: 10px;
            }
            > div {
              width: 35px;
              height: 35px;
              background: #3b6eb3;
              text-align: center;
              color: white;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
            }
            @media (max-width: 768px) {
              padding: 10px 0;
              justify-content: center;
            }
          }
          > p {
            text-align: left;
            margin: 10px 0;
            font-size: 20px;
            line-height: 35px;
            padding-left: 50px;
            word-break: keep-all;
            @media (max-width: 768px) {
              font-size: 15px;
              line-height: 28px;
              padding: 10px 10px;
              text-align: center;
            }
          }
          /* text Row  */
        }
        &:last-child {
          display: flex;
          justify-content: space-evenly;
          margin-top: 20px;
          padding-left: 30px;
          flex-wrap: wrap;
          @media (max-width: 768px) {
            padding: 10px 0px;
            justify-content: center;
          }
          > div {
            width: ${(props) => (props.imgLeng > 3 ? '22.5%' : '31%')};
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (max-width: 768px) {
              width: ${(props) => (props.imgLeng > 3 ? '22.5%' : '31%')};
              margin: 0 auto;
            }
            > img {
              border: 2px solid #1e1e1e;
              width: 100%;
            }
            > span {
              padding-top: 5px;
              font-size: 16px;
              font-weight: 500;
              color: #1e1e1e;
              @media (max-width: 768px) {
                font-size: 12px;
              }
            }
            &:first-child {
              /* assembly handle column  */
            }
            &:last-child {
              /* screw handle column  */
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    margin: 50px 0;
  }
`;

const SpecSection = ({ data }) => {
  return (
    <Row imgLeng={data.imgs.length}>
      <div>
        <img src={data.mainImg} alt={data.title} />
      </div>
      <div>
        <div>
          <h3>
            <div>{data.id}</div>
            <em>{data.ko_title}</em>
          </h3>
          <p
            dangerouslySetInnerHTML={{
              __html: data.desc,
            }}></p>
        </div>
        <div>
          {data.imgs.map((img, index) => (
            <div key={index}>
              <img src={img.img} alt={img.name} />
              <span>{img.name}</span>
            </div>
          ))}
        </div>
      </div>
    </Row>
  );
};

export default SpecSection;
