import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Cotainer = styled.section`
  padding: 40px 30px;
  border-top: 1px solid #ddd;
  text-align: left;
  display: table;
  width: 100%;
  position: relative;
  @media screen and (max-width: 1080px) {
    padding: 30px 20px;
  }
`;

const Wrap = styled.div`
  display: flex;
  width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    > div {
      width: 50%;
      &:first-child {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 480px) {
    > div {
      width: 100%;
    }
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: 33%;

  &:first-child {
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 1080px) {
      padding-right: 20px;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
      padding-right: 0px;
    }
    @media screen and (max-width: 480px) {
      padding: 0 10px;
    }
    > h1 {
      width: 80%;
      float: left;
    }
    > a {
      display: inline-block;
      width: 20%;
      float: right;
      padding-right: 10px;
      text-align: right;
      font-size: 13px;
      line-height: 18px;
      color: #666;
    }
    > div {
      width: 100%;
      > ul {
        li {
          height: 34px;
          border-bottom: 1px solid #ddd;
          ::before {
            content: '';
            width: 3px;
            height: 3px;
            background: #666;
            border-radius: 50px;
            vertical-align: top;
            margin-right: 10px;
            margin-top: 17px;
            display: inline-block;
          }
          > a {
            padding: 0 0 0 0;
            color: #666;
            text-decoration: none;
            font-size: 13px;
            line-height: 34px;
          }
          > span {
            float: right;
            color: #666;
            text-decoration: none;
            font-size: 13px;
            line-height: 34px;
          }
        }
      }
    }
  }
  &:nth-child(2) {
    padding: 0 30px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;

    @media screen and (max-width: 768px) {
      padding: 0 10px;
      border-left: unset;
    }
    @media screen and (max-width: 480px) {
      margin: 10px 0;
      border-right: unset;
    }
    > div {
      > ul {
        > li {
          &:first-child {
            color: #d00203;
            font-size: 30px;
            line-height: 36px;
            font-weight: 700;
            font-family: 'Montserrat', sans-serif;
            padding-bottom: 10px;
            > i {
              font-size: 24px;
            }
          }
          color: #666;
          font-size: 13px;
          line-height: 20px;
          padding-left: 10px;
          margin: 2px 0;
          text-indent: -14px;
          margin-left: 14px;
          ::before {
            content: '';
            width: 3px;
            height: 3px;
            background: #666;
            border-radius: 50px;
            vertical-align: top;
            margin-right: 10px;
            margin-top: 10px;
            display: inline-block;
          }
          &:last-child {
            padding-top: 20px !important;
            padding: 0;
            text-indent: 0;
            margin: 0;
            font-size: 0;
            text-align: center;
            > a {
              display: inline-block;
              width: 46%;
              margin: 1%;
              border-radius: 3px;
              color: #fff;
              background: #787878;
              padding: 10px;
              font-size: 14px;
              text-align: center;
              vertical-align: bottom;
              :hover {
                background: #555;
              }
              &:last-child {
                background: #001f60;
                :hover {
                  background: #00113c;
                }
              }
            }
            ::before {
              display: none;
            }
          }
        }
      }
    }
  }
  &:last-child {
    width: 33%;
    vertical-align: top;
    padding-left: 30px;
    @media screen and (max-width: 1080px) {
      padding-left: 20px;
    }
    @media screen and (max-width: 768px) {
      width: 50%;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      height: 200px;
      padding: 0 10px;
      margin-top: 20px;
    }
    > iframe {
      width: 100%;
      height: 100%;
    }
  }
`;

const Title = styled.h1`
  color: #000;
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 15px;
  font-weight: 500;
  @media screen and (max-width: 1080px) {
    font-size: 16px;
    padding-bottom: 10px;
  }
`;

const Customer = () => {
  return (
    <Cotainer>
      <Wrap>
        <Box data-aos="fade-up" data-aos-duration="2000">
          <Title>미소뉴스</Title>
          <Link to="#">more ></Link>
          <div>
            <ul>
              <li>
                <Link href="/promotion/news">
                  인스타그램 계정을 개설하였습니다.
                </Link>
                <span>2020-08-01</span>
              </li>
              <li>
                <Link to="/promotion/news">
                  미소테크 홈페이지가 오픈되었습니다.
                </Link>
                <span>2020-08-01</span>
              </li>
            </ul>
          </div>
        </Box>
        <Box data-aos="fade-up" data-aos-duration="2000">
          <Title>고객센터</Title>
          <div>
            <ul>
              <li>
                <i className="fas fa-phone-alt"></i>&nbsp;
                <Link to="tel:010-4626-1042">010-4626-1042</Link>
              </li>
              <li>상담시간 : 09:00-18:00</li>
              <li>점심시간 : 12:00-13:00 (주말, 공휴일 휴무)</li>
              <li>
                <Link to="/customer/counsel">1:1문의하기</Link>
                <Link to="/customer/faq">FAQ</Link>
              </li>
            </ul>
          </div>
        </Box>
        <Box data-aos="fade-up" data-aos-duration="2000">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/ZmhpUgf_M9U"
            frameBorder="0"
            title="companyIntro"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}></iframe>
        </Box>
      </Wrap>
    </Cotainer>
  );
};

export default Customer;
