import React from 'react';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Subject from '../../Components/Subject';

import catalogueImg1 from '../../assets/img/promotion/catalogue/catalogueImg1.png';
import catalogueImg2 from '../../assets/img/promotion/catalogue/catalogueImg2.png';
import catalogueImg3 from '../../assets/img/promotion/catalogue/catalogueImg3.png';
import catalogueImg4 from '../../assets/img/promotion/catalogue/catalogueImg4.png';
import catalogueImg5 from '../../assets/img/promotion/catalogue/catalogueImg5.png';
import catalogueImg6 from '../../assets/img/promotion/catalogue/catalogueImg6.png';
import catalogueImg7 from '../../assets/img/promotion/catalogue/catalogueImg7.png';
import catalogueImg8 from '../../assets/img/promotion/catalogue/catalogueImg8.png';
import catalogueImg9 from '../../assets/img/promotion/catalogue/catalogueImg9.png';
import catalogueImg10 from '../../assets/img/promotion/catalogue/catalogueImg10.png';
import catalogueImg11 from '../../assets/img/promotion/catalogue/catalogueImg11.png';
import catalogueImg12 from '../../assets/img/promotion/catalogue/catalogueImg12.png';
import PromotionHeader from './PromotionHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const Box = styled.div`
  margin: 80px 0;
  @media (max-width: 1280px) {
    padding: 0 30px;
  }
  @media (max-width: 1080px) {
    margin: 30px 0;
  }
  @media (max-width: 768px) {
    padding: 20px 10px;
    margin: 10px 0;
  }
  .thumbs-wrapper {
  }
`;

const TextBox = styled.div`
  width: 100%;
  @media (max-width: 1280px) {
    padding: 0 30px;
  }
  @media (max-width: 768px) {
    padding: 0 10px;
    margin: 10px 0;
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

const Title = styled.h1`
  color: #00529b;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
  padding: 5px;
  @media (max-width: 1080px) {
    font-size: 17px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const DownBtn = styled.a`
  padding: 5px;
  color: #00529b;
  border: 1px solid #00529b;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  @media (max-width: 1080px) {
    font-size: 17px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
  :hover {
    background: #00529b;
    color: white;
  }
`;

const Desc = styled.p`
  font-size: 16px;
  line-height: 30px;
  word-break: keep-all;
  margin-top: 10px;
  padding-left: 5px;
  @media (max-width: 1080px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const Catalogue = () => {
  const info = { title: '카탈로그', path: '/promotion/catalogue' };
  const catalogueImgs = [
    catalogueImg1,
    catalogueImg2,
    catalogueImg3,
    catalogueImg4,
    catalogueImg5,
    catalogueImg6,
    catalogueImg7,
    catalogueImg8,
    catalogueImg9,
    catalogueImg10,
    catalogueImg11,
    catalogueImg12,
  ];
  return (
    <>
      <PromotionHeader />
      <Container>
        <Subject info={info} />
        <TextBox>
          <TitleBox>
            <Title>미소테크 카탈로그</Title>
            <DownBtn
              href="http://misotech.ipdisk.co.kr:80/publist/HDD1/Promotion/04.document/%E1%84%86%E1%85%B5%E1%84%89%E1%85%A9%E1%84%90%E1%85%A6%E1%84%8F%E1%85%B3_%E1%84%8F%E1%85%A1%E1%84%83%E1%85%A1%E1%86%AF%E1%84%85%E1%85%A9%E1%84%80%E1%85%B3.zip"
              download>
              다운로드
            </DownBtn>
          </TitleBox>
          <Desc>
            홈페이지 용량 관계상 다운로드 파일은 저화질임을 알려드립니다.
            <br />
            대리점 계약시 카탈로그를 무료로 받아 보실 수 있습니다.
            <br />
            미소테크의 카탈로그는 매년 지속적으로 업데이트 됩니다.
          </Desc>
        </TextBox>
        <Box>
          <Carousel>
            {catalogueImgs.map((catalogue, index) => (
              <div key={index}>
                <img src={catalogue} alt="catalogueImg" />
              </div>
            ))}
          </Carousel>
        </Box>
      </Container>
    </>
  );
};

export default Catalogue;
