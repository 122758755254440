import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 60px 0;
  margin: 0 auto;
  text-align: center;
  width: 1200px;
  margin-bottom: 80px;
  @media (max-width: 1280px) {
    width: 100%;
    margin-bottom: 60px;
  }
  @media (max-width: 1080px) {
    padding: 40px 0;
    margin-bottom: 40px;
  }
  @media (max-width: 768px) {
    padding: 20px 0;
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    padding: 40px 0 30px 0;
    margin-bottom: 20px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-size: 34px;
  line-height: 42px;
  font-weight: 500;
  padding-bottom: 10px;
  width: 100%;
  letter-spacing: -1px;
  font-family: 'Noto Sans KR', sans-serif;
  display: block;
  @media (max-width: 1080px) {
    font-size: 28px;
    line-height: 36px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const Desc = styled.p`
  width: 100%;
  display: block;
  color: #888;
  line-height: 24px;
  word-break: keep-all;
  text-transform: uppercase;
  @media (max-width: 1080px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const Subject = ({ info }) => {
  return (
    <Container>
      <Title>{info.title}</Title>
      <Desc>
        <i className="fas fa-home"></i>&nbsp;&nbsp;
        <i className="fas fa-chevron-right"></i>&nbsp;&nbsp;
        {info.path.split('/')[1]}&nbsp;&nbsp;
        <i className="fas fa-chevron-right"></i>&nbsp;&nbsp;
        {info.path.split('/')[2]}
      </Desc>
    </Container>
  );
};

export default Subject;
