import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';

import staffHeaderImg from '../../assets/img/customer/staffHeaderImg.png';
import staffSubImg1 from '../../assets/img/customer/staffSubImg1.png';
import staffSubImg2 from '../../assets/img/customer/staffSubImg2.png';
import CustomerHeader from './CustomerHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  > img {
    width: 100%;
    margin-top: 80px;
    @media (max-width: 768px) {
      margin-top: 30px;
      width: 100%;
      padding: 0;
    }
  }
  @media (max-width: 1280px) {
    width: 100%;
    margin-bottom: 20px;
    padding: 0 20px 30px 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const Table = styled.table`
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  border-top: 2px solid #1e459c !important;
  margin-bottom: 100px;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;
  line-height: 30px;
  @media (max-width: 1280px) {
    width: 100%;
    line-height: 28px;
  }
  @media (max-width: 1080px) {
    font-size: 14px;
    line-height: 26px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  tr {
    color: #5d5d5d;
    background: #fff;
    @media (max-width: 480px) {
      font-size: 12px;
    }
    &:first-child {
      color: #000;
      background: #f5f5f5;
    }
    > td {
      padding: 10px 5px;
      border: 1px solid #ddd;
    }
  }
`;

const ImgBox = styled.div`
  display: none;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    margin-top: 60px;
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 0;
    margin-top: 30px;
  }
`;

const Img = styled.img`
  @media (max-width: 768px) {
    display: block;
    width: 48%;
    height: 100%;
    margin: 0 auto;
  }
`;

const Row = styled.div`
  display: flex;
  padding: 100px 0 120px 0;
  @media (max-width: 768px) {
    padding: 20px 0 40px 0;
  }
`;

const Column = styled.div`
  width: 30%;
  margin: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 44%;
    margin: 2%;
  }
  &:first-child {
    align-items: flex-end;
    @media (max-width: 768px) {
      align-items: center;
      padding-left: 30px;
    }
    @media (max-width: 480px) {
      padding-left: 15px;
    }
    > p {
      text-align: right;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
  &:nth-child(2) {
    width: 38%;
    @media (max-width: 768px) {
      display: none;
    }
    > img {
      width: 100%;
    }
  }
  &:last-child {
    align-items: flex-start;
    @media (max-width: 768px) {
      align-items: center;

      padding-left: 30px;
    }
    @media (max-width: 480px) {
      padding-left: 15px;
    }
  }
`;

const Title = styled.h1`
  color: #3a6eb3;
  font-size: 35px;
  font-weight: 500;
  padding: 10px 10px;
  @media (max-width: 1280px) {
    font-size: 28px;
  }
  @media (max-width: 1080px) {
    font-size: 22px;
  }
`;

const Desc = styled.p`
  width: 100%;
  word-break: keep-all;
  font-size: 18px;
  line-height: 26px;
  padding: 0 5px;
  color: #5d5d5d;
  @media (max-width: 1280px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (max-width: 1080px) {
    font-size: 13px;
    line-height: 20px;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    text-align: center;
  }
`;

const Btn = styled.a`
  width: 60%;
  cursor: pointer;
  padding: 5px 0px;
  font-size: 20px;
  border: 1px solid #3b6eb3;
  text-align: center;
  color: #3b6eb3;
  background: white;
  :hover {
    background: #3b6eb3;
    color: white;
  }
  @media (max-width: 1280px) {
    font-size: 15px;
    padding: 8px 5px;
  }
  @media (max-width: 1080px) {
    font-size: 13px;
    padding: 5px 2px;
  }
  @media (max-width: 768px) {
    width: 60%;
    margin-top: 20px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Staff = () => {
  const info = { title: '담당자안내', path: '/customer/staff' };
  return (
    <>
      <CustomerHeader />

      <Container>
        <Subject info={info} />
        <Box data-aos="fade-up" data-aos-duration="2000">
          <img src={staffHeaderImg} alt="staffHeaderImg" />
        </Box>

        <ImgBox>
          <Img
            src={staffSubImg1}
            alt="staffSubImg1"
            data-aos="fade-up"
            data-aos-duration="2000"
          />
          <Img
            src={staffSubImg2}
            alt="staffSubImg2"
            data-aos="fade-up"
            data-aos-duration="2000"
          />
        </ImgBox>
        <Row>
          <Column>
            <Title data-aos="fade-up" data-aos-duration="2000">
              견적문의
            </Title>
            <Desc data-aos="fade-up" data-aos-duration="2000">
              제품 소개 및 대리점 계약 관련해서는 misotech7@naver.com 으로 메일
              접수나 유선으로 요청해 주세요.
            </Desc>
            <Desc data-aos="fade-up" data-aos-duration="2000">
              온라인 견적 요청을 통하여 빠르고 쉽게 견적서를 받아보실 수
              있습니다.
            </Desc>
            <Btn
              href="/customer/estimate"
              data-aos="fade-up"
              data-aos-duration="2000">
              온라인 견적요청
            </Btn>
          </Column>
          <Column>
            <img
              src={staffSubImg1}
              alt="staffSubImg1"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          </Column>
          <Column>
            <Title data-aos="fade-up" data-aos-duration="2000">
              기술문의
            </Title>
            <Desc data-aos="fade-up" data-aos-duration="2000">
              중문 설치 및 제품 관련 기술 문의 관련하여 자주 묻는 질문은 FAQ
              페이지에 올려져 있습니다.
            </Desc>
            <Desc data-aos="fade-up" data-aos-duration="2000">
              그 외의 문의 사항들은 홈페이지 고객지원 접수를 이용하시면, 빠르게
              문제를 해결할 수 있습니다.
            </Desc>
            <Btn
              href="/customer/counsel"
              data-aos="fade-up"
              data-aos-duration="2000">
              1:1 고객문의
            </Btn>
          </Column>
        </Row>
        <Table data-aos="fade-up" data-aos-duration="2000">
          <tbody>
            <tr>
              <td>구분</td>
              <td>담당자</td>
              <td>전화번호</td>
            </tr>
            <tr>
              <td>영업총괄</td>
              <td>이성태 이사</td>
              <td>070-4281-2999</td>
            </tr>
            <tr>
              <td>개발총괄</td>
              <td>오대욱 이사</td>
              <td>070-4281-2998</td>
            </tr>
            <tr>
              <td>영업부</td>
              <td>박진수 차장</td>
              <td>031-594-1042</td>
            </tr>
            <tr>
              <td>영업부</td>
              <td>우상원 대리</td>
              <td>070-4281-2997</td>
            </tr>
            <tr>
              <td>자재 및 시공</td>
              <td>박준호 과장</td>
              <td></td>
            </tr>
            <tr>
              <td>제품 생산</td>
              <td>김두현 과장</td>
              <td></td>
            </tr>
            <tr>
              <td>제품 개발</td>
              <td>김경록 과장</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default Staff;
