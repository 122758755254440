import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0 0 10px 0;
`;

const Msg = styled.span`
  color: red;
  font-size: 15px;
  font-weight: 600;
`;

const Btn = styled.button`
  margin-left: 10px;
  background: #333;
  padding: 10px 15px;
  color: #fff;
  font-size: 15px;
  border: 0;
  font-weight: 600;
  font-family: 'Noto Sans KR', sans-serif;
  cursor: pointer;
  text-align: center;
`;

const ErrorNotice = (props) => {
  return (
    <Container>
      <Msg>{props.message}</Msg>
      <Btn onClick={props.clearError}>X</Btn>
    </Container>
  );
};

export default ErrorNotice;
