import React from 'react';
import styled from 'styled-components';

import Subject from '../../Components/Subject';

import dramaImg from '../../assets/img/portfolio/drama/dramaImg.png';
import dramaImg1 from '../../assets/img/portfolio/drama/dramaImg1.jpg';
import dramaImg2 from '../../assets/img/portfolio/drama/dramaImg2.jpg';
import dramaImg3 from '../../assets/img/portfolio/drama/dramaImg3.jpg';
import dramaImg4 from '../../assets/img/portfolio/drama/dramaImg4.jpg';
import dramaImg5 from '../../assets/img/portfolio/drama/dramaImg5.jpg';
import dramaImg6 from '../../assets/img/portfolio/drama/dramaImg6.jpg';
import dramaImg7 from '../../assets/img/portfolio/drama/dramaImg7.jpg';
import dramaImg8 from '../../assets/img/portfolio/drama/dramaImg8.jpg';
import dramaImg9 from '../../assets/img/portfolio/drama/dramaImg9.jpg';
import dramaImg10 from '../../assets/img/portfolio/drama/dramaImg10.jpg';
import PortfolioHeader from './PortfolioHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Box = styled.div`
  width: 100%;
  margin: 0;
  padding: 60px 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  @media (max-width: 1080px) {
    padding: 50px 0px;
  }
  @media (max-width: 768px) {
    padding: 40px 0px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  padding-bottom: 15px;
  @media (max-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const Name = styled.h2`
   {
    color: #00529b;
    padding: 15px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 0;
    margin-bottom: 10px;
  }
  @media (max-width: 1080px) {
    font-size: 17px;
    line-height: 24px;
  }
`;

const Desc = styled.p`
  font-size: 17px;
  line-height: 24px;
  word-break: keep-all;
  margin-bottom: 50px;
  @media (max-width: 1080px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }
`;

const ImgBox = styled.div`
  width: 1200px;
  margin-bottom: 30px;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  > img {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const Column = styled.div`
  width: 48%;
  margin: 1%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 20px;
  }
  > img {
    width: 100%;
  }
`;

const Drama = () => {
  const info = { title: '드라마 협찬', path: '/portfolio/drama' };

  return (
    <>
      <PortfolioHeader />
      <Container>
        <Subject info={info} />
        <Box>
          <Title data-aos="fade-up" data-aos-duration="2000">
            드라마 협찬
          </Title>
          <ImgBox data-aos="fade-up" data-aos-duration="2000">
            <img src={dramaImg} alt="dramaImg" />
          </ImgBox>
          <Name data-aos="fade-up" data-aos-duration="2000">
            굿캐스팅
          </Name>
          <Desc data-aos="fade-up" data-aos-duration="2000">
            생활속 공간을 디자인하는 미소테크는 인기드라마에서도 만날 수
            있습니다. SBS월화드라마 '굿캐스팅' 외에도 각 방송사 드라마
            제작협찬도 활발히 하고 있습니다.
          </Desc>
          <Row>
            <Column>
              <img
                src={dramaImg1}
                alt="dramaImg1"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={dramaImg2}
                alt="dramaImg2"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={dramaImg3}
                alt="dramaImg3"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={dramaImg4}
                alt="dramaImg4"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={dramaImg5}
                alt="dramaImg5"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={dramaImg6}
                alt="dramaImg6"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={dramaImg7}
                alt="dramaImg7"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={dramaImg8}
                alt="dramaImg8"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <img
                src={dramaImg9}
                alt="dramaImg9"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
            <Column>
              <img
                src={dramaImg10}
                alt="dramaImg10"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-center"
              />
            </Column>
          </Row>
        </Box>
      </Container>
    </>
  );
};

export default Drama;
