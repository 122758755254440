import React from 'react';
import styled from 'styled-components';
import Subject from '../../Components/Subject';

import promotionNews1 from '../../assets/img/promotion/news/promotionNews1.png';
import promotionNews2 from '../../assets/img/promotion/news/promotionNews2.png';
import PromotionHeader from './PromotionHeader';

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 60px;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 20px 50px 20px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;
`;

const Column = styled.div`
  width: 30%;
  margin: 1%;

  @media (max-width: 1280px) {
    width: 30%;
  }
  @media (max-width: 768px) {
    width: 40%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
  &:first-child {
    display: flex;
    align-items: center;
    > img {
      width: 100%;
      border: 1px solid #666666;
    }
  }
  &:last-child {
    width: 65%;
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 20px;
    justify-content: space-between;
    @media (max-width: 1280px) {
      width: 65%;
    }
    @media (max-width: 768px) {
      width: 55%;
    }
    @media (max-width: 480px) {
      width: 100%;
      padding: 10px 5px;
    }
  }
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;

const Class = styled.span`
  width: 15%;
  font-size: 17px;
  font-weight: 500;
  @media (max-width: 1280px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Title = styled.h1`
  font-size: 20px;
  vertical-align: center;
  @media (max-width: 1280px) {
    font-size: 17px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const DescBox = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;

const Desc = styled.p`
  width: 85%;
  word-break: keep-all;
  font-size: 15px;
  line-height: 28px;
  color: #5d5d5d;
  @media (max-width: 1280px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
  > a {
    color: #00539b;
    font-weight: 600;
    font-style: italic;
  }
`;

const News = () => {
  const info = { title: '미소소식', path: '/promotion/news' };

  return (
    <>
      <PromotionHeader />

      <Container>
        <Subject info={info} />
        <Row>
          <Column>
            <img src={promotionNews2} alt="promotionNews2" />
          </Column>
          <Column>
            <TitleBox>
              <Class>제목</Class>
              <Title>미소테크의 인스타그램 계정이 개설되었습니다.</Title>
            </TitleBox>
            <DescBox>
              <Class>내용</Class>
              <Desc>
                인스타 팔로워를 통해 다양한 사진 자료와 실시간 문의를 하실 수
                있습니다.
                <br />
                계정 :
                <a
                  href="https://www.instagram.com/misotech_slimdoor/?hl=ko"
                  target="_blank"
                  rel="noopener noreferrer">
                  misotech_slimdoor
                </a>
              </Desc>
            </DescBox>
            <DescBox>
              <Class>날짜</Class>
              <Desc>2020-08-01</Desc>
            </DescBox>
            <DescBox>
              <Class>장소</Class>
              <Desc>주식회사 미소테크</Desc>
            </DescBox>
            <DescBox>
              <Class>정보</Class>
              <Desc>인스타그램 계정 개설</Desc>
            </DescBox>
          </Column>
        </Row>
        <Row>
          <Column>
            <img src={promotionNews1} alt="promotionNews1" />
          </Column>
          <Column>
            <TitleBox>
              <Class>제목</Class>
              <Title>미소테크의 홈페이지가 개설 되었습니다.</Title>
            </TitleBox>
            <DescBox>
              <Class>내용</Class>
              <Desc>
                지속적입 업데이트와 함께 고객 여러분에게 더 편리한 시스템으로
                찾아갈 것을 약속 드립니다.
              </Desc>
            </DescBox>
            <DescBox>
              <Class>날짜</Class>
              <Desc>2020-08-01</Desc>
            </DescBox>
            <DescBox>
              <Class>장소</Class>
              <Desc>주식회사 미소테크</Desc>
            </DescBox>
            <DescBox>
              <Class>정보</Class>
              <Desc>홈페이지 오픈</Desc>
            </DescBox>
          </Column>
        </Row>
      </Container>
    </>
  );
};

export default News;
