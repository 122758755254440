import develop1 from '../../assets/img/company/whatwedo/develop1.png';
import develop2 from '../../assets/img/company/whatwedo/develop2.png';
import produce1 from '../../assets/img/company/whatwedo/produce1.png';
import produce2 from '../../assets/img/company/whatwedo/produce2.png';
import install1 from '../../assets/img/company/whatwedo/install1.png';
import install2 from '../../assets/img/company/whatwedo/install2.png';
import service1 from '../../assets/img/company/whatwedo/service1.png';
import service2 from '../../assets/img/company/whatwedo/service2.png';

import danger from '../../assets/img/company/patents/certificate/danger.png';
import develop from '../../assets/img/company/patents/certificate/develop.png';
import disabled from '../../assets/img/company/patents/certificate/disabled.png';
import productSafe from '../../assets/img/company/patents/certificate/productSafe.png';
import venture from '../../assets/img/company/patents/certificate/venture.png';
import tradeMarkPatent from '../../assets/img/company/patents/tradeMarkPatent_40-1621861.png';
import designPatent01 from '../../assets/img/company/patents/patent/designPatent_30-0988046.png';
import designPatent02 from '../../assets/img/company/patents/patent/designPatent_30-0988593.png';
import designPatent03 from '../../assets/img/company/patents/patent/designPatent_30-0988605.png';
import designPatent04 from '../../assets/img/company/patents/patent/designPatent_30-0990422.png';
import designPatent05 from '../../assets/img/company/patents/patent/designPatent_30-0992954.png';
import designPatent06 from '../../assets/img/company/patents/patent/designPatent_30-0992957.png';
import designPatent07 from '../../assets/img/company/patents/patent/designPatent_30-0992961.png';
import designPatent08 from '../../assets/img/company/patents/patent/designPatent_30-0992962.png';
import designPatent09 from '../../assets/img/company/patents/patent/designPatent_30-0992963.png';
import designPatent10 from '../../assets/img/company/patents/patent/designPatent_30-0992964.png';
import designPatent11 from '../../assets/img/company/patents/patent/designPatent_30-0992965.png';
import designPatent12 from '../../assets/img/company/patents/patent/designPatent_30-0993130.png';
import designPatent13 from '../../assets/img/company/patents/patent/designPatent_30-0995295.png';
import designPatent14 from '../../assets/img/company/patents/patent/designPatent_30-0995296.png';
import designPatent15 from '../../assets/img/company/patents/patent/designPatent_30-0995298.png';
import designPatent16 from '../../assets/img/company/patents/patent/designPatent_30-0995299.png';
import designPatent17 from '../../assets/img/company/patents/patent/designPatent_30-0995300.png';
import designPatent18 from '../../assets/img/company/patents/patent/designPatent_30-1001628.png';
import designPatent19 from '../../assets/img/company/patents/patent/designPatent_30-1001968.png';
import designPatent20 from '../../assets/img/company/patents/patent/designPatent_30-1013203.png';
import designPatent21 from '../../assets/img/company/patents/patent/designPatent_30-1018447.png';
import designPatent22 from '../../assets/img/company/patents/patent/designPatent_30-1018448.png';
import designPatent23 from '../../assets/img/company/patents/patent/designPatent_30-1018449.png';
import designPatent24 from '../../assets/img/company/patents/patent/designPatent_30-1018450.png';
import designPatent25 from '../../assets/img/company/patents/patent/designPatent_30-1018451.png';
import designPatent26 from '../../assets/img/company/patents/patent/designPatent_30-1018452.png';
import designPatent27 from '../../assets/img/company/patents/patent/designPatent_30-1019486.png';
import designPatent28 from '../../assets/img/company/patents/patent/designPatent_30-1028637.png';
import designPatent29 from '../../assets/img/company/patents/patent/designPatent_30-1028639.png';
import designPatent30 from '../../assets/img/company/patents/patent/designPatent_30-1030669.png';
import designPatent31 from '../../assets/img/company/patents/patent/designPatent_30-1040123.png';
import designPatent32 from '../../assets/img/company/patents/patent/designPatent_30-1040124.png';
import designPatent33 from '../../assets/img/company/patents/patent/designPatent_30-1040125.png';
import designPatent34 from '../../assets/img/company/patents/patent/designPatent_30-1040513.png';
import designPatent35 from '../../assets/img/company/patents/patent/designPatent_30-1050484.png';
import designPatent36 from '../../assets/img/company/patents/patent/designPatent_30-1053856.png';
import designPatent37 from '../../assets/img/company/patents/patent/designPatent_30-1053857.png';
import designPatent38 from '../../assets/img/company/patents/patent/designPatent_30-1064853.png';
import designPatent39 from '../../assets/img/company/patents/patent/designPatent_30-1064854.png';

export const bussinessDatas = [
  {
    title: 'develop',
    ko_title: '기술 개발 및 연구',
    columns: [
      {
        img: develop1,
        subTitle: '전문가들의 기술력',
        desc:
          '중문 기술 개발에 관한 국내 최고라 자랑할만한 전문가들이 더 나은 기술을 위해 연구하고 개발하고 있습니다.',
      },
      {
        img: develop2,
        subTitle: '트랜드를 선도하는 기업',
        desc:
          '점점 더 빨라지는 인테리어 트랜드에 한발 앞서 세련되고 모던한 디지인을 추구하는 도어업계의 선도 기업입니다.',
      },
    ],
  },
  {
    title: 'product',
    ko_title: '제품 생산',
    columns: [
      {
        img: produce1,
        subTitle: '완벽한 제품으로 인정받는 기업',
        desc:
          '한치의 오차도 허락하지 않는 자체 생산력의 빈틈없는 시스템으로 완벽한 제품으로 인정받는 기업입니다',
      },
      {
        img: produce2,
        subTitle: '제품으로 신뢰받는 기업',
        desc:
          '부품과 디자인 모두 책임있는 제품으로 생산하여 고객이 신뢰하는 기업으로 성장해 나가겠습니다.',
      },
    ],
  },
  {
    title: 'install',
    ko_title: '제품 시공',
    columns: [
      {
        img: install1,
        subTitle: '맞춤시공까지 책임지는 기업',
        desc:
          '전문 시공팀에서 맞춤 제작된 제품을 마지막까지 깔끔한 시공으로 책임지는 기업입니다.',
      },
      {
        img: install2,
        subTitle: '고객이 만족하는 시공',
        desc:
          '디자인까지 생각하여 고객이 원하는 공간에 사용하기 편안한 디자인으로 시공을 책임지고 있습니다.',
      },
    ],
  },
  {
    title: 'service',
    ko_title: '고객감동 서비스',
    columns: [
      {
        img: service1,
        subTitle: '신뢰받는 기업',
        desc:
          '고객을 위한 맞춤 서비스는 설계에서부터 생산 시공까지 책임지며 고객의 만족을 신뢰로 받기위해 끊임없이 노력하는 기업입니다.',
      },
      {
        img: service2,
        subTitle: '고객의 행복을 추구하는 기업',
        desc:
          '미소테크는 고객이 행복할 때 미소짓고 고객이 만족할 때 더욱 힘이 나는 고객의 행복을 추구하는 기업입니다.',
      },
    ],
  },
];

export const patentData = [
  {
    title: '인증서',
    patents: [
      { name: '벤처기업확인서', img: venture },
      { name: '연구전담부서', img: develop },
      { name: '제품안전협의회', img: productSafe },
      { name: '위험성안정평가', img: danger },
      { name: '장애인기업', img: disabled },
    ],
  },
  {
    title: '특허',
    patents: [
      { name: '디자인특허 제 30-0988046 호', img: designPatent01 },
      { name: '디자인특허 제 30-0988593 호', img: designPatent02 },
      { name: '디자인특허 제 30-0988605 호', img: designPatent03 },
      { name: '디자인특허 제 30-0990422 호', img: designPatent04 },
      { name: '디자인특허 제 30-0992954 호', img: designPatent05 },
      { name: '디자인특허 제 30-0992957 호', img: designPatent06 },
      { name: '디자인특허 제 30-0992961 호', img: designPatent07 },
      { name: '디자인특허 제 30-0992962 호', img: designPatent08 },
      { name: '디자인특허 제 30-0992963 호', img: designPatent09 },
      { name: '디자인특허 제 30-0992964 호', img: designPatent10 },
      { name: '디자인특허 제 30-0992965 호', img: designPatent11 },
      { name: '디자인특허 제 30-0993130 호', img: designPatent12 },
      { name: '디자인특허 제 30-0995295 호', img: designPatent13 },
      { name: '디자인특허 제 30-0995296 호', img: designPatent14 },
      { name: '디자인특허 제 30-0995298 호', img: designPatent15 },
      { name: '디자인특허 제 30-0995299 호', img: designPatent16 },
      { name: '디자인특허 제 30-0995300 호', img: designPatent17 },
      { name: '디자인특허 제 30-1001628 호', img: designPatent18 },
      { name: '디자인특허 제 30-1001968 호', img: designPatent19 },
      { name: '디자인특허 제 30-1013203 호', img: designPatent20 },
      { name: '디자인특허 제 30-1018447 호', img: designPatent21 },
      { name: '디자인특허 제 30-1018448 호', img: designPatent22 },
      { name: '디자인특허 제 30-1018449 호', img: designPatent23 },
      { name: '디자인특허 제 30-1018450 호', img: designPatent24 },
      { name: '디자인특허 제 30-1018451 호', img: designPatent25 },
      { name: '디자인특허 제 30-1018452 호', img: designPatent26 },
      { name: '디자인특허 제 30-1019486 호', img: designPatent27 },
      { name: '디자인특허 제 30-1028637 호', img: designPatent28 },
      { name: '디자인특허 제 30-1028639 호', img: designPatent29 },
      { name: '디자인특허 제 30-1030669 호', img: designPatent30 },
      { name: '디자인특허 제 30-1040123 호', img: designPatent31 },
      { name: '디자인특허 제 30-1040124 호', img: designPatent32 },
      { name: '디자인특허 제 30-1040125 호', img: designPatent33 },
      { name: '디자인특허 제 30-1040513 호', img: designPatent34 },
      { name: '디자인특허 제 30-1050484 호', img: designPatent35 },
      { name: '디자인특허 제 30-1053856 호', img: designPatent36 },
      { name: '디자인특허 제 30-1053857 호', img: designPatent37 },
      { name: '디자인특허 제 30-1064853 호', img: designPatent38 },
      { name: '디자인특허 제 30-1064854 호', img: designPatent39 },
    ],
  },
  {
    title: '상표등록',
    patents: [{ name: '스마일도어', img: tradeMarkPatent }],
  },
];

export const mapData = [
  {
    title: '주소',
    icon: 'fas fa-industry',
    content: '경기도 남양주시 화도읍 수레로 961번길 25-30 주식회사 미소테크',
  },
  { title: '전화번호', icon: 'fas fa-phone-alt', content: '031-594-1042' },
  { title: '팩스번호', icon: 'fas fa-fax', content: '031-594-1040' },
  { title: '이메일', icon: 'fas fa-envelope', content: 'misotech7@naver.com' },
  { title: '홈페이지', icon: 'fas fa-home', content: 'www.misotech7.com' },
];

export const introData = [
  { title: '회사명', content: '주식회사 미소테크', icon: 'fas fa-globe' },
  { title: '설립일', content: '2017.09.16', icon: 'far fa-list-alt' },
  { title: '대표이사', content: '이성태 오대욱', icon: 'fas fa-people-arrows' },
  { title: '직원수', content: '12 명', icon: 'fas fa-users' },
  { title: '자본금', content: '₩ 100,000,000', icon: 'fas fa-dollar-sign' },
];
