import React from 'react';
import styled from 'styled-components';
import SmallCard from './SmallCard';

import { cardArr } from './datas';

const Container = styled.section`
  width: 100%;
  padding: 50px 20px;
  background: #fff;
  @media screen and (max-width: 1080px) {
    padding: 40px 20px;
  }
`;

const Wrap = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-family: 'Montserrat', sans-serif;
  color: #1e1e1e;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  padding-bottom: 15px;
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

const Desc = styled.p`
  font-size: 14px;
  line-height: 20px;
  word-break: keep-all;
  padding-bottom: 40px;
  font-weight: 500;
  color: #666666;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 18px;
    padding: 0 20px 30px 20px;
    font-weight: 400;
  }
`;

const Cardwrap = styled.div`
  width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Whatwedo = () => {
  return (
    <Container>
      <Wrap>
        <Title data-aos="fade-up" data-aos-duration="2000">
          What we do
        </Title>
        <Desc data-aos="fade-up" data-aos-duration="2000">
          최고의 프리미엄 공간 가치를 창조하는 기업.
          <br />
          라이프 스타일을 고려하여 공간을 나누고 개방하는 인테리어 시스템 도어
          제작합니다. <br />
          미소테크는 개발, 생산, 시공, 서비스 모든 공정에서 당신과 함께 합니다.
        </Desc>
        <Cardwrap>
          {cardArr.map((cardInfo, index) => (
            <SmallCard key={index} info={cardInfo} />
          ))}
        </Cardwrap>
      </Wrap>
    </Container>
  );
};

export default Whatwedo;
