import React from 'react';
import styled from 'styled-components';

import airconditioner from '../../../assets/img/product/airconditioner.png';
import lock from '../../../assets/img/product/lock.png';
import noise from '../../../assets/img/product/noise.png';
import shoes from '../../../assets/img/product/shoes.png';

const Container = styled.div`
  margin: 150px 0;
  text-align: center;
  @media (max-width: 768px) {
    margin: 100px 0;
  }
  > h1 {
    font-size: 26px;
    margin: 15px;
    color: #d63031;
    @media (max-width: 768px) {
      margin: 0;
      font-size: 18px;
    }
  }
  > h2 {
    font-size: 32px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 18px;
      margin: 10px 0 20px 0;
    }
    > em {
      font-weight: 600;
    }
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
    > div {
      margin: 3% 0;
      width: 20%;
      @media (max-width: 768px) {
        width: 40%;
        margin: 3% 2%;
      }
      > img {
        width: 70%;
        @media (max-width: 768px) {
          width: 70%;
        }
      }
      > p {
        font-size: 18px;
        line-height: 40px;
        @media (max-width: 768px) {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
`;

const Neccessary = () => {
  const datas = [
    {
      title: '냉/난방비 절감',
      en_title: 'airconditioner',
      img: airconditioner,
    },
    { title: '외부소음/미세먼지 차단', en_title: 'noise', img: noise },
    { title: '생활냄새 차단', en_title: 'shoes', img: shoes },
    { title: '사생활 보호', en_title: 'lock', img: lock },
  ];
  return (
    <Container>
      <h1>인테리어의 선택이 아닌 필수 아이템</h1>
      <h2>
        왜 <em>미소테크의 슬림도어를</em> 설치할까요?
      </h2>
      <div>
        {datas.map((data, index) => (
          <div key={index}>
            <img src={data.img} alt={data.en_title} />
            <p>{data.title}</p>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Neccessary;
