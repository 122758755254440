import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  width: 23%;
  margin: 0 1%;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.06);
  display: inline-block;
  vertical-align: top;
  @media (max-width: 768px) {
    width: 45%;
    margin: 10px;
  }
  @media (max-width: 480px) {
    width: 90%;
    margin-bottom: 30px;
  }
`;

const Imgwrap = styled.div`
  width: 100%;
`;

const Img = styled.img`
  width: 100%;
`;

const Descwrap = styled.div`
  padding: 30px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 10px;
  color: #666;
  text-align: center;
`;

const Desc = styled.p`
  width: 100%;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  word-break: keep-all;
  font-weight: 500;
  text-align: center;
  color: #666;
`;

const Btn = styled(Link)`
  width: 100%;
  color: #999;
  background: #fff;
  border: 1px solid #ccc;
  font-size: 11px;
  font-weight: 600 !important;
  font-family: 'Montserrat', sans-serif;
  padding: 8px 20px;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  :hover {
    background: #999;
    color: #fff;
  }
`;

const SmallCard = ({ info }) => {
  return (
    <Container data-aos="fade-up" data-aos-duration="2000">
      <Imgwrap>
        <Img src={info.img} alt="what1" />
      </Imgwrap>
      <Descwrap>
        <Title>{info.title}</Title>
        <Desc>{info.desc}</Desc>
        <Btn to="/company/business">detail view>></Btn>
      </Descwrap>
    </Container>
  );
};

export default SmallCard;
