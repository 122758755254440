import productImg1 from '../../assets/img/home/Products/productImg1.png';
import productImg2 from '../../assets/img/home/Products/productImg2.png';
import productImg3 from '../../assets/img/home/Products/productImg3.png';
import productImg4 from '../../assets/img/home/Products/productImg4.png';
import productImg5 from '../../assets/img/home/Products/productImg5.png';
import productImg6 from '../../assets/img/home/Products/productImg6.png';
import WhatwedoImg1 from '../../assets/img/home/Whatwedo/WhatwedoImg1.png';
import WhatwedoImg2 from '../../assets/img/home/Whatwedo/WhatwedoImg2.png';
import WhatwedoImg3 from '../../assets/img/home/Whatwedo/WhatwedoImg3.png';
import WhatwedoImg4 from '../../assets/img/home/Whatwedo/WhatwedoImg4.png';

export const productArr = [
  {
    name: 'product_1',
    img: productImg1,
    link: '/product/sliding',
    title: '슬라이딩 도어',
    desc:
      '어느 곳에나 완벽히 어울리는 슬라이딩 도어, 모던한 분위기와 세련되면서 실용성을 동시에 갖출수 있는 베스트 인테리어 아이템',
  },
  {
    name: 'product_2',
    img: productImg2,
    link: '/product/link',
    title: '연동 도어',
    desc:
      '미소테크의 연동 도어는 부드러우면서 조용하다. 다양한 타입으로 설치 가능하며, 집의 분위기를 가장 크게 바꿀 수 있는 첫인상',
  },
  {
    name: 'product_3',
    img: productImg3,
    link: '/product/hinge',
    title: '여닫이 도어',
    desc:
      '좁은 공간에서도 좀 더 넓은 개방감을 확보할 수 있다. 천장까지 확보되는 개방감의 최대화 모던한 슬림 여닫이 도어',
  },
  {
    name: 'product_4',
    img: productImg4,
    link: '/product/swing',
    title: '스윙 도어',
    desc:
      '자동으로 닫히는 유압식 힌지를 적용하여 중문의 한계를 벗어나다. 양방향 180도 회전으로 문열림을 자유로운 개방감',
  },
  {
    name: 'product_5',
    img: productImg5,
    link: '/product/partition',
    title: '파티션',
    desc:
      '구조적 안정감과 시공이 간편한 알루미늄 슬림 파티션, 다양한 디자인과 유리분할 패턴의 고급스러운 인테리어 효과',
  },
  {
    name: 'product_6',
    img: productImg6,
    link: '!#',
    title: '하드웨어',
    desc:
      '직접 개발하여, 미소테크의 제품에 커스터마이징 된 하드웨어, 최적의 움직임으로 제품의 완전성을 돕다',
  },
];

export const cardArr = [
  {
    img: WhatwedoImg3,
    title: 'Develop',
    desc:
      '모든 프레임과 하드웨어를 직접 개발하여 품질 보증되는 제품을 공급하고 있습니다.',
  },
  {
    img: WhatwedoImg2,
    title: 'Produce',
    desc:
      '업계 경력이 있는 직원들로 구성되어 생산력 향상과 경쟁력 있는 정확한 제품을 제공하고 있습니다.',
  },
  {
    img: WhatwedoImg1,
    title: 'Install',
    desc:
      '전문 인력을 배치하여 고객과의 약속을 최우선으로 하여 깔끔하고 완벽한 서비스를 받으실 수 있습니다.',
  },
  {
    img: WhatwedoImg4,
    title: 'Service',
    desc:
      '빠른 대응과 친절한 서비스로 전문적인 상담을 받아보실 수 있도록 노력하고 있습니다.',
  },
];
